import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { postOtpAction, postResendOtpAction } from "../redux/auth/action";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../ui/input-otp";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { getCookies, setCookies } from "../auth/auth";

function VerifyOtp() {
  const { t } = useTranslation();

  const {
    postOtp,
    postResendOtp,
    postOtpLoading,
    postResendOtpLoading,
    postOtpError,
    postResendOtpError,
  } = useSelector((state) => state.Authsection);
  const navigate = useNavigate();

  const [timer, setTimer] = useState(120); // 2 minutes timer (120 seconds)
  const [isTimerActive, setIsTimerActive] = useState(true);
  const { email } = useParams();
  const dispatch = useDispatch();
  const initialValues = {
    email: email,
    otp: "",
  };
  const [loader, setloader] = useState(false);
  useEffect(() => {
    authhandler()
  }, [navigate]);
  const authhandler = async () => {
    const token = await getCookies("token");
    const userType = await getCookies("userType");
    if (token && userType && loader === false) {
      navigate(`/${userType}/dashboard`);
    }
  }
  useEffect(() => {
    if (!isTimerActive) {
      toast.error(postResendOtpError.message, { id: "resendotp-toast" });

    }
  }, [postResendOtpError]);
  useEffect(() => {
    if (!isTimerActive) {
      resendhandler();
    }
  }, [postResendOtp]);
  const resendhandler = async () => {
    if (postResendOtp?.status === 200) {
      toast.success(postResendOtp.message, { id: "resendotp-toast" });
      setTimer(120); // Reset the timer to 2 minutes
      setIsTimerActive(true);
    } else if (postResendOtp?.status === 400) {
      toast.error(postResendOtp?.message, { id: "resendotp-toast" });
    } else if (postResendOtp?.status === 500) {
      toast.error(postResendOtp?.error, { id: "resendotp-toast" });
    }
  };
  useEffect(() => {
    if (loader) {
      handler();
    }
  }, [postOtp]);
  const handler = async () => {
    if (postOtp?.status === 200) {
      toast.success(postOtp?.message, { id: "otp-toast" });
      setloader(false);
      const token = await setCookies("token", postOtp.token);
      const userType = await setCookies("userType", postOtp.userType);
      if (token.status === true && userType.status === true) {
        navigate(`/${postOtp.userType}/details/addDetails`);  
      }
    } else if (postOtp?.status === 400) {
      toast.error(postOtp.message, { id: "otp-toast" });
    }else if (postOtp?.status === 401) {
      toast.error(postOtp.message, { id: "otp-toast" });
      setTimer(0)
      setIsTimerActive(false)
    } else if (postOtp?.status === 500) {
      toast.error(postOtp.error, { id: "otp-toast" });
    }
  };
  const validationSchema = Yup.object({});

  const onSubmit = (values) => {
    setloader(true);
    dispatch(postOtpAction(values));
    toast.loading("Verifying...", { id: "otp-toast" });
  };
  
  const handleResend = () => {
    dispatch(postResendOtpAction(email));
    toast.loading("Resending...", { id: "resendotp-toast" });
  };

  useEffect(() => {
    let timerInterval;
    if (isTimerActive) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerInterval);
            setIsTimerActive(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [isTimerActive]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  return (
    <div className="w-full p-8 md:col-span-2 bg-gray-100 col-span-5 grid grid-rows-5 ">
      <h2 className="text-2xl font-bold uppercase  ">{t("verify")}</h2>
      <h2 className="text-sm text-gray-400 mb-3  ">
        {t("checkEmail")}- {email}.
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form className="row-span-3 h-full">
            <div className="space-y-2 grid grid-rows-2 h-full ">
              <div className="items-center justify-center flex">
                <InputOTP
                  maxLength={6}
                  value={formik.values.otp}
                  autoFocus
                  pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                  onChange={(value) => formik.setFieldValue("otp", value)}
                >
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>

              <div className="flex flex-col w-full">
                <button
                  type="submit"
                  disabled={postOtpLoading || postResendOtpLoading}
                  className="primaryButton mt-auto"
                >
                  {t("verify")}
                </button>
                <button
                  type="button"
                  onClick={handleResend}
                  disabled={isTimerActive || postOtpLoading || postResendOtpLoading}
                  className="ghostButton mt-auto"
                >
                  {t("resendOtp")} {isTimerActive && `(${formatTime(timer)})`}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default VerifyOtp;
