import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import { ProtectedRoute } from "./auth/protectedRoute";
import Layout from "./layout/auth/layout";
import SignUp from "./components/signUp";
import "./i18n"; // Ensure this import is present
import VerifyOtp from "./components/verify-otp";
import ForgetPassword from "./components/forgetPassword";
import ResetPassword from "./components/resetPassword";
import AddDetails from "./components/company/addDetails";
import Truck from "./components/company/Truck/truck";
import AddTruck from "./components/company/Truck/addTruck";
import TruckDetails from "./components/company/Truck/truckDetails";
import AddTrip from "./components/company/trip/addTrip";
import Trip from "./components/company/trip/trip";
import AddFuel from "./components/company/fuel/addFuel";
import TripDetails from "./components/company/trip/tripDetails";
import AddTollTax from "./components/company/tollTax/addTollTax";
import AddService from "./components/company/Truck/service/addService";
import AddEmployeeNewUser from "./components/company/employee/addEmployeeNewUser";
import AddEmployeePersonalDetails from "./components/company/employee/addEmployeePersonalDetails";
import AddEmployeeFamilyDetails from "./components/company/employee/addEmployeeFamilyDetails";
import EmployeeList from "./components/company/employee/employeeList";
import AddEmployeeFind from "./components/company/employee/addEmployeeFind";
import EmployeeDetails from "./components/company/employee/employeeDetails";
import AddExtraExpenseTrip from "./components/company/trip/extraExpense/addExtraExpenseTrip";
import AddDEF from "./components/company/Truck/def/addDEF";
import AddClient from "./components/company/client/addClient";
import ClientList from "./components/company/client/client";
import Client from "./components/company/client/client";
import ClientDetails from "./components/company/client/clientDetails";
import Details from "./components/company/details";
import EmployeeSalaryList from "./components/company/employee/salary/employeeSalaryList";
import PaySalary from "./components/company/employee/salary/paySalary";
import PayEmi from "./components/company/Truck/emi/payEmi";
import TruckEmiList from "./components/company/Truck/emi/truckEmiList";
import Stats from "./components/company/stats/stats";
import Company from "./components/admin/company/company";
import CompanyDetails from "./components/admin/company/details/companyDetails";
import { Toaster } from "react-hot-toast";
import SubscriptionList from "./components/admin/truck/subscriptionList";
import Subscription from "./components/admin/truck/subscription";
import TruckAdmin from "./components/admin/truck/truck";
import TruckAdminDetails from "./components/admin/truck/details/truckDetails";
import AddTruckSubscription from "./components/admin/truck/addTruckSubscription";
import SubscriptionComponent from "./components/admin/subscription/subscription";
import AddSubscription from "./components/admin/subscription/addSubscription";
import AddDiscount from "./components/admin/subscription/addDiscount";
import TruckDetailsCompany from "./components/admin/company/details/truckDetailsCompany";
import EmployeeDetailsSection from "./components/admin/company/details/employeeDetailsSection";
import AdminEmployees from "./components/admin/employee/employee";
import AdminEmployeeDetails from "./components/admin/employee/details/employeeDetails";
import EmployeeAddToCompany from "./components/admin/employee/addToCompany";
import Expenses from "./components/admin/expenses/expenses";
import AddExpenses from "./components/admin/expenses/addExpenses";
import Dashboard from "./components/admin/dashboard/dashboard";
import CompanyDashboard from "./components/company/dashboard/dashboard";
import Insurance from "./components/company/Truck/insurance/insurance";
import Fitness from "./components/company/Truck/fitness/fitness";
import Permit from "./components/company/Truck/permit/permit";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<Login />} />
          <Route index path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/verify-otp/:email" element={<VerifyOtp />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Route>
        <Route path="/contactUs" element={<Login />} />
        <Route element={<ProtectedRoute userType="admin" />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/company" element={<Company />} />
          <Route path="/admin/company/:id" element={<CompanyDetails />} />
          <Route path="/admin/company/truck/details/:id" element={<TruckDetailsCompany />} />
          <Route path="/admin/company/employee/details/:companyEmployee" element={<EmployeeDetailsSection />} />
          <Route path="/admin/truck" element={<TruckAdmin />} />
          <Route path="/admin/truck/details/:id" element={<TruckAdminDetails />} />
          <Route path="/admin/truck/subscription" element={<Subscription />} />
          <Route path="/admin/truck/subscription/addSubscription" element={<AddTruckSubscription />} />
          <Route path="/admin/subscription" element={<SubscriptionComponent />} />
          <Route path="/admin/subscription/addSubscription" element={<AddSubscription />} />
          <Route path="/admin/subscription/addDiscount" element={<AddDiscount />} />
          <Route path="/admin/employee" element={<AdminEmployees />} />
          <Route path="/admin/employee/details/:employee" element={<AdminEmployeeDetails />} />
          <Route path="/admin/employee/addToCompany/:employeeId" element={<EmployeeAddToCompany />} />
          <Route path="/admin/expenses" element={<Expenses />} />
          <Route path="/admin/expenses/addExpenses" element={<AddExpenses />} />
        </Route>
        <Route element={<ProtectedRoute userType="company" />}>
          <Route path="/company/dashboard" element={<CompanyDashboard />} />
          <Route path="/company/details" element={<Details />} />
          <Route path="/company/details/addDetails" element={<AddDetails />} />
          <Route path="/company/truck" element={<Truck />} />
          <Route path="/company/truck/emi" element={<TruckEmiList />} />
          <Route path="/company/truck/emi/addEmi" element={<PayEmi />} />
          <Route path="/company/truck/addTruck" element={<AddTruck />} />
          <Route path="/company/truck/details" element={<TruckDetails />} />
          <Route path="/company/truck/insurance/:truckId" element={<Insurance />} />
          <Route path="/company/truck/fitness/:truckId" element={<Fitness />} />
          <Route path="/company/truck/permit/:truckId" element={<Permit />} />
          <Route path="/company/truck/addDEF" element={<AddDEF />} />
          <Route path="/company/trip/addExtraExpense" element={<AddExtraExpenseTrip />} />
          <Route path="/company/trip/addTrip" element={<AddTrip />} />
          <Route path="/company/trip/details" element={<TripDetails />} />
          <Route path="/company/trip" element={<Trip />} />
          <Route path="/company/client" element={<Client />} />
          <Route path="/company/client/details" element={<ClientDetails />} />
          <Route path="/company/client/addClient" element={<AddClient />} />
          <Route path="/company/fuel/addFuel" element={<AddFuel />} />
          <Route path="/company/tollTax/addTollTax" element={<AddTollTax />} />
          <Route path="/company/service/addService" element={<AddService />} />
          <Route path="/company/employee" element={<EmployeeList />} />
          <Route path="/company/employee/salary" element={<EmployeeSalaryList />} />
          <Route path="/company/employee/salary/addSalary" element={<PaySalary />} />
          <Route path="/company/employee/addEmployee/find" element={<AddEmployeeFind />} />
          <Route path="/company/employee/details/:id" element={<EmployeeDetails />} />
          <Route path="/company/employee/addEmployee/user" element={<AddEmployeeNewUser />} />
          <Route path="/company/employee/addEmployee/personalDetails" element={<AddEmployeePersonalDetails />} />
          <Route path="/company/employee/addEmployee/familyDetails" element={<AddEmployeeFamilyDetails />} />
          <Route path="/company/stats" element={<Stats />} />
        </Route>
        <Route element={<ProtectedRoute userType="employee" />}></Route>
      </Routes>
      <Toaster
        containerStyle={{
          margin: "20px 0 0 - 250px",
          padding: "0 16px",
          height: "40px",
          zIndex: 9999999,
        }}
        toastOptions={{
          success: {
            style: {
              padding: "16px",
              // width: "500vw",
            },
          },
          loading: {
            style: {
              padding: "16px",
            },
          },
          error: {
            style: {
              background: "#f87171",
              padding: "16px",
              color: "#fff",
              // width: "500vw",
            },
          },
        }}
      />
    </Router>
  );
}

export default App;
