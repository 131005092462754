import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Backdrop from "../../backdrop";
import { FaRoute } from "react-icons/fa";
import TripList from "./tripList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

function Trip() {
  const navigate = useNavigate();
  const {
    getCompanyTripDetailsFilter,
    getCompanyTripDetailsFilterLoading,
    getCompanyTripDetailsFilterError,
  } = useSelector((state) => state.CompanyTripsection);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getCompanyTripDetailsFilter?.status === 200) {
      setData(getCompanyTripDetailsFilter?.data);
    }
  }, [getCompanyTripDetailsFilter]);
  useEffect(() => {
    if (getCompanyTripDetailsFilterError?.status === 404) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 400) {
      toast.error(getCompanyTripDetailsFilterError.message, {
        id: "truck-toast",
      });
    } else if (getCompanyTripDetailsFilterError?.status === 500) {
      toast.error(getCompanyTripDetailsFilterError.error, {
        id: "truck-toast",
      });
    }
  }, [getCompanyTripDetailsFilterError]);
  return (
    <div>
      {getCompanyTripDetailsFilterLoading && <Backdrop />}
      <div className="flex justify-end">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/trip/addTrip")}
        >
          Add New Trip <FaRoute className="text-2xl" />
        </button>
      </div>
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Trips</TabsTrigger>
          <TabsTrigger value="Planned">Planned</TabsTrigger>
          <TabsTrigger value="Running">Running Trip</TabsTrigger>
          <TabsTrigger value="End">Trip End</TabsTrigger>
          <TabsTrigger value="Complete">Complete</TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          <TripList data={data} />
        </TabsContent>
        <TabsContent value="Planned">
          <TripList data={data?.filter((item)=> item.status === "Planned")} />
        </TabsContent>
        <TabsContent value="Running">
          <TripList data={data?.filter((item)=> item.status === "Trip Started")} />
        </TabsContent>
        <TabsContent value="End">
          <TripList data={data?.filter((item)=> item.status === "Trip Ended")} />
        </TabsContent>
        <TabsContent value="Complete">
          <TripList data={data?.filter((item)=> item.status === "Completed")} />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Trip;
