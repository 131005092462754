import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { deleteCompanyServiceAction, getCompanyServiceFilterAction } from "../../../../redux/company/service/action";

function DeleteService({ id, setShowModal }) {
  const dispatch = useDispatch()
  const {
    deleteCompanyService,
    deleteCompanyServiceLoading,
    deleteCompanyServiceError,
  } = useSelector((state) => state.Servicesection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["SERVICE"], 'You must type "SERVICE" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteCompanyService?.status === 200) {
        toast.success(deleteCompanyService.message, {
          id: "deleteService-toast",
        });
        const id = searchParams.get("id");
        const payload = {
          truckId: id,
        };
        dispatch(getCompanyServiceFilterAction(payload));
        setLoading(false);
        setShowModal(false);
      } else if (deleteCompanyServiceError?.status === 404) {
        toast.error(deleteCompanyServiceError.message, {
          id: "deleteService-toast",
        });
        setLoading(false);
      } else if (deleteCompanyServiceError?.status === 400) {
        toast.error(deleteCompanyServiceError.message, {
          id: "deleteService-toast",
        });
        setShowModal(false);
        setLoading(false);
      } else if (deleteCompanyServiceError?.status === 500) {
        toast.error(deleteCompanyServiceError.error, {
          id: "deleteService-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteCompanyService, deleteCompanyServiceError, loading]);

  const onSubmit = (values) => {
    if (values.deleteValue === "SERVICE") {
      dispatch(deleteCompanyServiceAction(id))
      toast.loading("Deleting Service...", { id: "deleteService-toast" });
      setLoading(true);
    }
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Service</DialogTitle>
      </DialogHeader>
        <div className="text-xl mt-5 font-semibold">
          Are you sure you want to{" "}
          <span className="text-red-500 font-bold">Delete</span> this Service?
        </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"SERVICE"</span> in Capital Letter on
                the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="SERVICE"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
                />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                type="button"
              disabled={deleteCompanyServiceLoading}
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={deleteCompanyServiceLoading}
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default DeleteService;
