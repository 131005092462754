import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyTruckDetailsAction,   getTruckFitnessAction,
  getTruckInsuranceAction,
  getTruckPermitAction, } from "../../../redux/company/truck/action";
import Backdrop from "../../backdrop";
import { PiTruck } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import TruckList from "./truckList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import TruckListExpire from "./truckListExpire";

function Truck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getCompanyTruckDetails,
    getCompanyTruckDetailsLoading,
    getTruckInsurance,
    getTruckInsuranceLoading,
    getTruckInsuranceError,
    getTruckFitness,
    getTruckFitnessLoading,
    getTruckPermit,
    getTruckPermitLoading,
  } = useSelector((state) => state.Companytrucksection);
  const [data, setData] = useState([]);
  const [insuranceData, setInsuranceData] = useState([]);
  const [fitnessData, setFitnessData] = useState([]);
  const [permitData, setPermitData] = useState([]);
  useEffect(() => {
    dispatch(getCompanyTruckDetailsAction());
    dispatch(getTruckInsuranceAction());
    dispatch(getTruckFitnessAction());
    dispatch(getTruckPermitAction());
  }, [dispatch]);
  useEffect(() => {
    if (getCompanyTruckDetails?.status === 200) {
      setData(getCompanyTruckDetails?.trucks);
    }
  }, [getCompanyTruckDetails]);
  console.log(getTruckFitness)
  useEffect(() => {
    if (getTruckFitness?.status === 200) {
      if (getTruckFitness?.data?.length > 0) {
        setFitnessData(getTruckFitness?.data);
      }
    }
  }, [getTruckFitness]);
  useEffect(() => {
    if (getTruckPermit?.status === 200) {
      if (getTruckPermit?.data?.length > 0) {
        setPermitData(getTruckPermit?.data);
      }
    }
  }, [getTruckPermit]);

  useEffect(() => {
    if (getTruckInsurance?.status === 200) {
      if (getTruckInsurance?.data?.length > 0) {
        setInsuranceData(getTruckInsurance?.data);
      }
    }
  }, [getTruckInsurance]);

  return (
    <div>
      {getCompanyTruckDetailsLoading && <Backdrop />}
      <div className="flex justify-end">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/truck/addTruck")}
        >
          Add New Truck <PiTruck className="text-2xl" />
        </button>
      </div>
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Trucks</TabsTrigger>
          <TabsTrigger value="notSell">In Company</TabsTrigger>
          <TabsTrigger value="sell">Selled</TabsTrigger>
          <TabsTrigger value="subscribe">Not Subscribe</TabsTrigger>
          {insuranceData?.length > 0 && (
            <TabsTrigger value="insurance">Expire Insurance</TabsTrigger>
          )}
          {fitnessData?.length > 0 && (
            <TabsTrigger value="fitness">Expire Fitness</TabsTrigger>
          )}
          {permitData?.length > 0 && (
            <TabsTrigger value="permit">Expire Permit</TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="All">
          <TruckList data={data} />
        </TabsContent>
        <TabsContent value="notSell">
          <TruckList data={data.filter((item) => item.isSell === false)} />
        </TabsContent>
        <TabsContent value="sell">
          <TruckList data={data.filter((item) => item.isSell === true)} />
        </TabsContent>
        <TabsContent value="subscribe">
          <TruckList data={data.filter((item) => item.isSubscribe === false)} />
        </TabsContent>
        <TabsContent value="insurance">
          <TruckListExpire data={insuranceData} section="insurance" />
        </TabsContent>
        <TabsContent value="fitness">
          <TruckListExpire data={fitnessData} section="fitness" />
        </TabsContent>
        <TabsContent value="permit">
          <TruckListExpire data={permitData} section="permit" />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Truck;
