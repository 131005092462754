import {
  DELETE_COMPANY_EMPLOYEE,
  DELETE_COMPANY_EMPLOYEE_ERROR,
  DELETE_COMPANY_EMPLOYEE_SUCCESS,
  GET_COMPANY_EMPLOYEE_DETAILS,
  GET_COMPANY_EMPLOYEE_DETAILS_ERROR,
  GET_COMPANY_EMPLOYEE_DETAILS_ID,
  GET_COMPANY_EMPLOYEE_DRIVER,
  GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS,
  GET_COMPANY_EMPLOYEE_DRIVER_ERROR,
  GET_COMPANY_EMPLOYEE_HELPER,
  GET_COMPANY_EMPLOYEE_HELPER_SUCCESS,
  GET_COMPANY_EMPLOYEE_HELPER_ERROR,
  GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR,
  GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS,
  GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_DETAILS,
  POST_COMPANY_EMPLOYEE_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  POST_EMPLOYEE_FIND,
  POST_EMPLOYEE_FIND_SUCCESS,
  POST_EMPLOYEE_FIND_ERROR,
  GET_COMPANY_EMPLOYEE_FILTER_LIST,
  GET_COMPANY_EMPLOYEE_FILTER_LIST_SUCCESS,
  GET_COMPANY_EMPLOYEE_FILTER_LIST_ERROR,
  PATCH_COMPANY_EMPLOYEE_SALARY,
  PATCH_COMPANY_EMPLOYEE_SALARY_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_SALARY_ERROR,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR,
} from "../../actions";

// Employee Details
export const getCompanyEmployeeDetailsAction = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS,
  payload: info,
});
export const getCompanyEmployeeDetailsSuccess = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  payload: info,
});
export const getCompanyEmployeeDetailsError = (error) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS_ERROR,
  payload: error,
});
export const getCompanyEmployeeDetailsIdAction = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS_ID,
  payload: info,
});
export const getCompanyEmployeeDetailsIdSuccess = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS,
  payload: info,
});
export const getCompanyEmployeeDetailsIdError = (error) => ({
  type: GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR,
  payload: error,
});
export const postCompanyEmployeeDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_DETAILS,
  payload: info,
});
export const postCompanyEmployeeDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeeDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_DETAILS_ERROR,
  payload: error,
});
export const postEmployeeFindAction = (info) => ({
  type: POST_EMPLOYEE_FIND,
  payload: info,
});
export const postEmployeeFindSuccess = (info) => ({
  type: POST_EMPLOYEE_FIND_SUCCESS,
  payload: info,
});
export const postEmployeeFindError = (error) => ({
  type: POST_EMPLOYEE_FIND_ERROR,
  payload: error,
});
export const patchCompanyEmployeeDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR,
  payload: error,
});
export const deleteCompanyEmployeeAction = (info) => ({
  type: DELETE_COMPANY_EMPLOYEE,
  payload: info,
});
export const deleteCompanyEmployeeSuccess = (info) => ({
  type: DELETE_COMPANY_EMPLOYEE_SUCCESS,
  payload: info,
});
export const deleteCompanyEmployeeError = (error) => ({
  type: DELETE_COMPANY_EMPLOYEE_ERROR,
  payload: error,
});

// Employee Personal Details
export const postCompanyEmployeePersonalDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  payload: info,
});
export const postCompanyEmployeePersonalDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeePersonalDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyEmployeePersonalDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  payload: info,
});
export const patchCompanyEmployeePersonalDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeePersonalDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  payload: error,
});

// Employee Attendance Details
export const postCompanyEmployeeAttendenceDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  payload: info,
});
export const postCompanyEmployeeAttendenceDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeeAttendenceDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyEmployeeAttendenceDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeAttendenceDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeAttendenceDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  payload: error,
});

// Employee Family Details
export const postCompanyEmployeeFamilyDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  payload: info,
});
export const postCompanyEmployeeFamilyDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeeFamilyDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyEmployeeFamilyDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeFamilyDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeFamilyDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  payload: error,
});

// Employee Experience Details
export const postCompanyEmployeeExperienceDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  payload: info,
});
export const postCompanyEmployeeExperienceDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeeExperienceDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyEmployeeExperienceDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeExperienceDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeExperienceDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  payload: error,
});

// Employee License Details
export const postCompanyEmployeeLicenseDetailsAction = (info) => ({
  type: POST_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  payload: info,
});
export const postCompanyEmployeeLicenseDetailsSuccess = (info) => ({
  type: POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyEmployeeLicenseDetailsError = (error) => ({
  type: POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyEmployeeLicenseDetailsAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeLicenseDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeLicenseDetailsError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  payload: error,
});

// Employee Role Details
export const getCompanyEmployeeDriverAction = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DRIVER,
  payload: info,
});
export const getCompanyEmployeeDriverSuccess = (info) => ({
  type: GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS,
  payload: info,
});
export const getCompanyEmployeeDriverError = (error) => ({
  type: GET_COMPANY_EMPLOYEE_DRIVER_ERROR,
  payload: error,
});
export const getCompanyEmployeeHelperAction = (info) => ({
  type: GET_COMPANY_EMPLOYEE_HELPER,
  payload: info,
});
export const getCompanyEmployeeHelperSuccess = (info) => ({
  type: GET_COMPANY_EMPLOYEE_HELPER_SUCCESS,
  payload: info,
});
export const getCompanyEmployeeHelperError = (error) => ({
  type: GET_COMPANY_EMPLOYEE_HELPER_ERROR,
  payload: error,
});

//update salary of employee
export const patchCompanyEmployeeSalaryAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_SALARY,
  payload: info,
});
export const patchCompanyEmployeeSalarySuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_SALARY_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeSalaryError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_SALARY_ERROR,
  payload: error,
});
//terminate employee
export const patchCompanyEmployeeTerminateAction = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS,
  payload: info,
});
export const patchCompanyEmployeeTerminateSuccess = (info) => ({
  type: PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyEmployeeTerminateError = (error) => ({
  type: PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR,
  payload: error,
});

