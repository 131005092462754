import { getCompanyTripDetailsAction } from "../../../../redux/company/trip/action";
import {
  getExpenseTripByIdAction,
  patchExpenseTripAction,
  postExpenseTripAction,
} from "../../../../redux/company/trip/extraExpense/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../datepicker/datePicker";
import FileUpload from "../../../fileupload/fileUpload";
import Backdrop from "../../../backdrop";

function AddExtraExpenseTrip() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { getCompanyTripDetails, getCompanyTripDetailsLoading } = useSelector(
    (state) => state.CompanyTripsection
  );
  const {
    expenseTripById,
    expenseTripByIdLoading,
    expenseTripByIdError,
    patchExpenseTrip,
    patchExpenseTripLoading,
    patchExpenseTripError,
    postExpenseTrip,
    postExpenseTripLoading,
    postExpenseTripError,
  } = useSelector((state) => state.ExpenseTripSection);

  const [truckNumber, setTripNumber] = useState("");
  const [truckData, setTripData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    const id = searchParams.get("id");
    const truckId = searchParams.get("tripId");
    if (truckId !== null) {
      setTripNumber(truckId);
    }
    if (!edit) {
      dispatch(getExpenseTripByIdAction(id));
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getCompanyTripDetailsAction());
  }, []);
  useEffect(() => {
    if (getCompanyTripDetails?.status === 200) {
      setTripData(getCompanyTripDetails?.data);
    }
  }, [getCompanyTripDetails]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (expenseTripById?.status === 200) {
        if (expenseTripById?.data?._id !== null) {
          setData(expenseTripById?.data);
        }
      }
      if (expenseTripByIdError === 400) {
        toast.error(expenseTripByIdError?.message);
      } else if (expenseTripByIdError === 404) {
        toast.error(expenseTripByIdError.message);
      } else if (expenseTripByIdError === 500) {
        toast.error(expenseTripByIdError.error);
      }
    }
  }, [expenseTripById, expenseTripByIdError]);
  useEffect(() => {
    if (loader) {
      if (postExpenseTripError?.status === 404) {
        toast.error(postExpenseTripError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postExpenseTripError?.status === 400) {
        toast.error(postExpenseTripError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postExpenseTripError?.status === 500) {
        toast.error(postExpenseTripError.error, {
          id: "addDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [postExpenseTripError]);
  useEffect(() => {
    if (loader) {
      if (postExpenseTrip?.status === 200) {
        toast.success(postExpenseTrip.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [postExpenseTrip]);
  useEffect(() => {
    if (loader) {
      if (patchExpenseTrip?.status === 200) {
        toast.success(patchExpenseTrip.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [patchExpenseTrip]);
  useEffect(() => {
    if (loader) {
      if (patchExpenseTripError?.status === 404) {
        toast.error(patchExpenseTripError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchExpenseTripError?.status === 400) {
        toast.error(patchExpenseTripError.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      } else if (patchExpenseTripError?.status === 500) {
        toast.error(patchExpenseTripError.error, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [patchExpenseTripError]);

  const initialValues = {
    tripId: truckNumber || data?.truckId || "",
    expenseDate: data?.expenseDate ? new Date(data?.expenseDate) : "",
    billNumber: data?.billNumber || null,
    expenseName: data?.expenseName || "",
    billImage: data?.billImage || "",
    cost: data?.cost || null,
    description: data?.description || "",
  };
  const validationSchema = Yup.object({
    tripId: Yup.string().required("Trip ID is required"),
    expenseDate: Yup.date().required("Service date is required"),
    billNumber: Yup.string().required("Service bill number is required"),
    expenseName: Yup.string().required("Service type is required"),
    cost: Yup.number().required("Service cost is required"),
    description: Yup.string(),
  });

  const onSubmit = (values) => {
    dispatch(postExpenseTripAction(values));
    setLoader(true);
    toast.loading("Adding Expense...", { id: "addDetails-toast" });
  };

  const onUpdate = (values) => {
    const payload = { id: data._id, body: values };
    dispatch(patchExpenseTripAction(payload));
    setLoader(true);
    toast.loading("Updating Expense...", { id: "updateDetails-toast" });
  };
  return (
    <div>
      {(expenseTripByIdLoading || getCompanyTripDetailsLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Add Expense</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
              <div>
                <label htmlFor="truckId">
                  Trip ID<span>*</span>
                </label>
                <Field
                  as="select"
                  id="tripId"
                  name="tripId"
                  className=""
                  disabled={truckNumber}
                >
                  <option value=""></option>
                  {truckData.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.tokenNumber} ({item?.startLocation} -{" "}
                      {item?.endLocation})
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="expenseName">
                  Expense Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="expenseName"
                  placeHolder="Name"
                  name="expenseName"
                  className=""
                />

                <ErrorMessage
                  name="expenseName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="expenseDate">
                  Expense Date<span>*</span>
                </label>

                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="expenseDate"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="expenseDate"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="billNumber">
                  Bill No<span>*</span>
                </label>
                <Field
                  type="text"
                  placeHolder="Service Bill No"
                  id="billNumber"
                  name="billNumber"
                  className=""
                />
                <ErrorMessage
                  name="billNumber"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="billImage">Bill Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="billImage"
                  values={values}
                />
                <ErrorMessage
                  name="billImage"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="cost">
                  Total Cost<span>*</span>
                </label>
                <Field type="number" id="cost" name="cost" className="" />
                <ErrorMessage name="cost" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  className=""
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <div className="flex">
                <button
                  type="submit"
                  className="primaryButton mt-auto"
                  disabled={patchExpenseTripLoading || postExpenseTripLoading}
                >
                  {data?._id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddExtraExpenseTrip;
