import { Dialog } from "../../../ui/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertInsuranceModal from "./alertInsuranceModal";
import AlertFitnessModal from "./alertFitnessModal";
import AlertPermitModal from "./alertPermitModal";
import {
  getTruckAlertFitnessAction,
  getTruckAlertInsuranceAction,
  getTruckAlertPermitAction,
} from "../../../redux/company/alert/action";
import { getCookies, setCookies } from "../../../auth/auth";

function Alert() {
  const dispatch = useDispatch();
  const { getTruckAlertInsurance, getTruckAlertFitness, getTruckAlertPermit } =
    useSelector((state) => state.CompanyAlertSection);

  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [loaderInsurance, setLoaderInsurance] = useState(false);
  const [loaderFitness, setLoaderFitness] = useState(false);
  const [loaderPermit, setLoaderPermit] = useState(false);
  const [showFitnessModal, setShowFitnessModal] = useState(false);
  const [showPermitModal, setShowPermitModal] = useState(false);
  const [insuranceData, setInsuranceData] = useState();
  const [fitnessData, setFitnessData] = useState();
  const [permitData, setPermitData] = useState();

  const checkAndFetchData = async () => {
    // Check lastFetchInsurance
    const lastFetchInsurance = await getCookies("lastFetchInsurance");
    if (!(lastFetchInsurance === true)) {
      // Fetch Insurance data
      console.log("insuracne");
      dispatch(getTruckAlertInsuranceAction());
      setLoaderInsurance(true);
      return;
    }

    // Check lastFetchFitness
    const lastFetchFitness = await getCookies("lastFetchFitness");
    if (!(lastFetchFitness === true)) {
      // Fetch Fitness data
      console.log("fit");
      dispatch(getTruckAlertFitnessAction());
      setLoaderFitness(true);
      return;
    }

    // Check lastFetchPermit
    const lastFetchPermit = await getCookies("lastFetchPermit");
    if (!(lastFetchPermit === true)) {
      // Fetch Permit data
      console.log("per");
      dispatch(getTruckAlertPermitAction());
      setLoaderPermit(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      console.log("object");
      checkAndFetchData(); // Start checking and fetching data after 1 minute
    }, 6000); // 1 minute delay

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  // Show modals based on the fetched data
  useEffect(() => {
    if (
      getTruckAlertInsurance?.status === 200 &&
      getTruckAlertInsurance?.data?.length > 0 &&
      loaderInsurance === true
    ) {
      setLoaderInsurance(false);
      setInsuranceData(getTruckAlertInsurance?.data);
      setShowInsuranceModal(true);
    }
  }, [getTruckAlertInsurance]);
  useEffect(() => {
    if (
      getTruckAlertFitness?.status === 200 &&
      getTruckAlertFitness?.data?.length > 0 &&
      loaderFitness === true
    ) {
      setLoaderFitness(false);
      setFitnessData(getTruckAlertFitness?.data);
      setShowFitnessModal(true);
    }
  }, [getTruckAlertFitness]);
  useEffect(() => {
    if (
      getTruckAlertPermit?.status === 200 &&
      getTruckAlertPermit?.data?.length > 0 &&
      loaderPermit === true
    ) {
      setLoaderPermit(false);
      setPermitData(getTruckAlertPermit?.data);
      setShowPermitModal(true);
    }
  }, [getTruckAlertPermit]);

  // Handle modal close and save the action to cookies to avoid repeated alerts
  const handleModalClose = async (alertType) => {
    if (alertType === "insurance") {
      setShowInsuranceModal(false);
      await setCookies("lastFetchInsurance", true, 1); // Set insurance as fetched
      checkAndFetchData(); // Check fitness next
    } else if (alertType === "fitness") {
      setShowFitnessModal(false);
      await setCookies("lastFetchFitness", true, 1); // Set fitness as fetched
      checkAndFetchData(); // Check permit next
    } else if (alertType === "permit") {
      setShowPermitModal(false);
      await setCookies("lastFetchPermit", true, 1); // Set permit as fetched
    }
  };

  return (
    <div>
      {/* Insurance Modal */}
      <Dialog open={showInsuranceModal}>
        <AlertInsuranceModal
          data={insuranceData}
          setShowModal={() => handleModalClose("insurance")}
        />
      </Dialog>

      {/* Fitness Modal */}
      <Dialog open={showFitnessModal}>
        <AlertFitnessModal
          data={fitnessData}
          setShowModal={() => handleModalClose("fitness")}
        />
      </Dialog>

      {/* Permit Modal */}
      <Dialog open={showPermitModal}>
        <AlertPermitModal
          data={permitData}
          setShowModal={() => handleModalClose("permit")}
        />
      </Dialog>
    </div>
  );
}

export default Alert;
