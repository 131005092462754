import { getCompanyEmployeeDetailsIdAction } from "../../../redux/company/employee/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import EmployeeCard from "./employeeCard";
import PersonalDetails from "./personalDetails";
import Backdrop from "../../backdrop";
import FamilyDetails from "./familyDetails";
import toast from "react-hot-toast";

function EmployeeDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    getCompanyEmployeeDetailsId,
    getCompanyEmployeeDetailsIdLoading,
    getCompanyEmployeeDetailsIdError,
  } = useSelector((state) => state.CompanyEmployeesection);
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(getCompanyEmployeeDetailsIdAction(id));
  }, [id]);

  useEffect(() => {
    if (getCompanyEmployeeDetailsId.status === 200) {
      setData(getCompanyEmployeeDetailsId.data);
    }
  }, [getCompanyEmployeeDetailsId]);
  useEffect(() => {
    if (
      getCompanyEmployeeDetailsIdError.status === 400 ||
      getCompanyEmployeeDetailsIdError.status === 404
    ) {
      toast.error(getCompanyEmployeeDetailsIdError.message);
    } else if (getCompanyEmployeeDetailsIdError.status === 500) {
      toast.error(getCompanyEmployeeDetailsIdError.error);
    }
  }, [getCompanyEmployeeDetailsIdError]);

  return (
    <div>
      {getCompanyEmployeeDetailsIdLoading && <Backdrop />}

      <EmployeeCard data={data?.companyEmployees.employeeId} isDetails={true} />
      <div className="flex flex-col gap-10">
        <PersonalDetails data={data?.companyEmployees} />
        <FamilyDetails data={data?.family} />
      </div>
    </div>
  );
}

export default EmployeeDetails;
