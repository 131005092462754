import { postEmployeeFindAction } from "../../../redux/company/employee/action";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import EmployeeCard from "./employeeCard";

function AddEmployeeFind() {
  const dispatch = useDispatch();
  const { postEmployeeFind, postEmployeeFindLoading, postEmployeeFindError } =
    useSelector((state) => state.CompanyEmployeesection);
  const [employees, setEmployees] = useState([]);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader) {
      if (postEmployeeFind.status === 200) {
        setEmployees(postEmployeeFind.data);
        toast.success(postEmployeeFind.message, { id: "find" });
        setLoader(false);
      }
    }
  }, [postEmployeeFind]);
  useEffect(() => {
    if (loader) {
      if (postEmployeeFindError.status === 404) {
        toast.error(postEmployeeFind.message, { id: "find" });
      } else if (postEmployeeFindError.status === 400) {
        toast.error(postEmployeeFind.message, { id: "find" });
      } else if (postEmployeeFindError.status === 500) {
        toast.error(postEmployeeFind.error, { id: "find" });
      }
    }
  }, [postEmployeeFind]);

  const searchSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phoneNumber: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    dateOfBirth: Yup.date(),
    gender: Yup.string(),
    email: Yup.string().email("Invalid email format"),
  });

  const handleSubmit = async (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(postEmployeeFindAction(payload));
    setLoader(true);
    toast.loading("Finding Employee", { id: "find" });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Search Employees</h2>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phoneNumber: "",
          city: "",
          state: "",
          dateOfBirth: "",
          gender: "",
          email: "",
        }}
        validationSchema={searchSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            <div>
              <label htmlFor="firstName">First Name</label>
              <Field
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="error"
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <Field
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage name="lastName" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="phoneNumber">Phone Number</label>
              <Field
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="error"
              />
            </div>
            <div>
              <label htmlFor="city">City</label>
              <Field
                type="text"
                id="city"
                name="city"
                placeholder="City"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage name="city" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="state">State</label>
              <Field
                type="text"
                id="state"
                name="state"
                placeholder="State"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage name="state" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <Field
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage
                name="dateOfBirth"
                component="div"
                className="error"
              />
            </div>
            <div>
              <label htmlFor="gender">Gender</label>
              <Field
                as="select"
                id="gender"
                name="gender"
                className="w-full px-4 py-2 border rounded-lg"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Field>
              <ErrorMessage name="gender" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="w-full px-4 py-2 border rounded-lg"
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>
            <button
              type="submit"
              className="primaryButton mt-auto h-fit col-span-full"
            >
              Search
            </button>
          </Form>
        )}
      </Formik>
      {employees?.length > 0
        ? employees.map((item) => <EmployeeCard data={item} />)
        : ""}
    </div>
  );
}

export default AddEmployeeFind;
