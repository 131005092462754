import { ErrorMessage, Field, Form, Formik } from "formik";
import useDeviceInfoAndLocation from "../../../auth/useDeviceAndLocation";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  postRegisterAction,
  postResendOtpAction,
} from "../../../redux/auth/action";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import AddEmployeeVerifyOtp from "./addEmployeeVerifyOtp";

function AddEmployeeNewUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deviceInfo, location } = useDeviceInfoAndLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email")

  const { postRegister, postRegisterLoading, postRegisterError } = useSelector(
    (state) => state.Authsection
  );

  const [loader, setLoader] = useState(false);

  const initialValues = {
    userType: "employee",
    email: "",
    password: "",
    confirmPassword: "",
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    latitude: location.latitude,
    longitude: location.longitude,
  };

  const validationSchema = Yup.object({
    userType: Yup.string().required("Required"),
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("passwordMatch"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t("passwordValidation")
      )
      .required(t("required")),
  });

  useEffect(() => {
    if (loader) handler();
  }, [postRegister]);

  const handler = async () => {
    if (postRegister.status === 200) {
      toast.success(postRegister.message, { id: "register-toast" });
      searchParams.set("email", postRegister.email);
      setSearchParams(searchParams);
    } else if (postRegister.status === 400) {
      toast.error(postRegister.message, { id: "register-toast" });
    } else if (postRegister.status === 500) {
      toast.error(postRegister.error, { id: "register-toast" });
    }
  };

  const onSubmit = (values) => {
    dispatch(postRegisterAction(values));
    setLoader(true);
    toast.loading("Registering...", { id: "register-toast" });
  };

  return (
    <div className="">
      {/* {(getStateLoading || getCityLoading || getCompanyDetailsLoading) && (
        <Backdrop />
      )} */}
      <h2 className="text-2xl font-bold mb-4">Create Employee</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="email" className="label">
                  {t("email")} <span>*</span>
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  disabled={email}
                  autoFocus
                  placeholder={t("email")}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none "
                  />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="password" className="label">
                  {t("password")} <span>*</span>
                </label>
                <Field
                  type="password"
                  id="password"
                  disabled={email}
                  name="password"
                  placeholder="Password"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                  />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                  />
              </div>
              <div>
                <label htmlFor="confirmPassword" className="label">
                  {t("confirmPassword")} <span>*</span>
                </label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  disabled={email}
                  placeholder={t("confirmPassword")}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
              </div>
              {email === null && 
              <button
                type="submit"
                disabled={postRegisterLoading}
                className="primaryButton"
              >
                {t("registration")}
              </button>
}
            </div>
          </Form>
        )}
      </Formik>

      {email !== null && <AddEmployeeVerifyOtp/>}
    </div>
  );
}

export default AddEmployeeNewUser;
