import { GET_ALERT_FITNESS, GET_ALERT_FITNESS_ERROR, GET_ALERT_FITNESS_SUCCESS, GET_ALERT_INSURANCE, GET_ALERT_INSURANCE_ERROR, GET_ALERT_INSURANCE_SUCCESS, GET_ALERT_PERMIT, GET_ALERT_PERMIT_ERROR, GET_ALERT_PERMIT_SUCCESS } from "../../actions";

export const getTruckAlertInsuranceAction = (info) => ({
    type: GET_ALERT_INSURANCE,
    payload: info,
  });
  export const getTruckAlertInsuranceSuccess = (info) => ({
    type: GET_ALERT_INSURANCE_SUCCESS,
    payload: info,
  });
  export const getTruckAlertInsuranceError = (error) => ({
    type: GET_ALERT_INSURANCE_ERROR,
    payload: error,
  });
  export const getTruckAlertFitnessAction = (info) => ({
    type: GET_ALERT_FITNESS,
    payload: info,
  });
  export const getTruckAlertFitnessSuccess = (info) => ({
    type: GET_ALERT_FITNESS_SUCCESS,
    payload: info,
  });
  export const getTruckAlertFitnessError = (error) => ({
    type: GET_ALERT_FITNESS_ERROR,
    payload: error,
  });
  export const getTruckAlertPermitAction = (info) => ({
    type: GET_ALERT_PERMIT,
    payload: info,
  });
  export const getTruckAlertPermitSuccess = (info) => ({
    type: GET_ALERT_PERMIT_SUCCESS,
    payload: info,
  });
  export const getTruckAlertPermitError = (error) => ({
    type: GET_ALERT_PERMIT_ERROR,
    payload: error,
  });
