const { GET_ALERT_INSURANCE, GET_ALERT_INSURANCE_SUCCESS, GET_ALERT_INSURANCE_ERROR, GET_ALERT_FITNESS, GET_ALERT_FITNESS_SUCCESS, GET_ALERT_FITNESS_ERROR, GET_ALERT_PERMIT, GET_ALERT_PERMIT_SUCCESS, GET_ALERT_PERMIT_ERROR, PATCH_ALERT_INSURANCE, PATCH_ALERT_INSURANCE_SUCCESS, PATCH_ALERT_INSURANCE_ERROR, PATCH_ALERT_FITNESS, PATCH_ALERT_FITNESS_SUCCESS, PATCH_ALERT_FITNESS_ERROR, PATCH_ALERT_PERMIT, PATCH_ALERT_PERMIT_SUCCESS, PATCH_ALERT_PERMIT_ERROR } = require("../../actions");
  
  const INIT_STATE = {
    getTruckAlertInsurance: {},
    getTruckAlertInsuranceLoading: false,
    getTruckAlertInsuranceError: "",
    getTruckAlertFitness: {},
    getTruckAlertFitnessLoading: false,
    getTruckAlertFitnessError: "",
    getTruckAlertPermit: {},
    getTruckAlertPermitLoading: false,
    getTruckAlertPermitError: "",
   
  };
  
  const CompanyAlertSection = (state = INIT_STATE, action) => {
    switch (action.type) {

      case GET_ALERT_INSURANCE:
        return {
          ...state,
          getTruckAlertInsurance: {},
          getTruckAlertInsuranceLoading: true,
        };
      case GET_ALERT_INSURANCE_SUCCESS:
        return {
          ...state,
          getTruckAlertInsurance: action.payload,
          getTruckAlertInsuranceLoading: false,
          getTruckAlertInsuranceError: "",
        };
  
      case GET_ALERT_INSURANCE_ERROR:
        return {
          ...state,
          getTruckAlertInsuranceLoading: false,
          getTruckAlertInsuranceError: action.payload,
        };
      case GET_ALERT_FITNESS:
        return {
          ...state,
          getTruckAlertFitness: {},
          getTruckAlertFitnessLoading: true,
        };
      case GET_ALERT_FITNESS_SUCCESS:
        return {
          ...state,
          getTruckAlertFitness: action.payload,
          getTruckAlertFitnessLoading: false,
          getTruckAlertFitnessError: "",
        };
  
      case GET_ALERT_FITNESS_ERROR:
        return {
          ...state,
          getTruckAlertFitnessLoading: false,
          getTruckAlertFitnessError: action.payload,
        };
      case GET_ALERT_PERMIT:
        return {
          ...state,
          getTruckAlertPermit: {},
          getTruckAlertPermitLoading: true,
        };
      case GET_ALERT_PERMIT_SUCCESS:
        return {
          ...state,
          getTruckAlertPermit: action.payload,
          getTruckAlertPermitLoading: false,
          getTruckAlertPermitError: "",
        };
  
      case GET_ALERT_PERMIT_ERROR:
        return {
          ...state,
          getTruckAlertPermitLoading: false,
          getTruckAlertPermitError: action.payload,
        };
//
   
      default:
        return { ...state };
    }
  };
  
  export default CompanyAlertSection;
  