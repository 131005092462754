import {
  getSalaryByIdAction,
  getSalaryByUserAction,
  patchSalaryAction,
  postSalaryAction,
} from "../../../../redux/company/employee/salary/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../../components/datepicker/datePicker";
import toast from "react-hot-toast";
import Backdrop from "../../../../components/backdrop";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCompanyEmployeeDetailsAction } from "../../../../redux/company/employee/action";

function PaySalary({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("employeeId");
  const {
    getCompanyEmployeeDetails,
    getCompanyEmployeeDetailsLoading,
    getCompanyEmployeeDetailsError,
  } = useSelector((state) => state.CompanyEmployeesection);
  const {
    postSalary,
    postSalaryLoading,
    postSalaryError,
    patchSalary,
    patchSalaryLoading,
    patchSalaryError,
    getSalaryByUser,
    getSalaryByUserLoading,
    getSalaryByUserError,
    getSalaryById,
    getSalaryByIdLoading,
    getSalaryByIdError,
  } = useSelector((state) => state.SalarySection);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const [excludeDate, setExcludeDate] = useState([]);

  useEffect(() => {
    const isEdit = searchParams.get("id") !== null;
    if (isEdit) {
      dispatch(getSalaryByIdAction(searchParams.get("id")));
    } else {
      const payload = { 
        id: id,
      };
      dispatch(getCompanyEmployeeDetailsAction(payload));
    }
    dispatch(getSalaryByUserAction(id));
  }, []);
  useEffect(() => {
    if (loading) {
      if (postSalary?.status === 200) {
        toast.success(postSalary.message, { id: "post_salary" });
        setLoading(false);
        navigate(-1);
      }
    }
  }, [postSalary]);
  useEffect(() => {
    if (loading) {
      if (postSalaryError?.status === 400) {
        toast.error(postSalaryError.message, { id: "post_salary" });
        setLoading(false);
      } else if (postSalaryError?.status === 404) {
        toast.error(postSalaryError.message, { id: "post_salary" });
        setLoading(false);
      } else if (postSalaryError?.status === 500) {
        toast.error(postSalaryError.error, { id: "post_salary" });
        setLoading(false);
      }
    }
  }, [postSalaryError]);
  useEffect(() => {
    if (loading) {
      if (patchSalary?.status === 200) {
        toast.success(patchSalary.message, { id: "patch_salary" });
        setLoading(false);
        navigate(-1);
      }
    }
  }, [patchSalary]);
  useEffect(() => {
    if (loading) {
      if (patchSalaryError?.status === 400) {
        toast.error(patchSalaryError.message, { id: "patch_salary" });
        setLoading(false);
      } else if (patchSalaryError?.status === 404) {
        toast.error(patchSalaryError.message, { id: "patch_salary" });
        setLoading(false);
      } else if (patchSalaryError?.status === 500) {
        toast.error(patchSalaryError.error, { id: "patch_salary" });
        setLoading(false);
      }
    }
  }, [patchSalaryError]);
  useEffect(() => {
    if (getCompanyEmployeeDetails.status === 200) {
      setData(getCompanyEmployeeDetails.data[0]);
    }
  }, [getCompanyEmployeeDetails]);
  useEffect(() => {
    if (
      getCompanyEmployeeDetailsError.status === 400 ||
      getCompanyEmployeeDetailsError.status === 404
    ) {
      toast.error(getCompanyEmployeeDetailsError.message);
    } else if (getCompanyEmployeeDetailsError.status === 500) {
      toast.error(getCompanyEmployeeDetailsError.error);
    }
  }, [getCompanyEmployeeDetailsError]);
  useEffect(() => {
    if (getSalaryById.status === 200) {
      setEdit(getSalaryById.data);
    }
  }, [getSalaryById]);
  useEffect(() => {
    if (
      getSalaryByIdError.status === 400 ||
      getSalaryByIdError.status === 404
    ) {
      toast.error(getSalaryByIdError.message);
    } else if (getSalaryByIdError.status === 500) {
      toast.error(getSalaryByIdError.error);
    }
  }, [getSalaryByIdError]);
  useEffect(() => {
    if (getSalaryByUser?.status == 200) {
      if (getSalaryByUser?.data?.length > 0) {
        const isEdit = searchParams.get("id") !== null;
        let filterData = getSalaryByUser?.data;
        if (isEdit) {
          filterData = getSalaryByUser?.data?.filter(
            (item) => item._id !== searchParams.get("id")
          );
        } else {
          filterData = getSalaryByUser?.data;
        }
        console.log(filterData);
        const excludeDates = filterData?.map(
          (record) => new Date(record.salaryMonth)
        );
        setExcludeDate(excludeDates);
      }
    }
  }, [getSalaryByUser]);

  const validationSchema = Yup.object({
    paymentType: Yup.string().required("Payment type is required"),
    salaryMonth: Yup.date().required("Salary month is required").nullable(),
    paymentDate: Yup.date().required("Payment date is required").nullable(),
    addOns: Yup.number(),
  });
  const onSubmit = (values) => {
    const payload = {
      employeeId: id,
      salary: values.salary,
      salaryMonth: values.salaryMonth,
      addOns: values.addOns,
      paymentDate: values.paymentDate,
      paymentType: values.paymentType,
      desctiption: values.desctiption,
    };
    dispatch(postSalaryAction(payload));
    setLoading(true);
    toast.loading("Saving data....", { id: "post_salary" });
  };
  const onUpdate = (values) => {
    const payload = {
      id: searchParams.get("id"),
      body: {
        employeeId: id,
        salary: values.salary,
        salaryMonth: values.salaryMonth,
        addOns: values.addOns,
        totalPay: values.salary + values.addOns,
        paymentDate: values.paymentDate,
        paymentType: values.paymentType,
        desctiption: values.desctiption,
      },
    };
    
    dispatch(patchSalaryAction(payload));
    setLoading(true);
    toast.loading("Updating data....", { id: "patch_salary" });
  };

  return (
    <div>
      {(postSalaryLoading ||
        getCompanyEmployeeDetailsLoading ||
        getSalaryByUserLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">{edit ? "Update" : "Pay"} Salary</h2>

      <Formik
        initialValues={{
          salary: edit?.salary || data?.salary,
          paymentType: edit?.paymentType || "Cash",
          salaryMonth: edit?.salaryMonth ? new Date(edit?.salaryMonth) : "",
          addOns: edit?.addOns || 0,
          paymentDate: edit?.paymentDate ? new Date(edit?.paymentDate) : "",
          desctiption: edit?.desctiption || "",
        }}
        validationSchema={validationSchema}
        onSubmit={edit?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div className="mb-4">
                <label htmlFor="salary">
                  Salary <span>*</span>
                </label>
                <Field
                  type="number"
                  id="salary"
                  name="salary"
                  disabled={true}
                  className=""
                  placeholder="Salary"
                />
                <ErrorMessage name="salary" component="div" className="error" />
              </div>
              <div className="mb-4">
                <label htmlFor="paymentType">
                  Payment Type <span>*</span>
                </label>
                <Field
                  as="select"
                  id="paymentType"
                  name="paymentType"
                  className=""
                >
                  <option value="Cash">Cash</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Check">Check</option>
                  <option value="UPI">UPI</option>
                </Field>
                <ErrorMessage
                  name="paymentType"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="salaryMonth">Salary Month *</label>
                <DatePicker
                  id="salaryMonth"
                  name="salaryMonth"
                  placeHolder="Salary Month"
                  showMonthYearPicker
                  dateFormat="MMM / YYYY"
                  excludeDates={excludeDate}
                />
                <ErrorMessage
                  name="salaryMonth"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="addOns">
                  Extra Pay <span>*</span>
                </label>
                <Field
                  type="number"
                  id="addOns"
                  name="addOns"
                  className=""
                  placeholder="Extra pay"
                />
                <ErrorMessage name="addOns" component="div" className="error" />
              </div>
              <div className="mb-4">
                <label htmlFor="paymentDate">Payment Date *</label>
                <DatePicker
                  id="paymentDate"
                  name="paymentDate"
                  placeHolder="Payment Date"
                />
                <ErrorMessage
                  name="paymentDate"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  placeHolder="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <button
                className="primaryButton bg-green-600 hover:bg-green-800  whitespace-nowrap"
                type="submit"
                disabled={postSalaryLoading}
              >
              {edit ? "Update" : ` Pay ${values.salary + values.addOns}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PaySalary;
