import { getCompanyTruckDetailsAction } from "../../../../redux/company/truck/action";
import {
  getCompanyServiceIdAction,
  patchCompanyServiceAction,
  postCompanyServiceAction,
} from "../../../../redux/company/service/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../datepicker/datePicker";
import FileUpload from "../../../fileupload/fileUpload";
import Backdrop from "../../../backdrop";

function AddService() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { getCompanyTruckDetails, getCompanyTruckDetailsLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const {
    getCompanyServiceId,
    getCompanyServiceIdLoading,
    getCompanyServiceIdError,
    patchCompanyService,
    patchCompanyServiceLoading,
    patchCompanyServiceError,
    postCompanyService,
    postCompanyServiceLoading,
    postCompanyServiceError,
  } = useSelector((state) => state.Servicesection);

  const [truckNumber, setTruckNumber] = useState("");
  const [truckData, setTruckData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    const id = searchParams.get("id");
    const truckId = searchParams.get("truckId");
    if (truckId !== null) {
      setTruckNumber(truckId);
    }
    if (!edit) {
      dispatch(getCompanyServiceIdAction(id));
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(getCompanyTruckDetailsAction());
  }, []);
  useEffect(() => {
    if (getCompanyTruckDetails?.status === 200) {
      setTruckData(getCompanyTruckDetails?.trucks);
    }
  }, [getCompanyTruckDetails]);
  console.log(getCompanyServiceId)
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getCompanyServiceId?.status === 200) {
        if (getCompanyServiceId?.serviceRecord?._id !== null) {
          setData(getCompanyServiceId?.serviceRecord);
        }
      }
      if (getCompanyServiceIdError === 400) {
        toast.error(getCompanyServiceIdError?.message);
      } else if (getCompanyServiceIdError === 404) {
        toast.error(getCompanyServiceIdError.message);
      } else if (getCompanyServiceIdError === 500) {
        toast.error(getCompanyServiceIdError.error);
      }
    }
  }, [getCompanyServiceId, getCompanyServiceIdError]);

  useEffect(() => {
    if (loader) {
      if (postCompanyService?.status === 200) {
        toast.success(postCompanyService.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      } else if (postCompanyServiceError?.status === 404) {
        toast.error(postCompanyServiceError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postCompanyServiceError?.status === 400) {
        toast.error(postCompanyServiceError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postCompanyServiceError?.status === 500) {
        toast.error(postCompanyServiceError.error, {
          id: "addDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [postCompanyService, postCompanyServiceError]);
  useEffect(() => {
    if (loader) {
      if (patchCompanyService?.status === 200) {
        toast.success(patchCompanyService.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      } else if (patchCompanyServiceError?.status === 404) {
        toast.error(patchCompanyServiceError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchCompanyServiceError?.status === 400) {
        toast.error(patchCompanyServiceError.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      } else if (patchCompanyServiceError?.status === 500) {
        toast.error(patchCompanyServiceError.error, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [patchCompanyService, patchCompanyServiceError]);

  const initialValues = {
    truckId: truckNumber || data?.truckId || "",
    serviceDate: data?.serviceDate ? new Date(data?.serviceDate) : "",
    serviceBillNo: data?.serviceBillNo || null,
    serviceType: data?.serviceType || "Major",
    odoMeterReading: data?.odoMeterReading || null,
    serviceBillImage: data?.serviceBillImage || "",
    serviceCost: data?.serviceCost || null,
    description: data?.description || "",
    serviceAt: data?.serviceAt || "Company",
    serviceCentreName:data?.serviceCentreName || "",
    partsChange: data?.partsChange || [
      {
        partName: "",
        partCost: null,
        quantity: null,
        typeOfQuantity: "Piece/Box",
        totalCost: null,
        description: "",
      },
    ],
    extraCost: data?.extraCost || null,
    tax: data?.tax || null,
  };
  const validationSchema = Yup.object({
    truckId: Yup.string().required("Truck ID is required"),
    serviceDate: Yup.date().required("Service date is required"),
    serviceBillNo: Yup.string().required("Service bill number is required"),
    serviceType: Yup.string().required("Service type is required"),
    serviceAt: Yup.string().required("Service At is required"),
    serviceCentreName: Yup.string().required("Service Centre Name is required"),
    odoMeterReading: Yup.number().required("Odometer reading is required"),
    serviceCost: Yup.number().required("Service cost is required"),
    description: Yup.string(),
    partsChange: Yup.array().of(
      Yup.object({
        partName: Yup.string().required("Part name is required"),
        partCost: Yup.number().required("Part cost is required"),
        quantity: Yup.number().required("Quantity is required"),
        typeOfQuantity: Yup.string().required("Type of quantity is required"),
        totalCost: Yup.number().required("Total cost is required"),
        description: Yup.string(),
      })
    ),
  });

  const onSubmit = (values) => {
    dispatch(postCompanyServiceAction(values));
    setLoader(true);
    toast.loading("Adding service...", { id: "addDetails-toast" });
  };

  const onUpdate = (values) => {
    const payload = { id: data._id, body: values };
    dispatch(patchCompanyServiceAction(payload));
    setLoader(true);
    toast.loading("Updating service...", { id: "updateDetails-toast" });
  };
  return (
    <div>
      {(getCompanyServiceIdLoading || getCompanyTruckDetailsLoading) && <Backdrop/>}
      <h2 className="text-2xl font-bold mb-4">Add Service</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
              <div>
                <label htmlFor="truckId">
                  Truck ID<span>*</span>
                </label>
                <Field
                  as="select"
                  id="truckId"
                  name="truckId"
                  className=""
                  disabled={truckNumber}
                >
                  <option value=""></option>
                  {truckData.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.truckNumber}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="serviceDate">
                  Service Date<span>*</span>
                </label>

                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="serviceDate"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="serviceDate"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="serviceBillNo">
                  Service Bill No<span>*</span>
                </label>
                <Field
                  type="text"
                  placeHolder="Service Bill No"
                  id="serviceBillNo"
                  name="serviceBillNo"
                  className=""
                />
                <ErrorMessage
                  name="serviceBillNo"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="serviceBillImage">Service Bill Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="serviceBillImage"
                  values={values}
                />
                <ErrorMessage
                  name="serviceBillImage"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="serviceType">
                  Service Type<span>*</span>
                </label>
                <Field
                  as="select"
                  id="serviceType"
                  name="serviceType"
                  className=""
                >
                  <option value="Major">Major</option>
                  <option value="Minor">Minor</option>
                  <option value="Regular Check">Regular Check</option>
                </Field>
                <ErrorMessage
                  name="serviceType"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="serviceAt">
                  Service At<span>*</span>
                </label>
                <Field
                  as="select"
                  id="serviceAt"
                  name="serviceAt"
                  className=""
                >
                  <option value="Company">Company Service Center</option>
                  <option value="Out Side">Out Side Serice Center</option>
                </Field>
                <ErrorMessage
                  name="serviceAt"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="serviceCentreName">
                  Service Centre Name<span>*</span>
                </label>
                <Field
                  type="text"
                  id="serviceCentreName"
                  name="serviceCentreName"
                  className=""
                />
                <ErrorMessage
                  name="serviceCentreName"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="odoMeterReading">
                  Odometer Reading<span>*</span>
                </label>
                <Field
                  type="number"
                  id="odoMeterReading"
                  name="odoMeterReading"
                  className=""
                />
                <ErrorMessage
                  name="odoMeterReading"
                  component="div"
                  className="error"
                />
              </div>

              <div className="md:col-span-3 ">
                <div>Parts Details</div>
                <FieldArray name="partsChange">
                  {({ push, remove, form }) => (
                    <div>
                      {form.values.partsChange.map((_, index) => (
                        <div
                          key={index}
                          className="grid lg:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-4 mb-4 bg-blue-100 p-4 rounded-md"
                        >
                          <div>
                            <label htmlFor={`partsChange[${index}].partName`}>
                              Part Name<span>*</span>
                            </label>
                            <Field
                              type="text"
                              id={`partsChange[${index}].partName`}
                              name={`partsChange[${index}].partName`}
                              className=""
                            />
                            <ErrorMessage
                              name={`partsChange[${index}].partName`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div>
                            <label htmlFor={`partsChange[${index}].partCost`}>
                            Cost Per {_?.typeOfQuantity}<span>*</span>
                            </label>
                            <Field
                              type="number"
                              id={`partsChange[${index}].partCost`}
                              name={`partsChange[${index}].partCost`}
                              className=""
                            />
                            <ErrorMessage
                              name={`partsChange[${index}].partCost`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div>
                            <label htmlFor={`partsChange[${index}].quantity`}>
                              Quantity<span>*</span>
                            </label>
                            <Field
                              type="number"
                              id={`partsChange[${index}].quantity`}
                              name={`partsChange[${index}].quantity`}
                              className=""
                            />
                            <ErrorMessage
                              name={`partsChange[${index}].quantity`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div>
                            <label
                              htmlFor={`partsChange[${index}].typeOfQuantity`}
                            >
                              Type of Quantity<span>*</span>
                            </label>
                            <Field
                              as="select"
                              id={`partsChange[${index}].typeOfQuantity`}
                              name={`partsChange[${index}].typeOfQuantity`}
                              className=""
                            >
                              <option value="Piece/Box">Piece/Box</option>
                              <option value="Liter">Liter</option>
                              <option value="Other">Other</option>
                            </Field>
                            <ErrorMessage
                              name={`partsChange[${index}].typeOfQuantity`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div>
                            <label htmlFor={`partsChange[${index}].totalCost`}>
                              Total Cost<span>*</span>
                            </label>
                            <Field
                              type="number"
                              id={`partsChange[${index}].totalCost`}
                              name={`partsChange[${index}].totalCost`}
                              className=""
                            />
                            <ErrorMessage
                              name={`partsChange[${index}].totalCost`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div>
                            <label
                              htmlFor={`partsChange[${index}].description`}
                            >
                              Description
                            </label>
                            <Field
                              as="textarea"
                              id={`partsChange[${index}].description`}
                              name={`partsChange[${index}].description`}
                              className=""
                            />
                            <ErrorMessage
                              name={`partsChange[${index}].description`}
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="md:col-start-6 flex justify-end">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="primaryButton bg-red-500 hover:bg-red-800 w-fit"
                            >
                              Remove Part
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() =>
                          push({
                            partName: "",
                            partCost: null,
                            quantity: null,
                            typeOfQuantity: "Piece/Box",
                            totalCost: null,
                            description: "",
                          })
                        }
                        className="primaryButton bg-green-500 hover:bg-green-800 w-fit"
                      >
                        Add Part
                      </button>
                    </div>
                  )}
                </FieldArray>
              </div>
              <div>
                <label htmlFor="extraCost">
                  Extra Cost
                </label>
                <Field
                  type="number"
                  id="extraCost"
                  name="extraCost"
                  className=""
                />
                <ErrorMessage
                  name="extraCost"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="tax">
                Tax Amount
                </label>
                <Field
                  type="number"
                  id="tax"
                  name="tax"
                  className=""
                />
                <ErrorMessage
                  name="tax"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="serviceCost">
                  Total Service Cost<span>*</span>
                </label>
                <Field
                  type="number"
                  id="serviceCost"
                  name="serviceCost"
                  className=""
                />
                <ErrorMessage
                  name="serviceCost"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="description">
                  Description
                </label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  className=""
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <div className="flex">
                <button type="submit" className="primaryButton mt-auto" disabled={patchCompanyServiceLoading || postCompanyServiceLoading}>
                  {data?._id? "Update" : "Save"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddService;
