import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getCityAction, getStateAction } from "../../../redux/address/action";
import { getCompanyEmployeeDetailsIdAction, patchCompanyEmployeeFamilyDetailsAction, postCompanyEmployeeFamilyDetailsAction } from "../../../redux/company/employee/action";

function AddEmployeeFamilyDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {
    postCompanyEmployeeFamilyDetails,
    postCompanyEmployeeFamilyDetailsLoading,
    postCompanyEmployeeFamilyDetailsError,
    patchCompanyEmployeeFamilyDetails,
    patchCompanyEmployeeFamilyDetailsLoading,
    patchCompanyEmployeeFamilyDetailsError,
    getCompanyEmployeeDetailsId,
    getCompanyEmployeeDetailsIdLoading,
    getCompanyEmployeeDetailsIdError
  } = useSelector((state) => state.CompanyEmployeesection);
  let [searchParams, setSearchParams] = useSearchParams();
const [loader, setLoader] = useState(false)
const [data, setData] = useState()
useEffect(() => {
  const id = searchParams.get("userId")
  dispatch(getCompanyEmployeeDetailsIdAction(id))
}, [])
  useEffect(() => {
    dispatch(getStateAction());
  }, [dispatch]);


  const initialValues = {
    familyMembers: data || [
      {
        userId: searchParams.get("userId"),
        relation: "",
        name: "",
        contactNumber: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        zipCode: "",
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    familyMembers: Yup.array().of(
      Yup.object().shape({
        relation: Yup.string().required("This Field is Required"),
        name: Yup.string().required("This Field is Required"),
        contactNumber: Yup.string().required("This Field is Required"),
        address: Yup.string().required("This Field is Required"),
        city: Yup.string().required("This Field is Required"),
        state: Yup.string().required("This Field is Required"),
        country: Yup.string().required("This Field is Required"),
        zipCode: Yup.string().required("This Field is Required"),
      })
    ),
  });
  useEffect(() => {
    if (getCompanyEmployeeDetailsId.status === 200) {
      setData(getCompanyEmployeeDetailsId.data.family);
     
    } 
}, [getCompanyEmployeeDetailsId, getCompanyEmployeeDetailsIdError]);
  useEffect(() => {
    if(loader){

        if (postCompanyEmployeeFamilyDetails.status === 200) {
            toast.success(postCompanyEmployeeFamilyDetails.message, { id: "postDetails" });
            setLoader(false);
            navigate(`/company/employee`)
        } else if (postCompanyEmployeeFamilyDetailsError.status === 400) {
            toast.error(postCompanyEmployeeFamilyDetailsError.message, { id: "postDetails" });
            setLoader(false);
        } else if (postCompanyEmployeeFamilyDetailsError.status === 404) {
            toast.error(postCompanyEmployeeFamilyDetailsError.message, { id: "postDetails" });
            setLoader(false);
        } else if (postCompanyEmployeeFamilyDetailsError.status === 500) {
            toast.error(postCompanyEmployeeFamilyDetailsError.error, { id: "postDetails" });
            setLoader(false);
        }
    }
  }, [postCompanyEmployeeFamilyDetails, postCompanyEmployeeFamilyDetailsError])
  useEffect(() => {
    if(loader){

        if (patchCompanyEmployeeFamilyDetails.status === 200) {
            toast.success(patchCompanyEmployeeFamilyDetails.message, { id: "postDetails" });
            setLoader(false);
            navigate(`/company/employee`)
        } else if (patchCompanyEmployeeFamilyDetailsError.status === 400) {
            toast.error(patchCompanyEmployeeFamilyDetailsError.message, { id: "postDetails" });
            setLoader(false);
        } else if (patchCompanyEmployeeFamilyDetailsError.status === 404) {
            toast.error(patchCompanyEmployeeFamilyDetailsError.message, { id: "postDetails" });
            setLoader(false);
        } else if (patchCompanyEmployeeFamilyDetailsError.status === 500) {
            toast.error(patchCompanyEmployeeFamilyDetailsError.error, { id: "postDetails" });
            setLoader(false);
        }
    }
  }, [patchCompanyEmployeeFamilyDetails, patchCompanyEmployeeFamilyDetailsError])

  const handleSubmit = (values) => {
    dispatch(postCompanyEmployeeFamilyDetailsAction(values))
    setLoader(true)
    toast.loading("Family details submitting.....", {id: "postDetails"});
  };
  const handleUpdate = (values) => {
    console.log(values)
    dispatch(patchCompanyEmployeeFamilyDetailsAction(values))
    setLoader(true)
    toast.loading("Family details Updating....", {id: "postDetails"});
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add Family Details</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data ? handleUpdate : handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FieldArray name="familyMembers">
              {({ insert, remove, push }) => (
                <div>
                  {values.familyMembers.length > 0 &&
                    values.familyMembers.map((member, index) => (
                      <>
                      <div
                        className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-4"
                        key={index}
                      >
                        <div>
                          <label htmlFor={`familyMembers.${index}.relation`}>
                            Relation <span>*</span>
                          </label>
                          <Field
                              as="select"
                              name={`familyMembers.${index}.relation`}
                              className="w-full px-4 py-2 border rounded-lg"
                            >
                              <option value="">Select Relation</option>
                              <option value="brother">Brother</option>
                              <option value="sister">Sister</option>
                              <option value="mother">Mother</option>
                              <option value="father">Father</option>
                              <option value="wife">Wife</option>
                              <option value="son">Son</option>
                              <option value="daughter">Daughter</option>
                            </Field>
                          <ErrorMessage
                            name={`familyMembers.${index}.relation`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div>
                          <label htmlFor={`familyMembers.${index}.name`}>
                            Name <span>*</span>
                          </label>
                          <Field
                            name={`familyMembers.${index}.name`}
                            placeholder="Name"
                            className="w-full px-4 py-2 border rounded-lg"
                          />
                          <ErrorMessage
                            name={`familyMembers.${index}.name`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor={`familyMembers.${index}.contactNumber`}
                          >
                            Contact Number <span>*</span>
                          </label>
                          <Field
                            name={`familyMembers.${index}.contactNumber`}
                            placeholder="Contact Number"
                            className="w-full px-4 py-2 border rounded-lg"
                          />
                          <ErrorMessage
                            name={`familyMembers.${index}.contactNumber`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div>
                          <label htmlFor={`familyMembers.${index}.address`}>
                            Address <span>*</span>
                          </label>
                          <Field
                            name={`familyMembers.${index}.address`}
                            placeholder="Address"
                            className="w-full px-4 py-2 border rounded-lg"
                          />
                          <ErrorMessage
                            name={`familyMembers.${index}.address`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <StateCity
                          index={index}
                          setFieldValue={setFieldValue}
                          values={values}
                          dispatch={dispatch}
                        />
                        <div>
                          <label htmlFor={`familyMembers.${index}.country`}>
                            Country <span>*</span>
                          </label>
                          <Field
                            name={`familyMembers.${index}.country`}
                            placeholder="Country"
                            className="w-full px-4 py-2 border rounded-lg"
                            readOnly
                          />
                          <ErrorMessage
                            name={`familyMembers.${index}.country`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div>
                          <label htmlFor={`familyMembers.${index}.zipCode`}>
                            Zip Code <span>*</span>
                          </label>
                          <Field
                            name={`familyMembers.${index}.zipCode`}
                            placeholder="Zip Code"
                            className="w-full px-4 py-2 border rounded-lg"
                          />
                          <ErrorMessage
                            name={`familyMembers.${index}.zipCode`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className=" mt-auto ">
                          <button
                            type="button"
                            className="primaryButton bg-red-500 hover:bg-red-800"
                            onClick={() => remove(index)}
                          >
                            Remove Family Member
                          </button>
                        </div>
                      </div>
                       {index+1 !== values.familyMembers.length && <hr className="my-8 border-dashed border-gray-400 " />}
                      </>

                    ))}
                  <button
                    type="button"
                    className="primaryButton w-fit bg-green-500 hover:bg-green-800"
                    onClick={() =>
                      push({
                        userId: searchParams.get("userId"),
                        relation: "",
                        name: "",
                        contactNumber: "",
                        address: "",
                        city: "",
                        state: "",
                        country: "India",
                        zipCode: "",
                      })
                    }
                  >
                    Add Family Member
                  </button>
                </div>
              )}
            </FieldArray>
            <button type="submit" className="primaryButton mt-10 md:w-1/3 px-5">
             {data ? "Update" :  "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddEmployeeFamilyDetails;

const StateCity = ({ index, setFieldValue, values, dispatch }) => {
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );

  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  useEffect(() => {
    if (getState?.status === 200) {
      setState(getState.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getState]);
  useEffect(() => {
    if (loader) {
      if (getCity?.status === 200) {
        setCity(getCity?.data.sort((a, b) => a.name.localeCompare(b.name)));
        setLoader(false)
      }
    }
  }, [getCity]);
  const handleCity = (id) => {
    setLoader(true);
    dispatch(getCityAction(id));
  };
  return (
    <>
      <div>
        <label htmlFor={`familyMembers.${index}.state`}>
          State <span>*</span>
        </label>
        <Field
          as="select"
          name={`familyMembers.${index}.state`}
          className="w-full px-4 py-2 border rounded-lg"
          onChange={(e) => {
            setFieldValue(`familyMembers.${index}.state`, e.target.value);
            handleCity(e.target.value);
          }}
        >
          <option value="">State</option>
          {state?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={`familyMembers.${index}.state`}
          component="div"
          className="error"
        />
      </div>
      <div>
        <label htmlFor={`familyMembers.${index}.city`}>
          City <span>*</span>
        </label>
        <Field
          as="select"
          name={`familyMembers.${index}.city`}
          className="w-full px-4 py-2 border rounded-lg"
        >
          <option value="">City</option>
          {city?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={`familyMembers.${index}.city`}
          component="div"
          className="error"
        />
      </div>
    </>
  );
};
