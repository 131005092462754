import { Badge } from "../../../ui/badge";
import moment from "moment";
import React from "react";

function PersonalDetails({ data, state, city }) {
  console.log(data)
  const sortedPreviousSalaries = data?.previousSalaries.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  const sortedPreviousDesignation = data?.previousDesignation.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
  );
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4 text-2xl font-extrabold">Details</div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Name</label>
        <p className="">
          {data?.employeeId?.firstName} {data?.employeeId?.lastName}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Gender</label>
        <p className="">{data?.employeeId?.gender}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Date of Birth</label>
        <p className="">
          {moment(data?.employeeId?.dateOfBirth).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Phone Number</label>
        <p>{data?.employeeId?.phoneNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Blood Group</label>
        <p className="">{data?.employeeId?.bloodGroup}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Address</label>
        <p>{data?.employeeId?.address}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>City</label>
        <p className="">{data?.employeeId?.city}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>State</label>
        <p className="">{data?.employeeId?.state}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label>Pin Code</label>
        <p className="">{data?.employeeId?.zipCode}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4">
        <label className="">Blocked by Company</label>
        <p className="">
          {data?.blockedByCompany ? (
            <Badge variant="destructive">Blocked</Badge>
          ) : (
            <Badge className="bg-green-700 hover:bg-green-900">Active</Badge>
          )}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Designation</label>
        <p className="">{data?.designation.toUpperCase()}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Joining Date</label>
        <p className="">
          {moment(data?.joiningDate).format("DD/MMM/YYYY")}
        </p>
      </div>
      {data?.leavingDate && (
        <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
          <label htmlFor="">Leaving Date</label>
          <p className="">
            {moment(data?.leavingDate).format("DD/MMM/YYYY")}
          </p>
        </div>
      )}
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Working Type</label>
        <p className="">{data?.employeeType}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Salary</label>
        <p className="">{data?.salary}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Current Working Status</label>
        <p className="">
          {data?.isWorking ? "Working" : "Leave"}
        </p>
      </div>
      <div className="col-span-4 text-xl font-bold">
        Salary Increment Details
      </div>
      <div className="col-span-4 grid grid-cols-2">
        {sortedPreviousSalaries?.map((item) => (
          <>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary</label>
              <p className="">{item?.salary}</p>
            </div>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary Update Date</label>
              <p className="">
                {moment(item?.updatedAt).format("DD/MMM/YYYY")}
              </p>
            </div>
          </>
        ))}
      </div>
      <div className="col-span-4 text-xl font-bold">
        Designation Details
      </div>
      <div className="col-span-4 grid grid-cols-2">
        {sortedPreviousDesignation?.map((item) => (
          <>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary</label>
              <p className="">{item?.designation}</p>
            </div>
            <div className="lg:col-span-1 col-span-2  ">
              <label htmlFor="">Salary Update Date</label>
              <p className="">
                {moment(item?.updatedAt).format("DD/MMM/YYYY")}
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default PersonalDetails;
