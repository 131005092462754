import { Badge } from "../../../ui/badge";
import moment from "moment";
import React from "react";

function TripTabDetails({data}) {
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full uppercase">
      <div className="col-span-4">
        <label htmlFor="" className="font-bold ">
          Token Number
        </label>
        <p className="font-bold">{data?.tokenNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Start Date</label>
        <p className="">{moment(data?.startDate).format("DD/MMM/YYYY")}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip End Date</label>
        <p className="">{moment(data?.endDate).format("DD/MMM/YYYY")}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Start Location</label>
        <p className="">{data?.startLocation}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip End Location</label>
        <p className="">{data?.endLocation}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Total KM Run</label>
        <p className="">{data?.distanceTravelled} KM</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Start Load</label>
        <p className="">{data?.startingLoad? data?.startingLoad + " " + data?.weigthType : "-"}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip End Load</label>
        <p className="">{data?.endingLoad? data?.endingLoad + " " + data?.weigthType: "-"}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Rate Per {data?.weigthType}</label>
        <p className="">Rs.{data?.ratePerWeight} per {data?.weigthType}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Number</label>
        <p className="">{data?.truckNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Driver Name</label>
        <p className="">{data?.driverName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Helper Name</label>
        <p className="">{data?.helperName ? data?.helperName : "-"}</p>
      </div>
     
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Product</label>
        <p className="">{data?.productType}</p>
      </div>

      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Trip Status</label>
        <div>
          {data?.status === "Planned" && (
            <Badge variant="destructive">{data?.status}</Badge>
          )}
          {data?.status === "Trip Started" && (
            <Badge
              variant="secondary"
              className="bg-yellow-500 hover:bg-yellow-800 text-white"
            >
              {data?.status}
            </Badge>
          )}
          {data?.status === "Trip Ended" && (
            <Badge
              variant=""
              className="bg-blue-500 hover:bg-blue-800 text-white"
            >
              {data?.status}
            </Badge>
          )}
          {data?.status === "Completed" && (
            <Badge
              variant=""
              className="bg-green-700 hover:bg-green-900 text-white"
            >
              {data?.status}
            </Badge>
          )}
        </div>
      </div>
      <div className=" col-span-4 ">
        <div className="font-medium text-sm">Payment Details</div>
        <div className="grid md:grid-cols-4">
          <div>
            <label htmlFor="">Total Amount</label>
            <p>{data?.totalPayment}</p>
          </div>
          <div>
            <label htmlFor="">Payment Date</label>
            <p>{data.paymentDate? moment(data.paymentDate).format("DD/MMM/YYYY"): "-"}</p>
          </div>
          {data.paymentType !== "Cash" && (
            <div>
              <label htmlFor="">Payment Account</label>
              <p>{data?.paymentAccount ? data?.paymentAccount : "-"}</p>
            </div>
          )}
          <div>
            <label htmlFor="">Payment Type</label>
            <p>{data?.paymentType ? data?.paymentType : "-" }</p>
          </div>
          {data.paymentType !== "Cash" && data.paymentType !== "Check" && (
            <div>
              <label htmlFor="">Transition Number</label>
              <p>{data?.transitionNumber ? data?.transitionNumber : "-" }</p>
            </div>
          )}
          {data.paymentType === "Check" && (
            <>
            <div>
              <label htmlFor="">End Date</label>
              <p>{data?.checkNumber}</p>
            </div>
            <div>
              <label htmlFor="">End Date</label>
              <img
              src={data?.checkImage === "" ? "" : data?.checkImage}
              alt=""
              className={` h-full w-full  max-h-[100px] max-w-[150px]`}
            />

            </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TripTabDetails;
