import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DatePicker from "../datepicker/datePicker";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetailsAction, patchCompanyDetailsAction, postCompanyDetailsAction } from "../../redux/company/action";
import toast from "react-hot-toast";
import { getCityAction, getStateAction } from "../../redux/address/action";
import FileUpload from "../fileupload/fileUpload";
import Backdrop from "../backdrop";

function AddDetails() {
  const dispacth = useDispatch();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const { postCompanyDetails, postCompanyDetailsLoading, postCompanyDetailsError, getCompanyDetails, getCompanyDetailsLoading, getCompanyDetailsError, patchCompanyDetails, patchCompanyDetailsLoading, patchCompanyDetailsError  } = useSelector(
    (state) => state.Companysection
  );
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );
  useEffect(() => {
    dispacth(getStateAction());
    dispacth(getCompanyDetailsAction())
  }, [dispacth]);
  useEffect(() => {
    if (getState?.status === 200) {
      setState(getState.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getState]);
  useEffect(() => {
    if (getCity?.status === 200) {
      setCity(getCity?.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getCity]);
  useEffect(() => {
    if (loader) {
      handler();
    }
  }, [postCompanyDetails, postCompanyDetailsError]);
  useEffect(() => {
    if (loader) {
      updateHandler();
    }
  }, [patchCompanyDetails, patchCompanyDetailsError]);
  useEffect(() => {
    if (getCompanyDetails?.status === 200) {
      setData(getCompanyDetails?.data?.company)
      handleCity(getCompanyDetails?.data?.company?.state)
    }
  }, [getCompanyDetails]);
  

  const [data, setData] = useState()

  const handleCity = (id) => {
    dispacth(getCityAction(id));
  };
  const handler = async () => {
    if (postCompanyDetails.status === 200) {
      toast.success(postCompanyDetails.message, { id: "addDetails-toast" });
      setLoader(false);
    } else if (postCompanyDetailsError.status === 400) {
      toast.error(postCompanyDetailsError.message, { id: "addDetails-toast" });
      setLoader(false);
    } else if (postCompanyDetailsError.status === 500) {
      toast.error(postCompanyDetailsError.error, { id: "addDetails-toast" });
      setLoader(false);
    }
  };
  const updateHandler = async () => {
    if (patchCompanyDetails.status === 200) {
      toast.success(patchCompanyDetails.message, { id: "addDetails-toast" });
      dispacth(getCompanyDetailsAction())
      setLoader(false);
    } else if (patchCompanyDetailsError.status === 400) {
      toast.error(patchCompanyDetailsError.message, { id: "addDetails-toast" });
      setLoader(false);
    } else if (patchCompanyDetailsError.status === 500) {
      toast.error(patchCompanyDetailsError.error, { id: "addDetails-toast" });
      setLoader(false);
    }
  };
  const initialValues = {
    companyName: data?.companyName || "",
    companyOwnerBy: data?.companyOwnerBy || "",
    companyStartDate: data?.companyStartDate ? new Date(data?.companyStartDate) : "",
    address: data?.address || "",
    city: data?.city || "",
    state: data?.state || "",
    country: data?.country || "India",
    gstNumber: data?.gstNumber || "",
    phoneNumber: data?.phoneNumber || null,
    alternateMobileNumber: data?.alternateMobileNumber || null,
    accountNumber: data?.accountNumber || "",  
    companyLogo: data?.companyLogo || ""
  };
  const validationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    companyOwnerBy: Yup.string().required("Owner is required"),
    companyStartDate: Yup.string(),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    gstNumber: Yup.string(),
    phoneNumber: Yup.string().required("Phone Number is required"),
    alternateMobileNumber: Yup.string().nullable(),
  });

  

  const onSubmit = (values) => {
    dispacth(postCompanyDetailsAction(values));
    setLoader(true);
    toast.loading("Saving Details...", { id: "addDetails-toast" });
  };
  const onUpdate = (values) => {
    const payload = {
      id: data._id,
      body: values
    }
    dispacth(patchCompanyDetailsAction(payload));
    setLoader(true);
    toast.loading("Update Details...", { id: "addDetails-toast" });
  };
  return (
    <div className="">
      {(getStateLoading || getCityLoading || getCompanyDetailsLoading) &&<Backdrop/>}
      <h2 className="text-2xl font-bold mb-4">Add Company Details</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="email">
                  Company Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="companyName"
                  name="companyName"
                  autoFocus
                  className=""
                  placeholder="Company Name"
                />
                <ErrorMessage
                  name="companyName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="email">
                  Company Owner Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="companyOwnerBy"
                  name="companyOwnerBy"
                  className="w-full"
                  placeholder="Company Owner By"
                />
                <ErrorMessage
                  name="companyOwnerBy"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="email">Company Start Date</label>
                <div className="w-full">
                  <DatePicker
                    id="companyStartDate"
                    maxDate={new Date()}
                    name="companyStartDate"
                    errorName={errors.companyStartDate}
                    className=""
                    placeHolder="Company Start Date"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="address">
                  Address <span>*</span>
                </label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className=""
                  placeholder="Address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="country">
                  Country <span>*</span>
                </label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  className=""
                  placeholder="Country"
                  readOnly
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="state">
                  State <span>*</span>
                </label>
                <Field
                  as="select"
                  id="state"
                  name="state"
                  className=""
                  onChange={(e) => {
                    setFieldValue("state", e.target.value);
                    handleCity(e.target.value);
                  }}
                  placeholder="State"
                >
                  <option value="">State</option>
                  {state?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="state"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="city">
                  City <span>*</span>
                </label>
                <Field
                  as="select"
                  id="city"
                  name="city"
                  className=""
                  placeholder="City"
                >
                  <option value="">City</option>
                  {city?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="gstNumber">GST Number</label>
                <Field
                  type="text"
                  id="gstNumber"
                  name="gstNumber"
                  className=""
                  placeholder="GST Number"
                />
              </div>
              <div>
                <label htmlFor="phoneNumber">Phone Number</label>
                <Field
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  className=""
                  placeholder="Phone Number"
                />
              </div>
              <div>
                <label htmlFor="alternateMobileNumber">
                  Alternate Mobile Number
                </label>
                <Field
                  type="text"
                  id="alternateMobileNumber"
                  name="alternateMobileNumber"
                  className=""
                  placeholder="Alternate Mobile Number"
                />
                <ErrorMessage
                  name="alternateMobileNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div className="">
                <label htmlFor="alternateMobileNumber">Account Number</label>
                <Field
                  type="number"
                  id="accountNumber"
                  name="accountNumber"
                  className=""
                  placeholder="Account Number"
                />
              </div>
              <div>
                <label htmlFor="companyLogo">Company Logo</label>
               <FileUpload setFieldValue={setFieldValue} name="companyLogo" values={values} />
              </div>

              <button type="submit" className="primaryButton h-fit mt-auto" disabled={postCompanyDetailsLoading || patchCompanyDetailsLoading}>
              {data?._id  ?  "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddDetails;
