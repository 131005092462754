import React, { useState } from "react";
import Image from "../../../../assets/images/spare-parts.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../../ui/dialog";
import DeleteService from "./deleteService";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";

function ServiceListCard({ data }) {
    const navigate = useNavigate()
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div
      key={data._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid grid-cols-12 gap-x-7">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-4">
          <button type="button" className="rounded-full bg-gray-400" onClick={()=>setShowImageModal(true)}>
            <img
              src={
                data?.serviceBillImage === "" ? Image : data?.serviceBillImage
              }
              alt=""
              className={`min-h-[100px] max-h-[200px] min-w-[100px] max-w-[200px] ${
                data?.serviceBillImage === "" ? "" : "rounded-full"
              }`}
            />
          </button>
        </div>
        {data?.serviceBillImage &&
        <Dialog open={showImageModal}>
            <ImagePopUpDownload image={data?.serviceBillImage} setShowModal={setShowImageModal} imagename={`fuel_${data?._id}`} />
          </Dialog>
        }
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button type="button" className="font-bold text-blue-600 text-xl">
            {data?.serviceCentreName} ({data?.serviceAt}), {data?.serviceBillNo}
          </button>
          <p className="textgrey text-base uppercase">
            {moment(data.serviceDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Service Type</h3>
            <p>{data?.serviceType}</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Truck ODO Meter Reading</h3>
            <p>{data?.odoMeterReading} KM</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Extra Cost</h3>
            <p>{data?.extraCost ? `Rs.${data.extraCost}` : "-"}</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Tax</h3>
            <p>{data?.tax ? `Rs.${data.tax}` : "-"}</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Service Total Cost</h3>
            <p>Rs.{data?.serviceCost}</p>
          </div>

          <div className="md:col-span-3">
            <h3 className="textgrey text-xs">Description</h3>
            <p>{data?.description}</p>
          </div>
          
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-x-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center mt-5 bg-blue-100 p-4 rounded-md">
          <h3 className="col-span-4 font-bold">Parts Changed</h3>
          <div className="textgrey text-xs">Part Name</div>
          <div className="textgrey text-xs">Cost X Quantity</div>
          <div className="textgrey text-xs">Total Cost</div>
          <div className="textgrey text-xs">Description</div>

          {data?.partsChange.map((item) => (
            <>
              <p className="">{item.partName}</p>

              <p>
                Rs.{item.partCost} X {item.quantity} {item.typeOfQuantity}
              </p>
              <p> Rs.{item.totalCost}</p>
              <p>{item.description || "-"}</p>
            </>
          ))}
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-x-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center mt-5">
        <div className="my-auto ">
            <button
              type="button"
              className="secondaryButton h-full"
              onClick={() => navigate(`/company/service/addService?id=${data?._id}`)}
            >
              Edit Service Details
            </button>
          </div>
          <div className="my-auto col-span-1">
                <Dialog open={showDeleteModal}>
                  <DialogTrigger asChild>
                    <button
                      type="button"
                      onClick={() => setShowDeleteModal(true)}
                      className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
                    >
                      Delete
                    </button>
                  </DialogTrigger>
                  <DeleteService
                    setShowModal={setShowDeleteModal}
                    id={data?._id}
                  />
                </Dialog>
              </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceListCard;
