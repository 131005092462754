import React, { useEffect, useState } from "react";
import Backdrop from "../../backdrop";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "../../datepicker/datePicker";
import FileUpload from "../../fileupload/fileUpload";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../ui/button";
import {
  getCompanyTruckDetailsIdAction,
  getTruckPurchaseAction,
  patchCompanyTruckDetailsAction,
  postCompanyTruckDetailsAction,
  postTruckPurchaseAction,
} from "../../../redux/company/truck/action";
import { Switch } from "../../../ui/switch";
import OtpModalTruck from "./otpModalTruck";
import { Dialog } from "../../../ui/dialog";
import FileMultiUpload from "../../fileupload/fileMuiltipleUpload";

function AddTruck() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [purchaseLoader, setPurchaseLoader] = useState(false);
  const [purchaseTruckList, setPurchaseTruckList] = useState([]);
  const [purchaseData, setPurchaseData] = useState();
  const [purchaseOtpData, setPurchaseOtpData] = useState();
  const [showModal, setShowModal] = useState(false);
  const {
    postCompanyTruckDetails,
    postCompanyTruckDetailsLoading,
    postCompanyTruckDetailsError,
    getTruckPurchase,
    getTruckPurchaseLoading,
    getCompanyTruckDetailsId,
    getCompanyTruckDetailsIdLoading,
    getCompanyTruckDetailsIdError,
    patchCompanyTruckDetails,
    patchCompanyTruckDetailsError,
    patchCompanyTruckDetailsLoading,
    postTruckPurchase,
    postTruckPurchaseLoading,
    postTruckPurchaseError,
  } = useSelector((state) => state.Companytrucksection);
  useEffect(() => {
    const edit = searchParams.get("id") === null;
    const id = searchParams.get("id");
    if (!edit) {
      dispatch(getCompanyTruckDetailsIdAction(id));
    } else {
      setData();
      dispatch(getTruckPurchaseAction());
    }
  }, [searchParams]);

  useEffect(() => {
    if (getTruckPurchase?.status === 200) {
      setPurchaseTruckList(getTruckPurchase.data);
    }
  }, [getTruckPurchase]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;
    if (!edit) {
      if (getCompanyTruckDetailsId?.status === 200) {
        setData(getCompanyTruckDetailsId.truck);
        setReadOnly(true);
      }
    }
  }, [getCompanyTruckDetailsId]);
  useEffect(() => {
    if (getCompanyTruckDetailsIdError?.status === 404) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "addDetails-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 400) {
      toast.error(getCompanyTruckDetailsIdError.message, {
        id: "addDetails-toast",
      });
    } else if (getCompanyTruckDetailsIdError?.status === 500) {
      toast.error(getCompanyTruckDetailsIdError.error, {
        id: "addDetails-toast",
      });
    }
  }, [getCompanyTruckDetailsIdError]);
  useEffect(() => {
    if (loader) {
      if (postCompanyTruckDetails?.status === 200) {
        toast.success(postCompanyTruckDetails.message, {
          id: "addDetails-toast",
        });
        navigate("/company/truck");
        setLoader(false);
      }
    }
  }, [postCompanyTruckDetails]);
  useEffect(() => {
    if (loader) {
      if (postCompanyTruckDetails?.status === 210) {
        toast.success(postCompanyTruckDetails.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
        setPurchaseOtpData(postCompanyTruckDetails?.data);
        setShowModal(true);
      } else if (postCompanyTruckDetailsError?.status === 404) {
        toast.error(postCompanyTruckDetailsError.message, {
          id: "addDetails-toast",
        });
      } else if (postCompanyTruckDetailsError?.status === 400) {
        toast.error(postCompanyTruckDetailsError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postCompanyTruckDetailsError?.status === 500) {
        toast.error(postCompanyTruckDetailsError.error, {
          id: "addDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [postCompanyTruckDetailsError]);
  useEffect(() => {
    if (updateLoader) {
      if (patchCompanyTruckDetails?.status === 200) {
        toast.success(postCompanyTruckDetails.message, {
          id: "updateDetails-toast",
        });
        navigate("/company/truck");
        setUpdateLoader(false);
      }
    }
  }, [patchCompanyTruckDetails]);
  useEffect(() => {
    if (updateLoader) {
      if (patchCompanyTruckDetailsError?.status === 404) {
        toast.error(patchCompanyTruckDetailsError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchCompanyTruckDetailsError?.status === 400) {
        toast.error(patchCompanyTruckDetailsError.message, {
          id: "updateDetails-toast",
        });
        setUpdateLoader(false);
      } else if (patchCompanyTruckDetailsError?.status === 500) {
        toast.error(patchCompanyTruckDetailsError.error, {
          id: "updateDetails-toast",
        });
        setUpdateLoader(false);
      }
    }
  }, [patchCompanyTruckDetailsError]);
  useEffect(() => {
    if (purchaseLoader) {
      if (postTruckPurchase?.status === 200) {
        toast.success(postTruckPurchase.message, {
          id: "addpurchaseDetails-toast",
        });
        setShowModal(false);
        navigate("/company/truck");
        setPurchaseLoader(false);
      }
    }
  }, [postTruckPurchase]);
  useEffect(() => {
    if (purchaseLoader) {
      if (postTruckPurchaseError?.status === 404) {
        toast.error(postTruckPurchaseError.message, {
          id: "addpurchaseDetails-toast",
        });
        setPurchaseLoader(false);
      } else if (postTruckPurchaseError?.status === 400) {
        toast.error(postTruckPurchaseError.message, {
          id: "addpurchaseDetails-toast",
        });
        setPurchaseLoader(false);
      } else if (postTruckPurchaseError?.status === 500) {
        toast.error(postTruckPurchaseError.error, {
          id: "addpurchaseDetails-toast",
        });
        setPurchaseLoader(false);
      }
    }
  }, [postTruckPurchaseError]);

  const initialValues = {
    truckNumber: data?.truckId?.truckNumber || "",
    truckCompanyName: data?.truckId?.truckCompanyName || "",
    truckModelName: data?.truckId?.truckModelName || "",
    truckImage: data?.truckId?.truckImage || "",
    purchaseDate: data?.truckId?.purchaseDate
      ? new Date(data?.truckId?.purchaseDate)
      : "",
      fitnessNumber: data?.truckId?.fitnessId?.fitnessNumber || "",
    isInsurance: data?.truckId?.isInsurance || null,
    insuranceNumber: data?.truckId?.insuranceId?.insuranceNumber || "",
    insuranceCost: data?.truckId?.insuranceId?.insuranceCost || null,
    insuranceCompanyName: data?.truckId?.insuranceId?.insuranceCompanyName || "",
    insuranceImage: data?.truckId?.insuranceId?.insuranceImage || [],
    insuranceTill: data?.truckId?.insuranceTill
      ? new Date(data?.truckId?.insuranceTill)
      : "",
    isFitness: data?.truckId?.isFitness || null,
    fitnessCost: data?.truckId?.fitnessId?.fitnessCost || null,
    fitnessTill: data?.truckId?.fitnessTill
      ? new Date(data?.truckId?.fitnessTill)
      : "",
    fitnessImage: data?.truckId?.fitnessId?.fitnessImage || [],
    typeOfTruck: data?.truckId?.typeOfTruck || "",
    chasisNumber: data?.truckId?.chasisNumber || "",
    isEmi: data?.truckId?.isEmi || null,
    emiStartDate: data?.truckId?.emiStartDate
      ? new Date(data?.truckId?.emiStartDate)
      : "",
    emiEndDate: data?.truckId?.emiEndDate
      ? new Date(data?.truckId?.emiEndDate)
      : "",
    totalCost: data?.truckId?.totalCost || null,
    emiMonths: data?.truckId?.emiMonths || null,
    emiCompanyName: data?.truckId?.emiCompanyName || "",
    emiLeft: data?.truckId?.emiLeft || "",
    truckOwnByName: data?.truckOwnByName || "",
    usedfor: data?.usedfor || "",
    isPermit: data?.truckId?.isPermit || null,
    permitTill: data?.truckId?.permitTill
      ? new Date(data?.truckId?.permitTill)
      : "",
    permitCost: data?.truckId?.permitId?.permitCost || null,
    permitNumber: data?.truckId?.permitId?.permitNumber || "",
    permitImage: data?.truckId?.permitId?.permitImage || [],
  };
  const validationSchema = Yup.object({
    truckNumber: Yup.string().required("Truck Number is Required"),
    truckCompanyName: Yup.string().required("Truck Company Name is Required"),
    truckModelName: Yup.string().required("Truck Model Name is Required"),
    purchaseDate: Yup.date().nullable(), // Optional date field
    truckOwnByName: Yup.string().required("Truck Owned By is Required"),
    typeOfTruck: Yup.string().required("Type of Truck is Required"),
    usedfor: Yup.string().required("Purpose is Required"),
    chasisNumber: Yup.string().required("Chassis Number is Required"),

    // Conditional validation for EMI fields
    totalCost: Yup.number()
      .nullable()
      .when("isEmi", {
        is: true,
        then: () => Yup.number().required("Total EMI Amount is Required"),
        otherwise: () => Yup.number().nullable(),
      }),
    emiLeft: Yup.number()
      .nullable()
      .when("isEmi", {
        is: true,
        then: () => Yup.number().required("EMI Pending Amount is Required"),
        otherwise: () => Yup.number().nullable(),
      }),
    emiCompanyName: Yup.string()
      .nullable()
      .when("isEmi", {
        is: true,
        then: () => Yup.string().required("EMI Company Name is Required"),
        otherwise: () => Yup.string().nullable(),
      }),
    emiStartDate: Yup.date()
      .nullable()
      .when("isEmi", {
        is: true,
        then: () => Yup.date().required("EMI Start Date is Required"),
        otherwise: () => Yup.date().nullable(),
      }),
    emiEndDate: Yup.date()
      .nullable()
      .when("isEmi", {
        is: true,
        then: () => Yup.date().required("EMI End Date is Required"),
        otherwise: () => Yup.date().nullable(),
      }),

    // Conditional validation for insurance fields
    insuranceNumber: Yup.string()
      .nullable()
      .when("isInsurance", {
        is: true,
        then: () => Yup.string().required("Insurance Number is Required"),
        otherwise: () => Yup.string().nullable(),
      }),
    insuranceCost: Yup.number()
      .nullable()
      .when("isInsurance", {
        is: true,
        then: () => Yup.number().required("Insurance Cost is Required"),
        otherwise: () => Yup.number().nullable(),
      }),
    insuranceTill: Yup.date()
      .nullable()
      .when("isInsurance", {
        is: true,
        then: () => Yup.date().required("Insurance Expiry Date is Required"),
        otherwise: () => Yup.date().nullable(),
      }),

    // Conditional validation for fitness fields
    fitnessCost: Yup.number()
      .nullable()
      .when("isFitness", {
        is: true,
        then: () => Yup.number().required("Fitness Cost is Required"),
        otherwise: () => Yup.number().nullable(),
      }),
    fitnessTill: Yup.date()
      .nullable()
      .when("isFitness", {
        is: true,
        then: () => Yup.date().required("Fitness Expiry Date is Required"),
        otherwise: () => Yup.date().nullable(),
      }),

    // Conditional validation for permit fields
    permitNumber: Yup.string()
      .nullable()
      .when("isPermit", {
        is: true,
        then: () => Yup.string().required("Permit Number is Required"),
        otherwise: () => Yup.string().nullable(),
      }),
    permitCost: Yup.number()
      .nullable()
      .when("isPermit", {
        is: true,
        then: () => Yup.number().required("Permit Cost is Required"),
        otherwise: () => Yup.number().nullable(),
      }),
    permitTill: Yup.date()
      .nullable()
      .when("isPermit", {
        is: true,
        then: () => Yup.date().required("Permit Expiry Date is Required"),
        otherwise: () => Yup.date().nullable(),
      }),

    // Validation for optional images
    insuranceImage: Yup.array().nullable(),
    fitnessImage: Yup.array().nullable(),
    permitImage: Yup.array().nullable(),
  });

  const calculateEmiMonths = (emiStartDate, emiEndDate) => {
    const start = new Date(emiStartDate);
    const end = new Date(emiEndDate);
    const months =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
    return months;
  };
  const handleEdit = () => {
    searchParams.delete("readOnly");
    setSearchParams(searchParams);
  };
  const onSubmit = async (values) => {
    if (values.isEmi === null) {
      values.emiMonths = calculateEmiMonths(
        values.emiStartDate,
        values.emiEndDate
      );
    }
    dispatch(postCompanyTruckDetailsAction(values));
    setLoader(true);
    toast.loading("Saving Details...", { id: "addDetails-toast" });
  };
  const onUpdate = async (values) => {
    const payload = {
      id: searchParams.get("id"),
      body: values,
    };
    dispatch(patchCompanyTruckDetailsAction(payload));
    setUpdateLoader(true);
    toast.loading("Updating Details...", { id: "updateDetails-toast" });
  };
  const onPurchase = async (values) => {
    const payload = {
      id: data?.truckId._id,
      body: values,
    };
    dispatch(postTruckPurchaseAction(payload));
    setPurchaseLoader(true);
    toast.loading("Saving Details...", { id: "addpurchaseDetails-toast" });
  };

  const handlePurchase = (truckNumber, setFieldValue) => {
    const selectedTruck = purchaseTruckList.find(
      (truck) => truck.truckNumber === truckNumber
    );
    if (selectedTruck) {
      setPurchaseData(selectedTruck);
      delete selectedTruck.purchaseDate;
      const payload = {
        truckId: selectedTruck,
      };
      setData(payload);
    } else {
      setPurchaseData();
      setData();
    }
  };
  return (
    <div className="">
      {(getCompanyTruckDetailsIdLoading || getTruckPurchaseLoading) && (
        <Backdrop />
      )}
      <Dialog open={showModal}>
        <OtpModalTruck
          data={purchaseOtpData}
          setShowModal={setShowModal}
          setPurchaseLoader={setPurchaseLoader}
        />
      </Dialog>
      <h2 className="text-2xl font-bold mb-4">
        {data?._id ? "Update" : "Add"} Truck Details
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={purchaseData ? onPurchase : data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="truckNumber">
                  Truck Number <span>*</span>
                </label>
                <Field
                  type="text"
                  id="truckNumber"
                  name="truckNumber"
                  disabled={readOnly}
                  className="uppercase"
                  list="trucklistpurchase"
                  placeholder="Truck Number"
                  onChange={(e) => {
                    setFieldValue("truckNumber", e.target.value);
                    handlePurchase(e.target.value, setFieldValue);
                  }}
                />
                <ErrorMessage
                  name="truckNumber"
                  component="div"
                  className="text-red-500"
                />
                <datalist id="trucklistpurchase">
                  {purchaseTruckList.map((truck) => (
                    <option
                      key={truck._id}
                      value={truck.truckNumber}
                      label={`${truck.truckCompanyName} - ${truck.truckModelName}`}
                    />
                  ))}
                </datalist>
              </div>
              <div>
                <label htmlFor="purchaseDate">Purchase Date</label>
                <div className="w-full">
                  <DatePicker
                    id="purchaseDate"
                    maxDate={new Date()}
                    disabled={readOnly}
                    name="purchaseDate"
                    errorName={errors.purchaseDate}
                    className=""
                    placeHolder="Purchase Date"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="truckCompanyName">
                  Truck Company Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="truckCompanyName"
                  name="truckCompanyName"
                  className=""
                  placeholder="Truck Company Name"
                  disabled={purchaseData}
                />
                <ErrorMessage
                  name="truckCompanyName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="truckModelName">
                  Truck Model Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="truckModelName"
                  disabled={purchaseData}
                  name="truckModelName"
                  className=""
                  placeholder="Truck Model Name"
                />
                <ErrorMessage
                  name="truckModelName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="truckOwnByName">
                  Truck Owner Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="truckOwnByName"
                  name="truckOwnByName"
                  className=""
                  placeholder="Truck In The Name"
                />
                <ErrorMessage
                  name="truckOwnByName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="typeOfTruck">
                  Type Of Truck <span>*</span>
                </label>
                <Field
                  type="text"
                  id="typeOfTruck"
                  disabled={purchaseData}
                  name="typeOfTruck"
                  className=""
                  list="desig"
                  placeholder="Type Of Truck"
                />
                <datalist id="desig">
                  <option>Open Bucket</option>
                  <option>Closed Shell</option>
                  <option>FlatBed</option>
                </datalist>
                <ErrorMessage
                  name="typeOfTruck"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="usedfor">
                  Truck Used For <span>*</span>
                </label>
                <Field
                  type="text"
                  id="usedfor"
                  name="usedfor"
                  className=""
                  list="use"
                  placeholder="Truck Used For"
                />
                <datalist id="use">
                  {values.typeOfTruck === "Open Bucket" && (
                    <>
                      <option>Coal Transport</option>
                      <option>Fly Ash Transport</option>
                      <option>Sand Transport</option>
                      <option>Soil Transport</option>
                      <option>Metal Ore Transport</option>
                    </>
                  )}
                  {values.typeOfTruck === "Closed Shell" && (
                    <>
                      <option>Box Transport</option>
                      <option>Oil/Water/Chemical Transport</option>
                      <option>Concrete Transport</option>
                      <option>Chiller Products Transport</option>
                    </>
                  )}
                  {values.typeOfTruck === "FlatBed" && (
                    <>
                      <option>Metal Rod/Roll Transport</option>
                      <option>Machinery Transport</option>
                    </>
                  )}
                </datalist>
                <ErrorMessage
                  name="usedfor"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="chasisNumber">
                  Chassis Number <span>*</span>
                </label>
                <Field
                  type="text"
                  id="chasisNumber"
                  name="chasisNumber"
                  disabled={purchaseData}
                  className=""
                  placeholder="Chassis Number"
                />
                <ErrorMessage
                  name="chasisNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div className="col-span-3">
                <label
                  htmlFor="truckImage"
                  className="text-black text-sm font-bold"
                >
                  Truck Image
                </label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="truckImage"
                  disabled={readOnly}
                  values={values}
                />
              </div>
              <div className="mt-auto col-span-3">
                <label
                  htmlFor="isInsurance"
                  className="text-black text-base font-bold"
                >
                  Truck Has Insurance
                </label>

                <div className="flex gap-2">
                  <label
                    htmlFor="isInsuranceTrue"
                    className={` bg-white rounded-xl  cursor-pointer hover:bg-green-100  text-black py-2.5 px-4 ${
                      values.isInsurance
                        ? "ring-2 ring-green-400"
                        : "border border-gray-300"
                    } `}
                  >
                    Yes
                  </label>
                  <Field
                    type="radio"
                    name="isInsurance"
                    value={true}
                    id="isInsuranceTrue"
                    className="sr-only"
                    onChange={() => setFieldValue("isInsurance", true)}
                  />
                  <label
                    htmlFor="isInsuranceFalse"
                    className={`${
                      values.isInsurance === false
                        ? "ring-2 ring-red-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-red-100 text-black py-2.5 px-4`}
                  >
                    No
                  </label>
                  <Field
                    type="radio"
                    name="isInsurance"
                    value={false}
                    id="isInsuranceFalse"
                    className="sr-only"
                    onChange={() => setFieldValue("isInsurance", false)}
                  />
                </div>
              </div>
              {values.isInsurance && (
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 col-span-3">
                  <div className="">
                    <label htmlFor="insuranceNumber">
                      Insurance Policy Number
                    </label>
                    <div className="w-full">
                      <Field
                        id="insuranceNumber"
                        type="text"
                        name="insuranceNumber"
                        className=""
                        placeHolder="Insurance Policy Number"
                      />
                      <ErrorMessage
                        name="insuranceNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="insuranceCompanyName">
                      Insurance Company Name
                    </label>
                    <div className="w-full">
                      <Field
                        id="insuranceCompanyName"
                        type="text"
                        name="insuranceCompanyName"
                        className=""
                        placeHolder="Insurance Company Name"
                      />
                      <ErrorMessage
                        name="insuranceCompanyName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="insuranceTill">Insurance End Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="insuranceTill"
                        name="insuranceTill"
                        errorName={errors.insuranceTill}
                        className=""
                        placeHolder="Insurance End Date"
                      />
                      <ErrorMessage
                        name="insuranceTill"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="insuranceCost">Insurance Cost</label>
                    <div className="w-full">
                      <Field
                        id="insuranceCost"
                        type="number"
                        name="insuranceCost"
                        className=""
                        placeHolder="Insurance Cost"
                      />
                      <ErrorMessage
                        name="insuranceCost"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className=" col-span-3">
                    <label
                      htmlFor="insuranceImage"
                      className="text-black text-sm font-bold"
                    >
                      Insurance Image
                    </label>
                    <FileMultiUpload
                      setFieldValue={setFieldValue}
                      values={values.insuranceImage}
                      name="insuranceImage" // disabled={readOnly} values={values}
                    />
                  </div>
                </div>
              )}
              <div className="mt-auto col-span-3">
                <label
                  htmlFor="isFitness"
                  className="text-black text-base font-bold"
                >
                  Truck has Fitness
                </label>
                <div className="flex gap-2">
                  <label
                    htmlFor="isFitnessTrue"
                    className={`${
                      values.isFitness
                        ? "ring-2 ring-green-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-green-100 text-black py-2.5 px-4`}
                  >
                    Yes
                  </label>
                  <Field
                    type="radio"
                    name="isFitness"
                    value={true}
                    id="isFitnessTrue"
                    className="sr-only"
                    onChange={() => setFieldValue("isFitness", true)}
                  />
                  <label
                    htmlFor="isFitnessFalse"
                    className={`${
                      values.isFitness === false
                        ? "ring-2 ring-red-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-red-100 text-black py-2.5 px-4`}
                  >
                    No
                  </label>
                  <Field
                    type="radio"
                    name="isFitness"
                    value={false}
                    id="isFitnessFalse"
                    className="sr-only"
                    onChange={() => setFieldValue("isFitness", false)}
                  />
                </div>
              </div>
              {values.isFitness && (
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 col-span-3">
                  <div className="">
                    <label htmlFor="fitnessNumber">Fitness Number</label>
                    <div className="w-full">
                      <Field
                        id="fitnessNumber"
                        type="text"
                        name="fitnessNumber"
                        className=""
                        placeHolder="Fitness Number"
                      />
                      <ErrorMessage
                        name="fitnessNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="emiStartDate">Fitness End Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="fitnessTill"
                        name="fitnessTill"
                        errorName={errors.fitnessTill}
                        className=""
                        placeHolder="Fitness End Date"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="fitnessCost">Fitness Cost</label>
                    <div className="w-full">
                      <Field
                        id="fitnessCost"
                        type="number"
                        name="fitnessCost"
                        className=""
                        placeHolder="Fitness Cost"
                      />
                      <ErrorMessage
                        name="fitnessCost"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div className="col-span-3">
                    <label
                      htmlFor="fitnessImage"
                      className="text-black text-sm font-bold"
                    >
                      Fitness Image
                    </label>
                    <FileMultiUpload
                      setFieldValue={setFieldValue}
                      values={values.fitnessImage}
                      name="fitnessImage" // disabled={readOnly} values={values}
                    />
                  </div>
                </div>
              )}
              <div className="mt-auto col-span-3">
                <label
                  htmlFor="isPermit"
                  className="text-black text-base font-bold"
                >
                  Truck Permit
                </label>
                <div className="flex gap-2">
                  <label
                    htmlFor="isPermitTrue"
                    className={`${
                      values.isPermit
                        ? "ring-2 ring-green-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-green-100 text-black py-2.5 px-4`}
                  >
                    Yes
                  </label>
                  <Field
                    type="radio"
                    name="isPermit"
                    value={true}
                    id="isPermitTrue"
                    className="sr-only"
                    onChange={() => setFieldValue("isPermit", true)}
                  />
                  <label
                    htmlFor="isPermitFalse"
                    className={`${
                      values.isPermit === false
                        ? "ring-2 ring-red-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-red-100 text-black py-2.5 px-4`}
                  >
                    No
                  </label>
                  <Field
                    type="radio"
                    name="isPermit"
                    value={false}
                    id="isPermitFalse"
                    className="sr-only"
                    onChange={() => setFieldValue("isPermit", false)}
                  />
                </div>
              </div>
              {values.isPermit && (
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 col-span-3">
                  <div className="">
                    <label htmlFor="permitNumber">Permit Number</label>
                    <div className="w-full">
                      <Field
                        id="permitNumber"
                        type="text"
                        name="permitNumber"
                        className=""
                        placeHolder="Permit Number"
                      />
                      <ErrorMessage
                        name="permitNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="emiStartDate">Permit End Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="permitTill"
                        name="permitTill"
                        errorName={errors.permitTill}
                        className=""
                        placeHolder="Permit End Date"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="permitCost">Permit Cost</label>
                    <div className="w-full">
                      <Field
                        id="permitCost"
                        type="number"
                        name="permitCost"
                        className=""
                        placeHolder="Pemit Cost"
                      />
                      <ErrorMessage
                        name="permitCost"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label
                      htmlFor="permitImage"
                      className="text-black text-sm font-bold"
                    >
                      Pemit Image
                    </label>
                    <FileMultiUpload
                    values={values.permitImage}
                      setFieldValue={setFieldValue}
                      name="permitImage" // disabled={readOnly} values={values}
                    />
                  </div>
                </div>
              )}
              <div className="mt-auto col-span-3">
                <label
                  htmlFor="isEmi"
                  className="text-black text-base font-bold"
                >
                  Truck On Emi
                </label>

                <div className="flex gap-2">
                  <label
                    htmlFor="isEmiTrue"
                    className={`${
                      values.isEmi
                        ? "ring-2 ring-green-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-green-100 text-black py-2.5 px-4`}
                  >
                    Yes
                  </label>
                  <Field
                    type="radio"
                    name="isEmi"
                    value={true}
                    id="isEmiTrue"
                    className="sr-only"
                    onChange={() => setFieldValue("isEmi", true)}
                  />
                  <label
                    htmlFor="isEmiFalse"
                    className={`${
                      values.isEmi === false
                        ? "ring-2 ring-red-400"
                        : "border border-gray-300"
                    } bg-white rounded-xl  cursor-pointer hover:bg-red-100 text-black py-2.5 px-4`}
                  >
                    No
                  </label>
                  <Field
                    type="radio"
                    name="isEmi"
                    value={false}
                    id="isEmiFalse"
                    className="sr-only"
                    onChange={() => setFieldValue("isEmi", false)}
                  />
                </div>
              </div>

              {values.isEmi && (
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 col-span-3">
                  <div className="">
                    <label htmlFor="emiCompanyName">Emi Company Name</label>

                    <Field
                      type="text"
                      id="emiCompanyName"
                      name="emiCompanyName"
                      className=""
                      placeholder="Emi Company Name"
                    />
                    <ErrorMessage
                      name="emiCompanyName"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="emiStartDate">Emi Start Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="emiStartDate"
                        name="emiStartDate"
                        errorName={errors.emiStartDate}
                        className=""
                        showMonthYearPicker
                        dateFormat="MMM / YYYY"
                        placeHolder="Emi Start Date"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="emiEndDate">Emi End Date</label>
                    <div className="w-full">
                      <DatePicker
                        id="emiEndDate"
                        name="emiEndDate"
                        errorName={errors.emiStartDate}
                        showMonthYearPicker
                        dateFormat="MMM / YYYY"
                        className=""
                        placeHolder="Emi End Date"
                        minDate={values.emiStartDate}
                      />
                    </div>
                  </div>
                  <div className="">
                    <label htmlFor="emiMonths">Emi Duration</label>
                    <Field
                      type="number"
                      id="emiMonths"
                      name="emiMonths"
                      value={
                        values?.emiMonths === null
                          ? calculateEmiMonths(
                              values.emiStartDate,
                              values.emiEndDate
                            )
                          : values.emiMonths
                      }
                      className=""
                      onChange={(e) =>
                        setFieldValue("emiMonths", e.target.value)
                      }
                      placeholder="Emi Duration"
                    />
                    <ErrorMessage
                      name="emiMonths"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="emiMonths">Total Emi Amount</label>

                    <Field
                      type="number"
                      id="totalCost"
                      name="totalCost"
                      className=""
                      placeholder="Total Emi Amount"
                    />
                    <ErrorMessage
                      name="totalCost"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="">
                    <label htmlFor="emiMonths">Pending Emi Amount</label>

                    <Field
                      type="number"
                      id="emiLeft"
                      name="emiLeft"
                      className=""
                      placeholder="Pending Emi Amount"
                    />
                    <ErrorMessage
                      name="emiLeft"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
              )}

              <button
                disabled={
                  postCompanyTruckDetailsLoading ||
                  patchCompanyTruckDetailsLoading ||
                  postTruckPurchaseLoading
                }
                type="submit"
                className="primaryButton h-fit mt-auto"
              >
                {data?._id ? "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddTruck;
