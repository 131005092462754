import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDEFFilterAction } from "../../../../redux/company/truck/def/action";
import DatePicker from "../../../datepicker/datePicker";
import DEFListCard from "./DEFListCard";

function DEFList({ data, isSell }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  console.log(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
    // if (!(data?.length > 0)) {
    handleClearFilters();
    // }
  }, [dispatch]);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(getDEFFilterAction(payload));
  };

  const handleClearFilters = () => {
    const TruckId = searchParams.get("truckId");
    const payload = {
      truckId: TruckId,
    };
    dispatch(getDEFFilterAction(payload));
  };
  const filteredData = data?.expenseRecords?.filter(
    (item) =>
      item.cost.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.billNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.expenseName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="flex flex-col">
     {!isSell && <button
        onClick={() =>
          navigate(`/company/truck/addDef?truckId=${searchParams.get("truckId")}`)
        }
        className="primaryButton w-fit ml-auto"
      >
        Add Diesel Exhaust Fuel
      </button>}
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)}>
          <FaFilter className="text-gray-600" />
        </button>
      </div>
      {showFilters && (
        <Formik
          initialValues={{
            startDate: "",
          }}
          onSubmit={handleFilterSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="grid md:grid-cols-2 gap-4 mb-4 bg-gray-700 p-4 rounded-md shadow-md shadow-red-300">
              <div>
                <label htmlFor="startDate">Date From</label>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  startDate={new Date()}
                  endDate={null}
                  selectsStart
                  placeHolder="Select Date Range"
                />
              </div>
              <div>
                <label htmlFor="endDate">Date To</label>
                <DatePicker
                  name="endDate"
                  id="endDate"
                  startDate={new Date()}
                  endDate={null}
                  selectsEnd
                  minDate={values.startDate}
                  placeHolder="Select Date Range"
                />
              </div>

              <div className="md:col-span-2  flex gap-4 justify-between">
                <button type="submit" className="primaryButton">
                  Apply Filters
                </button>
                <button
                  type="button"
                  className="secondaryButton"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className="grid md:grid-cols-2">
        <div className="md:text-left">
          {/* <h3 className="textgrey">Total Liters</h3>
          <p className="font-bold">{data?.totalLiter} Liter</p> */}
        </div>
        <div className="md:text-right">
          <h3 className="textgrey">Total Amount</h3>
          <p className="font-bold">Rs.{data?.totalAmount}</p>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <DEFListCard
            key={item._id}
            data={item}
            reroute={true}
          />
        ))
      ) : (
        <p>No Extra Expense found.</p>
      )}
    </div>
  );
}

export default DEFList;
