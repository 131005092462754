import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { AiOutlineCloseSquare } from "react-icons/ai";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PaginationPackage from "../../paginationPackage";
import usePagination from "headless-pagination-react";

function IncomeTable({ data, setShow, show }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Search params hook
  const [totalItems, setTotalItems] = useState(1000);
  const [maxLinks, setMaxLinks] = useState(10);
  const [perPage, setperPage] = useState(5);
  const { links, from, to, setPage, onNext, onPrevious, page } = usePagination({
    totalItems,
    perPage,
    maxLinks,
  });
  const formatAmount = (amount) =>
    amount
      ? new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 2,
        }).format(amount)
      : "₹0.00";
  useEffect(() => {
    setTotalItems(data?.length);
  }, [data]);
  const handleExport = () => {
    if (!data || !data) {
      console.error("Data is undefined");
      return;
    }

    // Flatten fuel data
    const combinedData = [
      ...(data?.map((item) => ({
        "Token Number": item?.tokenNumber || "-",
        "Start Location": item?.startLocation || "-",
        "End Location": item?.endLocation || "-",
        "Start Date": item?.startDate
          ? moment(item?.startDate).format("DD / MMM / YYYY")
          : "-",
        "End Date": item?.endDate
          ? moment(item?.endDate).format("DD / MMM / YYYY")
          : "-",
        "Start Load": item?.startingLoad + item.weigthType || "-",
        "End Load": item?.endingLoad + item.weigthType || "-",
        "Truck Number": item?.truckId ? item?.truckId.truckNumber : "-",
        "Client Name": item?.clientId ? item?.clientId.name : "-",
        Driver: item?.driverId
          ? item?.driverId?.employeeId?.firstName +
            " " +
            item?.driverId?.employeeId?.lastName
          : "-",
        Helper: item?.helperId
          ? item?.helperId?.employeeId?.firstName +
            " " +
            item?.helperId?.employeeId?.lastName
          : "-",
        "Total Payment": item?.totalPayment
          ? formatAmount(item.totalPayment)
          : "-",
        "Deduction": item?.anyDeduction
          ? formatAmount(item.anyDeduction)
          : "-",
        "Advance Payment": item?.advancePaymentCost
          ? formatAmount(item.advancePaymentCost)
          : "-",
        "GST Amount": item?.gstOnPayment ? item?.gstOnPayment + "%" : "-",
        "Final Payment": item?.finalPayment
          ? formatAmount(item?.finalPayment)
          : "-",
      })) || []),
    ];

    // Find last `fuelFillDate` if startDate is not provided
    const lastFuelFillDate = data?.length
      ? moment(data[data.length - 1]?.endDate || new Date()).format(
          "DD/MMM/YYYY"
        )
      : "";

    // Determine the start and end date for the file name
    const startDate = searchParams.get("startDate")
      ? moment(searchParams.get("startDate")).format("DD/MMM/YYYY")
      : lastFuelFillDate;
    const endDate = searchParams.get("endDate")
      ? moment(searchParams.get("endDate")).format("DD/MMM/YYYY")
      : "";

    // Convert to worksheet
    const ws = XLSX.utils.json_to_sheet(combinedData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Combined Data");

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Use start and end date for file name, defaulting to lastFuelFillDate if needed
    saveAs(
      file,
      `trip_${startDate}-${
        endDate ? endDate : moment(new Date()).format("DD/MMM/YYYY")
      }.xlsx`
    );
  };

  return (
    <div className="col-span-4 order-last overflow-x-auto">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Token Number
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Start Location
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                End Location
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Start Date
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                End Date
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Start Load
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                End Load
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Truck Number
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Client Name
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Driver Name
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Helper Name
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Total Payment
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Deduction
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Advance Payment
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                GST
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Final Payment
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium  justify-end flex gap-3">
                <FiDownload
                  className="text-lg ghostButton cursor-pointer"
                  onClick={() => handleExport()}
                />
                <AiOutlineCloseSquare
                  onClick={() => setShow("")}
                  className="text-red-500 text-xl cursor-pointer"
                />
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data.map((item) => (
              <tr key={item._id}>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900">
                  {item.tokenNumber || "-"}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.startLocation}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.endLocation}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.startDate
                    ? moment(item.startDate).format("DD / MMM / YYYY")
                    : "-"}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.endDate
                    ? moment(item.endDate).format("DD / MMM / YYYY")
                    : "-"}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item?.startingLoad} {item.weigthType}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item?.endingLoad} {item.weigthType}
                </td>
                <td
                  className="whitespace-nowrap px-4 py-2 text-gray-900 text-center"
                  onClick={() =>
                    navigate(
                      "/company/truck/details?truckId=" + item?.truckId._id
                    )
                  }
                >
                  {item?.truckId?.truckNumber}
                </td>
                <td
                  className="whitespace-nowrap px-4 py-2 text-gray-900 text-center"
                  onClick={() =>
                    navigate(
                      "/company/client/details?clientId=" + item?.clientId._id
                    )
                  }
                >
                  {item?.clientId?.name}
                </td>
                <td
                  className="whitespace-nowrap px-4 py-2 text-gray-900 text-center"
                  onClick={() =>
                    navigate("/company/employee/details/" + item?.driverId?._id)
                  }
                >
                  {item?.driverId?.employeeId?.firstName}{" "}
                  {item?.driverId?.employeeId?.lastName}
                </td>
                <td
                  className="whitespace-nowrap px-4 py-2 text-gray-900 text-center"
                  onClick={() =>
                    navigate("/company/employee/details/" + item?.helperId?._id)
                  }
                >
                  {item?.helperId?.employeeId?.firstName}{" "}
                  {item?.helperId?.employeeId?.lastName}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {formatAmount(item.totalPayment)}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {formatAmount(item.anyDeduction)}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {formatAmount(item.advancePaymentCost)}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {item.gstOnPayment || "-"}%
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-900 text-center">
                  {formatAmount(item.finalPayment)}
                </td>
              </tr>
            ))}
            <tr>
              <td colspan="17">
                <PaginationPackage
                  links={links}
                  onNext={onNext}
                  onPrevious={onPrevious}
                  from={from}
                  to={to}
                  totalItems={totalItems}
                  perPage={perPage}
                  totalPages={page}
                  setPage={setPage}
                  setperPage={setperPage}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default IncomeTable;
