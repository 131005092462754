import React, { useEffect, useState } from 'react';
import logo from "../../assets/images/logo2.png"
import LanguageSwitcher from '../../components/languageSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionDiscountAction } from '../../redux/company/subscription/action';
import moment from 'moment';


const Header = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const {
    getSubscriptionDiscount,
    getSubscriptionDiscountLoading,
    getSubscriptionDiscountError,
  } = useSelector((state) => state.SubscriptionSection);
const [discount, setDiscount] = useState(null)
const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    dispatch(getSubscriptionDiscountAction());
  }, []);

  useEffect(() => {
    if (getSubscriptionDiscount?.status === 200) {
      setDiscount(getSubscriptionDiscount?.data);

      const interval = setInterval(() => {
        const now = moment();
        const end = moment(getSubscriptionDiscount?.data?.extraDiscountEndDate);
        const duration = moment.duration(end.diff(now));

        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.minutes());
        const seconds = Math.floor(duration.seconds());

        setTimeLeft(`${hours}h : ${minutes}m : ${seconds}s`);

        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setTimeLeft("Offer ended");
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [getSubscriptionDiscount]);

  return (
    <>
    <header className="bg-gray-700 shadow w-full">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-2">
       
        <div className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className="h-14 w-auto mr-1"
            />
          <h1 className="text-3xl font-bold text-[#efd098] font-mono">TRUCKING</h1>
          </div>
        <LanguageSwitcher/>
      </div>
    </header>
      {discount !== null &&
        <div className="bg-gradient-to-r from-transparent via-yellow-300 to-yellow-500 text-center w-screen sticky">
        Get {discount.extraDiscountPercent}% OFF ({discount.extraDiscountName}) - {timeLeft} Time Left
      </div>
      }
      </>
  );
};

export default Header;
