import React, { useState } from "react";
import Image from "../../../../assets/images/budget.png";
import { Dialog, DialogTrigger } from "../../../../ui/dialog";
import DeleteExpenseTrip from "./extraExpenseTripDelete";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";

function ExtraExpenseTripListCard({data}) {
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div key={data._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button type="button" className="rounded-full bg-gray-400">
            <img
              src={data?.billImage === "" ? Image : data?.billImage}
              alt=""
              className={` ${
                data?.billImage === "" ? "" : "rounded-full"
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.billImage !== "" &&
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.billImage}
              setShowModal={setShowImageModal}
              imagename={`token_${data?._id}`}
            />
          </Dialog>
        }
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button type="button" className="font-bold text-blue-600 text-xl">
            {data?.expenseName}, {data?.billNumber}
          </button>
          <p className="textgrey text-base uppercase">
            {moment(data.expenseDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Cost</h3>
            <p>Rs.{data?.cost}</p>
          </div>
          <div className="md:col-span-3">
            <h3 className="textgrey text-xs md:text-left">Description</h3>
            <p>{data?.description}</p>
          </div>

          <div className="my-auto ">
            <button
              type="button"
              className="secondaryButton h-full"
              onClick={() => navigate(`/company/truck/addExtraExpense?id=${data?._id}`)}
            >
              Edit Expense Details
            </button>
          </div>
          <div className="my-auto col-span-1">
            <Dialog open={showDeleteModal}>
              <DialogTrigger asChild>
                <button
                  type="button"
                  onClick={() => setShowDeleteModal(true)}
                  className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
                >
                  Delete
                </button>
              </DialogTrigger>
              <DeleteExpenseTrip setShowModal={setShowDeleteModal} id={data?._id} />
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraExpenseTripListCard;
