import { Badge } from "../../../ui/badge";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../assets/images/user.png";
import { Dialog } from "../../../ui/dialog";
import ImagePopUpDownload from "../../imagePopUpDownload";

function EmployeeCard({ data, isDetails = false }) {
  const navigate = useNavigate();

  const [showImageModal, setShowImageModal] = useState(false)

  return (
    <div key={data?._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full "
            onClick={() =>
              !isDetails
                ?  navigate(`details?employeeId=${data?._id}`)
                : setShowImageModal(true)
            }
          >
            <img
              src={data?.employeeImage || Image}
              alt=""
              className={`${
                data?.employeeImage ? "rounded-full" : ""
              } h-[100px] w-[100px]`}
            />
          </button>

        </div>
        {data?.employeeImage &&
        <Dialog open={showImageModal}>
            <ImagePopUpDownload image={data?.employeeImage} setShowModal={setShowImageModal} imagename={`employee_${data?._id}`} />
          </Dialog>
        }
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={
              !isDetails
                ? () => navigate(`details?employeeId=${data?._id}`)
                : null
            }
          >
            {data?.firstName} {data?.lastName}
          </button>
          <p className="textgrey text-base">
            {data?.gender}, {moment(data?.dateOfBirth).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Phone Number</h3>
            <p>{data?.phoneNumber}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">Blood Group</h3>
            <p className="md:text-center">{data?.bloodGroup}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">Address</h3>
            <p className="md:text-center">
              {data?.address}, {data?.city}, {data?.state}, {data?.country}, {data?.zipCode}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-right">Blocked</h3>
            <p className="md:text-right">
              {data?.blocked ? (
                <Badge variant="destructive">Blocked</Badge>
              ) : (
                <Badge className="bg-green-700 hover:bg-green-900">
                  Active
                </Badge>
              )}
            </p>
          </div>
          {!isDetails && (
            <div className="my-auto col-span-1">
              <button
                type="button"
                className="secondaryButton bg-green-600 hover:bg-green-800 text-white h-full"
                onClick={() =>
                  navigate(
                    `/company/employee/addEmployee/personalDetails?userId=${data?.userId}`
                  )
                }
              >
                Add This Employee
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeCard;
