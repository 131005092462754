const {
  GET_COMPANY_EMPLOYEE_DETAILS,
  GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  GET_COMPANY_EMPLOYEE_DETAILS_ERROR,
  GET_COMPANY_EMPLOYEE_DETAILS_ID,
  GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS,
  GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR,
  POST_COMPANY_EMPLOYEE_DETAILS,
  POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR,
  DELETE_COMPANY_EMPLOYEE,
  DELETE_COMPANY_EMPLOYEE_SUCCESS,
  DELETE_COMPANY_EMPLOYEE_ERROR,
  GET_COMPANY_EMPLOYEE_DRIVER,
  GET_COMPANY_EMPLOYEE_HELPER,
  GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS,
  GET_COMPANY_EMPLOYEE_DRIVER_ERROR,
  GET_COMPANY_EMPLOYEE_HELPER_SUCCESS,
  GET_COMPANY_EMPLOYEE_HELPER_ERROR,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR,
  POST_EMPLOYEE_FIND,
  POST_EMPLOYEE_FIND_SUCCESS,
  POST_EMPLOYEE_FIND_ERROR,
  GET_COMPANY_EMPLOYEE_FILTER_LIST,
  GET_COMPANY_EMPLOYEE_FILTER_LIST_SUCCESS,
  GET_COMPANY_EMPLOYEE_FILTER_LIST_ERROR,
  PATCH_COMPANY_EMPLOYEE_SALARY,
  PATCH_COMPANY_EMPLOYEE_SALARY_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_SALARY_ERROR,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR,
} = require("../../actions");

const INIT_STATE = {
  getCompanyEmployeeDetails: {},
  getCompanyEmployeeDetailsLoading: false,
  getCompanyEmployeeDetailsError: "",
  getCompanyEmployeeDriver: {},
  getCompanyEmployeeDriverLoading: false,
  getCompanyEmployeeDriverError: "",
  getCompanyEmployeeHelper: {},
  getCompanyEmployeeHelperLoading: false,
  getCompanyEmployeeHelperError: "",
  getCompanyEmployeeDetailsId: {},
  getCompanyEmployeeDetailsIdLoading: false,
  getCompanyEmployeeDetailsIdError: "",
  getCompanyEmployeeFilterList: {},
  getCompanyEmployeeFilterListLoading: false,
  getCompanyEmployeeFilterListError: "",
  postCompanyEmployeeDetails: {},
  postCompanyEmployeeDetailsLoading: false,
  postCompanyEmployeeDetailsError: "",
  postEmployeeFind: {},
  postEmployeeFindLoading: false,
  postEmployeeFindError: "",
  patchCompanyEmployeeDetails: {},
  patchCompanyEmployeeDetailsLoading: false,
  patchCompanyEmployeeDetailsError: "",
  deleteCompanyEmployee: {},
  deleteCompanyEmployeeLoading: false,
  deleteCompanyEmployeeError: "",
  postCompanyEmployeePersonalDetails: {},
  postCompanyEmployeePersonalDetailsLoading: false,
  postCompanyEmployeePersonalDetailsError: "",
  patchCompanyEmployeePersonalDetails: {},
  patchCompanyEmployeePersonalDetailsLoading: false,
  patchCompanyEmployeePersonalDetailsError: "",
  postCompanyEmployeeAttendenceDetails: {},
  postCompanyEmployeeAttendenceDetailsLoading: false,
  postCompanyEmployeeAttendenceDetailsError: "",
  patchCompanyEmployeeAttendenceDetails: {},
  patchCompanyEmployeeAttendenceDetailsLoading: false,
  patchCompanyEmployeeAttendenceDetailsError: "",
  postCompanyEmployeeFamilyDetails: {},
  postCompanyEmployeeFamilyDetailsLoading: false,
  postCompanyEmployeeFamilyDetailsError: "",
  patchCompanyEmployeeFamilyDetails: {},
  patchCompanyEmployeeFamilyDetailsLoading: false,
  patchCompanyEmployeeFamilyDetailsError: "",
  postCompanyEmployeeExperienceDetails: {},
  postCompanyEmployeeExperienceDetailsLoading: false,
  postCompanyEmployeeExperienceDetailsError: "",
  patchCompanyEmployeeExperienceDetails: {},
  patchCompanyEmployeeExperienceDetailsLoading: false,
  patchCompanyEmployeeExperienceDetailsError: "",
  postCompanyEmployeeLicenseDetails: {},
  postCompanyEmployeeLicenseDetailsLoading: false,
  postCompanyEmployeeLicenseDetailsError: "",
  patchCompanyEmployeeLicenseDetails: {},
  patchCompanyEmployeeLicenseDetailsLoading: false,
  patchCompanyEmployeeLicenseDetailsError: "",
  patchCompanyEmployeeSalary: {},
  patchCompanyEmployeeSalaryLoading: false,
  patchCompanyEmployeeSalaryError: "",
  patchCompanyEmployeeTerminate: {},
  patchCompanyEmployeeTerminateLoading: false,
  patchCompanyEmployeeTerminateError: "",
  
};

const CompanyEmployeesection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_EMPLOYEE_DETAILS:
      return {
        ...state,
        getCompanyEmployeeDetails: {},
        getCompanyEmployeeDetailsLoading: true,
      };
    case GET_COMPANY_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        getCompanyEmployeeDetails: action.payload,
        getCompanyEmployeeDetailsLoading: false,
        getCompanyEmployeeDetailsError: "",
      };

    case GET_COMPANY_EMPLOYEE_DETAILS_ERROR:
      return {
        ...state,
        getCompanyEmployeeDetailsLoading: false,
        getCompanyEmployeeDetailsError: action.payload,
      };
    case GET_COMPANY_EMPLOYEE_DRIVER:
      return {
        ...state,
        getCompanyEmployeeDriver: {},
        getCompanyEmployeeDriverLoading: true,
      };
    case GET_COMPANY_EMPLOYEE_DRIVER_SUCCESS:
      return {
        ...state,
        getCompanyEmployeeDriver: action.payload,
        getCompanyEmployeeDriverLoading: false,
        getCompanyEmployeeDriverError: "",
      };

    case GET_COMPANY_EMPLOYEE_DRIVER_ERROR:
      return {
        ...state,
        getCompanyEmployeeDriverLoading: false,
        getCompanyEmployeeDriverError: action.payload,
      };
    case GET_COMPANY_EMPLOYEE_HELPER:
      return {
        ...state,
        getCompanyEmployeeHelper: {},
        getCompanyEmployeeHelperLoading: true,
      };
    case GET_COMPANY_EMPLOYEE_HELPER_SUCCESS:
      return {
        ...state,
        getCompanyEmployeeHelper: action.payload,
        getCompanyEmployeeHelperLoading: false,
        getCompanyEmployeeHelperError: "",
      };

    case GET_COMPANY_EMPLOYEE_HELPER_ERROR:
      return {
        ...state,
        getCompanyEmployeeHelperLoading: false,
        getCompanyEmployeeHelperError: action.payload,
      };
    case GET_COMPANY_EMPLOYEE_FILTER_LIST:
      return {
        ...state,
        getCompanyEmployeeFilterList: {},
        getCompanyEmployeeFilterListLoading: true,
      };
    case GET_COMPANY_EMPLOYEE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        getCompanyEmployeeFilterList: action.payload,
        getCompanyEmployeeFilterListLoading: false,
        getCompanyEmployeeFilterListError: "",
      };

    case GET_COMPANY_EMPLOYEE_FILTER_LIST_ERROR:
      return {
        ...state,
        getCompanyEmployeeFilterListLoading: false,
        getCompanyEmployeeFilterListError: action.payload,
      };
    case GET_COMPANY_EMPLOYEE_DETAILS_ID:
      return {
        ...state,
        getCompanyEmployeeDetailsId: {},
        getCompanyEmployeeDetailsIdLoading: true,
      };
    case GET_COMPANY_EMPLOYEE_DETAILS_ID_SUCCESS:
      return {
        ...state,
        getCompanyEmployeeDetailsId: action.payload,
        getCompanyEmployeeDetailsIdLoading: false,
        getCompanyEmployeeDetailsIdError: "",
      };

    case GET_COMPANY_EMPLOYEE_DETAILS_ID_ERROR:
      return {
        ...state,
        getCompanyEmployeeDetailsIdLoading: false,
        getCompanyEmployeeDetailsIdError: action.payload,
      };
    case POST_COMPANY_EMPLOYEE_DETAILS:
      return {
        ...state,
        postCompanyEmployeeDetails: {},
        postCompanyEmployeeDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeeDetails: action.payload,
        postCompanyEmployeeDetailsLoading: false,
        postCompanyEmployeeDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeeDetailsLoading: false,
        postCompanyEmployeeDetailsError: action.payload,
      };
    case POST_EMPLOYEE_FIND:
      return {
        ...state,
        postEmployeeFind: {},
        postEmployeeFindLoading: true,
      };
    case POST_EMPLOYEE_FIND_SUCCESS:
      return {
        ...state,
        postEmployeeFind: action.payload,
        postEmployeeFindLoading: false,
        postEmployeeFindError: "",
      };

    case POST_EMPLOYEE_FIND_ERROR:
      return {
        ...state,
        postEmployeeFindLoading: false,
        postEmployeeFindError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeDetails: {},
        patchCompanyEmployeeDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeDetails: action.payload,
        patchCompanyEmployeeDetailsLoading: false,
        patchCompanyEmployeeDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeDetailsLoading: false,
        patchCompanyEmployeeDetailsError: action.payload,
      };
    case DELETE_COMPANY_EMPLOYEE:
      return {
        ...state,
        deleteCompanyEmployee: {},
        deleteCompanyEmployeeLoading: true,
      };
    case DELETE_COMPANY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        deleteCompanyEmployee: action.payload,
        deleteCompanyEmployeeLoading: false,
        deleteCompanyEmployeeError: "",
      };

    case DELETE_COMPANY_EMPLOYEE_ERROR:
      return {
        ...state,
        deleteCompanyEmployeeLoading: false,
        deleteCompanyEmployeeError: action.payload,
      };

    case POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS:
      return {
        ...state,
        postCompanyEmployeePersonalDetails: {},
        postCompanyEmployeePersonalDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeePersonalDetails: action.payload,
        postCompanyEmployeePersonalDetailsLoading: false,
        postCompanyEmployeePersonalDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeePersonalDetailsLoading: false,
        postCompanyEmployeePersonalDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS:
      return {
        ...state,
        patchCompanyEmployeePersonalDetails: {},
        patchCompanyEmployeePersonalDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeePersonalDetails: action.payload,
        patchCompanyEmployeePersonalDetailsLoading: false,
        patchCompanyEmployeePersonalDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeePersonalDetailsLoading: false,
        patchCompanyEmployeePersonalDetailsError: action.payload,
      };
      
    case POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS:
      return {
        ...state,
        postCompanyEmployeeAttendenceDetails: {},
        postCompanyEmployeeAttendenceDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeeAttendenceDetails: action.payload,
        postCompanyEmployeeAttendenceDetailsLoading: false,
        postCompanyEmployeeAttendenceDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeeAttendenceDetailsLoading: false,
        postCompanyEmployeeAttendenceDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeAttendenceDetails: {},
        patchCompanyEmployeeAttendenceDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeAttendenceDetails: action.payload,
        patchCompanyEmployeeAttendenceDetailsLoading: false,
        patchCompanyEmployeeAttendenceDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeAttendenceDetailsLoading: false,
        patchCompanyEmployeeAttendenceDetailsError: action.payload,
      };

    case POST_COMPANY_EMPLOYEE_FAMILY_DETAILS:
      return {
        ...state,
        postCompanyEmployeeFamilyDetails: {},
        postCompanyEmployeeFamilyDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeeFamilyDetails: action.payload,
        postCompanyEmployeeFamilyDetailsLoading: false,
        postCompanyEmployeeFamilyDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeeFamilyDetailsLoading: false,
        postCompanyEmployeeFamilyDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeFamilyDetails: {},
        patchCompanyEmployeeFamilyDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeFamilyDetails: action.payload,
        patchCompanyEmployeeFamilyDetailsLoading: false,
        patchCompanyEmployeeFamilyDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeFamilyDetailsLoading: false,
        patchCompanyEmployeeFamilyDetailsError: action.payload,
      };

    case POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS:
      return {
        ...state,
        postCompanyEmployeeExperienceDetails: {},
        postCompanyEmployeeExperienceDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeeExperienceDetails: action.payload,
        postCompanyEmployeeExperienceDetailsLoading: false,
        postCompanyEmployeeExperienceDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeeExperienceDetailsLoading: false,
        postCompanyEmployeeExperienceDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeExperienceDetails: {},
        patchCompanyEmployeeExperienceDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeExperienceDetails: action.payload,
        patchCompanyEmployeeExperienceDetailsLoading: false,
        patchCompanyEmployeeExperienceDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeExperienceDetailsLoading: false,
        patchCompanyEmployeeExperienceDetailsError: action.payload,
      };

    case POST_COMPANY_EMPLOYEE_LICENSE_DETAILS:
      return {
        ...state,
        postCompanyEmployeeLicenseDetails: {},
        postCompanyEmployeeLicenseDetailsLoading: true,
      };
    case POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS:
      return {
        ...state,
        postCompanyEmployeeLicenseDetails: action.payload,
        postCompanyEmployeeLicenseDetailsLoading: false,
        postCompanyEmployeeLicenseDetailsError: "",
      };

    case POST_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR:
      return {
        ...state,
        postCompanyEmployeeLicenseDetailsLoading: false,
        postCompanyEmployeeLicenseDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeLicenseDetails: {},
        patchCompanyEmployeeLicenseDetailsLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeLicenseDetails: action.payload,
        patchCompanyEmployeeLicenseDetailsLoading: false,
        patchCompanyEmployeeLicenseDetailsError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeLicenseDetailsLoading: false,
        patchCompanyEmployeeLicenseDetailsError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_SALARY:
      return {
        ...state,
        patchCompanyEmployeeSalary: {},
        patchCompanyEmployeeSalaryLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_SALARY_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeSalary: action.payload,
        patchCompanyEmployeeSalaryLoading: false,
        patchCompanyEmployeeSalaryError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_SALARY_ERROR:
      return {
        ...state,
        patchCompanyEmployeeSalaryLoading: false,
        patchCompanyEmployeeSalaryError: action.payload,
      };
    case PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS:
      return {
        ...state,
        patchCompanyEmployeeTerminate: {},
        patchCompanyEmployeeTerminateLoading: true,
      };
    case PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_SUCCESS:
      return {
        ...state,
        patchCompanyEmployeeTerminate: action.payload,
        patchCompanyEmployeeTerminateLoading: false,
        patchCompanyEmployeeTerminateError: "",
      };

    case PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS_ERROR:
      return {
        ...state,
        patchCompanyEmployeeTerminateLoading: false,
        patchCompanyEmployeeTerminateError: action.payload,
      };
    default:
      return { ...state };
  }
};

export default CompanyEmployeesection;
