import React from "react";
import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsRight,
  BiChevronsLeft,
} from "react-icons/bi";

function PaginationPackage({
  onNext,
  onPrevious,
  setPage,
  setperPage,
  totalItems,
  from,
  perPage,
  to,
  links,
  totalPages,
}) {
  const startendHandler = (value) => {
    setPage(value);
  };

  // Create an array of numbers from 1 to totalPages
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  return (
    <>
      <div className=" py-3 flex items-center justify-between">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-xs text-gray-700">
              Item per page:{" "}
              <select
                className="focus:outline-none mx-1 py-1 px-2 h-auto w-fit"
                onChange={(e) => setperPage(Number(e.target.value))}
                value={perPage}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
              |{" "}
              <span className="font-medium">
                {from}-{to < totalItems ? to : totalItems}
              </span>{" "}
              of <span className="font-medium">{totalItems}</span> results
            </p>
          </div>
          <div className="flex gap-3">
            <p className="text-xs my-auto">
              {links?.filter((item) => item?.active === true)[0]?.label} of{" "}
              {links?.length} pages
            </p>
            <nav
              className="relative z-0 inline-flex space-x-2 "
              aria-label="Pagination"
            >
              <div
                className="relative inline-flex items-center text-xs font-medium text-blue-500 hover:text-blue-900 cursor-pointer"
                onClick={() => startendHandler(1)}
              >
                <span className="sr-only">First</span>
                <BiChevronsLeft className="text-2xl" aria-hidden="true" />
              </div>
              <div
                className="relative inline-flex items-center text-xs font-medium text-blue-500 hover:text-blue-900 cursor-pointer "
                onClick={onPrevious}
              >
                <span className="sr-only">Previous</span>
                <BiChevronLeft className="text-2xl" aria-hidden="true" />
              </div>

              <div className="space-x-3">
                {links.map((link, i) => (
                  <button
                    type="button"
                    key={i}
                    className={`${
                      link.active
                        ? "bg-white text-blue-500 px-1 py-0.5 font-semibold rounded-md"
                        : undefined
                    } text-sm`}
                    onClick={() =>
                      typeof link.label !== "string"
                        ? setPage(link.label)
                        : undefined
                    }
                    disabled={link.disabled}
                  >
                    {link.label}
                  </button>
                ))}
              </div>
              <div
                className="relative inline-flex items-center text-xs font-medium text-blue-500 hover:text-blue-900 cursor-pointer"
                onClick={onNext}
              >
                <span className="sr-only">Next</span>
                <BiChevronRight className="text-2xl" aria-hidden="true" />
              </div>
              <div
                className="relative inline-flex items-center text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-900"
                onClick={() => startendHandler(totalPages)}
              >
                <span className="sr-only">Last</span>
                <BiChevronsRight className="text-2xl" aria-hidden="true" />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaginationPackage;
