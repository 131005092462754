import moment from "moment/moment";
import React from "react";
import { Badge } from "../../../ui/badge";
function TruckTabDetails({ data }) {
  const date = moment(data?.subscribeTill).diff(moment(new Date()), "days");
  return (
    <div className="bg-white p-4 rounded-md shadow-lg grid grid-cols-4 gap-5 w-full">
      <div className="col-span-4">
        <label htmlFor="" className="font-bold">
          Truck Number
        </label>
        <p className="font-bold">{data?.truckNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Company Name</label>
        <p className="">{data?.truckId?.truckCompanyName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Model Name</label>
        <p className="">{data?.truckId?.truckModelName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Chassis Number</label>
        <p className="">{data?.truckId?.chasisNumber}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck New Purchase Date</label>
        <p className="">
          {moment(data?.truckId?.purchaseDate).format("DD/MMM/YYYY")}
        </p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Purchase Date</label>
        <p className="">
          {moment(data?.purchaseDate).format("DD/MMM/YYYY")}
        </p>
      </div>
      {data?.isSell ?
      (
        <>
          <div>
            <label htmlFor="">Truck Selled</label>
            <p className="flex ">
              <Badge variant="destructive">Sell</Badge>
            </p>
          </div>
          <div className="">
            <label htmlFor="">Truck Sell Date</label>

            <p className="">{moment(data?.sellDate).format("DD/MMM/YYYY")}</p>
          </div>
        </>
      ): 
      <>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Owned By Name</label>
        <p className="">{data?.truckOwnByName}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Used For</label>
        <p className="">{data?.usedfor}</p>
      </div>
      </>
      }
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Truck Type</label>
        <p className="">{data?.truckId?.typeOfTruck}</p>
      </div>
      <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Subscritption End Date</label>
        <p className="">
          {data?.isSubscribe
            ? moment(data?.subscribeTill).format("DD/MMM/YYYY")
            : "Please Subscribe"}
        </p>
      </div>
      {data?.isSubscribe && (
        <div className="lg:col-span-1 md:col-span-2 col-span-4 ">
        <label htmlFor="">Subscritption Expired In</label>
        <div>
        <Badge
        className={` ${date > 120 ? "bg-green-800 " : ""} ${
          date < 120 && date > 30 ? "bg-yellow-500 " : ""
          } ${date < 30 ? "bg-red-500 " : ""}`}
          >
          {date} Days Left
          </Badge>
          </div>
          </div>
        )}
      

      <div className=" col-span-4 ">
        <div className="font-medium text-sm">
          Subscritption Purchase Details
        </div>
        {data?.subscriptionDetails?.map((item, index) => (
          <div className="grid md:grid-cols-5">
            <div>
              <label htmlFor="">Purchase Date</label>
              <p>{moment(item.subscriptionDate).format("DD/MMM/YYYY")}</p>
            </div>
            <div>
              <label htmlFor="">Start/End Date</label>
              <p>
                {moment(item.subscribeStartFrom).format("DD/MMM/YYYY")} -{" "}
                {moment(item.subscribeEnd).format("DD/MMM/YYYY")}
              </p>
            </div>
            <div>
              <label htmlFor="">Cost</label>
              <p>{item.defaultCost}</p>
            </div>
            <div>
              <label htmlFor="">Discount</label>
              <p>
                {item.isDiscount
                  ? `${item.discountPercent}% (${item.discountName})`
                  : "-"}
                  {item?.discountId?.isExtraDiscount && (
                    `+${item.discountId.extraDiscountPercent}% (${item.discountId.extraDiscountName})`
                  )}
              </p>
            </div>
            <div>
              <label htmlFor="">Final Cost</label>
              <p>{item.cost}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TruckTabDetails;
