import { Dialog } from '../../../../ui/dialog'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import EmployeeSalaryDelete from './employeeSalaryDelete'

function EmployeeSalaryCard({data, payButton}) {
    const [showSalaryDeleteModal, setShowSalaryDeleteModal] = useState(false);
  return (
    <div key={data._id}>
          <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
            <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
              <p className="text-blue-700 font-semibold text-xl uppercase">
                Salary for {moment(data.salaryMonth).format("MMMM YYYY")}
              </p>
            </div>

            <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
              <div className="md:text-left">
                <h3 className="textgrey text-xs">Total Pay</h3>
                <p>₹{data.totalPay}</p>
              </div>
              <div>
                <h3 className="textgrey text-xs md:text-center">Add-Ons</h3>
                <p className="md:text-center">₹{data.addOns}</p>
              </div>
              <div>
                <h3 className="textgrey text-xs md:text-center">
                  Payment Date
                </h3>
                <p className="md:text-center">
                  {moment(data.paymentDate).format("DD/MMM/YYYY")}
                </p>
              </div>
              <div>
                <h3 className="textgrey text-xs md:text-right">Payment Type</h3>
                <p className="md:text-right">
                  <div className="">{data.paymentType}</div>
                </p>
              </div>
                <div className="flex">
                  <Link
                    to={`addSalary?id=${data._id}&employeeId=${data?.employeeId}`}
                    className="primaryButton bg-yellow-600 hover:bg-yellow-800 w-full text-center"
                  >
                    Edit Salary
                  </Link>
                </div>
              {/* {payButton && ( */}
                {/* <div className="flex"> */}
                  <Dialog open={showSalaryDeleteModal}>
                    <button
                      onClick={() => setShowSalaryDeleteModal(true)}
                      className="primaryButton bg-red-600 hover:bg-red-800"
                    >
                      Delete Salary
                    </button>
                      <EmployeeSalaryDelete
                        id={data?._id}
                        setShowModal={setShowSalaryDeleteModal}
                      />
                  </Dialog>
                {/* </div> */}
              {/* )} */}
            </div>
          </div>
        </div>
  )
}

export default EmployeeSalaryCard
