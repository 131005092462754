import React, { useState } from "react";
import Image from "../../../assets/images/gas-pump.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import DeleteFuel from "./deleteFuel";
import { Badge } from "../../../ui/badge";
import ImagePopUpDownload from "../../imagePopUpDownload";

function FuelListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div key={data._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button type="button" className="rounded-full bg-gray-400" onClick={()=>setShowImageModal(true)}>
            <img
              src={data?.billImage === "" ? Image : data?.billImage}
              alt=""
              className={` ${
                data?.billImage === "" ? "" : "rounded-full"
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.billImage &&
        <Dialog open={showImageModal}>
            <ImagePopUpDownload image={data?.billImage} setShowModal={setShowImageModal} imagename={`fuel_${data?._id}`} />
          </Dialog>
        }
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button type="button" className="font-bold text-blue-600 text-xl">
            {data?.pumpName}, {data?.billNumber}
          </button>
          <p className="textgrey text-base uppercase">
            {moment(data.fuelFillDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Fuel Cost</h3>
            <p>Rs.{data?.cost}</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Fuel Liters</h3>
            <p>{data?.quantity}Liters</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-left">Cost Per Liter</h3>
            <p className="md:text-left flex flex-col">
              {data?.perLiterCost} per liter
            </p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Truck Number</h3>
            <p>{data?.truckId.truckNumber}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-left ">Trip</h3>
            <p className="flex md:justify-start">
              {data?.tripId?.tokenNumber}, {data?.tripId?.startLocation}-
              {data?.tripId?.endLocation}
            </p>
          </div>

          <div>
            <h3 className="textgrey text-xs md:text-left">Trip Status</h3>
            <p>
              {data?.tripId?.status === "Planned" && (
                <Badge variant="destructive">{data?.tripId?.status}</Badge>
              )}
              {data?.tripId?.status === "Trip Started" && (
                <Badge
                  variant="secondary"
                  className="bg-yellow-500 hover:bg-yellow-800 text-white"
                >
                  {data?.tripId?.status}
                </Badge>
              )}
              {data?.tripId?.status === "Trip Ended" && (
                <Badge
                  variant=""
                  className="bg-blue-500 hover:bg-blue-800 text-white"
                >
                  {data?.tripId?.status}
                </Badge>
              )}
              {data?.tripId?.status === "Completed" && (
                <Badge
                  variant=""
                  className="bg-green-700 hover:bg-green-900 text-white"
                >
                  {data?.tripId?.status}
                </Badge>
              )}
            </p>
          </div>
          <div className="col-span-2">
            <h3 className="textgrey text-xs md:text-left">Description</h3>
            <p>{data?.description}</p>
          </div>
          {data?.tripId?.status !== "Completed" && data?.tripId?.status !== "Planning" && (
            <>
              <div className="my-auto ">
                <button
                  type="button"
                  className="secondaryButton h-full"
                  onClick={() =>
                    navigate(`/company/fuel/addFuel?id=${data?._id}`)
                  }
                >
                  Edit Fuel Details
                </button>
              </div>
              <div className="my-auto col-span-1">
                <Dialog open={showDeleteModal}>
                  <DialogTrigger asChild>
                    <button
                      type="button"
                      onClick={() => setShowDeleteModal(true)}
                      className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
                    >
                      Delete
                    </button>
                  </DialogTrigger>
                  <DeleteFuel
                    setShowModal={setShowDeleteModal}
                    id={data?._id}
                  />
                </Dialog>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FuelListCard;
