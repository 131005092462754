import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_TRUCK,
  GET_COMPANY_TRUCK_DETAILS,
  GET_COMPANY_TRUCK_DETAILS_ID,
  GET_TRUCK_FITNESS,
  GET_TRUCK_FITNESS_BYTRUCKID,
  GET_TRUCK_INSURANCE,
  GET_TRUCK_INSURANCE_BYTRUCKID,
  GET_TRUCK_PERMIT,
  GET_TRUCK_PERMIT_BYTRUCKID,
  GET_TRUCK_PURCHASE,
  PATCH_COMPANY_TRUCK_DETAILS,
  PATCH_TRUCK_FITNESS,
  PATCH_TRUCK_INSURANCE,
  PATCH_TRUCK_PERMIT,
  POST_COMPANY_TRUCK_DETAILS,
  POST_TRUCK_PURCHASE,
  POST_TRUCK_SELL,
} from "../../actions.js";
import {
  deleteCompanyTruckError,
  deleteCompanyTruckSuccess,
  getCompanyTruckDetailsError,
  getCompanyTruckDetailsIdError,
  getCompanyTruckDetailsIdSuccess,
  getCompanyTruckDetailsSuccess,
  getTruckFitnessByTruckIdError,
  getTruckFitnessByTruckIdSuccess,
  getTruckFitnessError,
  getTruckFitnessSuccess,
  getTruckInsuranceByTruckIdError,
  getTruckInsuranceByTruckIdSuccess,
  getTruckInsuranceError,
  getTruckInsuranceSuccess,
  getTruckPermitByTruckIdError,
  getTruckPermitByTruckIdSuccess,
  getTruckPermitError,
  getTruckPermitSuccess,
  getTruckPurchaseError,
  getTruckPurchaseSuccess,
  patchCompanyTruckDetailsError,
  patchCompanyTruckDetailsSuccess,
  patchTruckFitnessError,
  patchTruckFitnessSuccess,
  patchTruckInsuranceError,
  patchTruckInsuranceSuccess,
  patchTruckPermitError,
  patchTruckPermitSuccess,
  postCompanyTruckDetailsError,
  postCompanyTruckDetailsSuccess,
  postTruckPurchaseError,
  postTruckPurchaseSuccess,
  postTruckSellError,
  postTruckSellSuccess,
} from "./action.js";



// Async function to handle API call
const getCompanyTruckDetailsAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTruckDetailsCall(action) {
  try {
    const data = yield call(getCompanyTruckDetailsAsync, action); // Call async function with action payload
    yield put(getCompanyTruckDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTruckDetailsError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTruckDetailsData() {
  yield takeEvery(GET_COMPANY_TRUCK_DETAILS, getCompanyTruckDetailsCall);
}
// Async function to handle API call
const getCompanyTruckDetailsIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
   
    throw error.response.data; // Throw other errors to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getCompanyTruckDetailsIdCall(action) {
  try {
    const data = yield call(getCompanyTruckDetailsIdAsync, action); // Call async function with action payload
    yield put(getCompanyTruckDetailsIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyTruckDetailsIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyTruckDetailsIdData() {
  yield takeEvery(GET_COMPANY_TRUCK_DETAILS_ID, getCompanyTruckDetailsIdCall);
}

// Async function to handle API call
const postCompanyTruckDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/truck/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postCompanyTruckDetailsCall(action) {
  try {
    const data = yield call(postCompanyTruckDetailsAsync, action); // Call async function with action payload
    yield put(postCompanyTruckDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyTruckDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyTruckDetailsData() {
  yield takeEvery(POST_COMPANY_TRUCK_DETAILS, postCompanyTruckDetailsCall);
}

// Async function to handle API call
const postTruckSellAsync = async (payload) => {
  try {
    const response = await api.post(`/company/truck/sell/${payload.payload.id}`, payload.payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postTruckSellCall(action) {
  try {
    const data = yield call(postTruckSellAsync, action); // Call async function with action payload
    yield put(postTruckSellSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postTruckSellError(error)); // Dispatch error action with error object
  }
}
export function* watchPostTruckSellData() {
  yield takeEvery(POST_TRUCK_SELL, postTruckSellCall);
}


// Async function to handle API call
const getTruckPurchaseAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/purchaseTruck/getAll`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckPurchaseCall(action) {
  try {
    const data = yield call(getTruckPurchaseAsync, action); // Call async function with action payload
    yield put(getTruckPurchaseSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckPurchaseError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckPurchaseData() {
  yield takeEvery(GET_TRUCK_PURCHASE, getTruckPurchaseCall);
}

// Async function to handle API call
const getTruckInsuranceByTruckIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/insurance/getTruckById/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckInsuranceByTruckIdCall(action) {
  try {
    console.log("object")
    const data = yield call(getTruckInsuranceByTruckIdAsync, action.payload); // Call async function with action payload
    yield put(getTruckInsuranceByTruckIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckInsuranceByTruckIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckInsuranceByTruckIdData() {
  yield takeEvery(GET_TRUCK_INSURANCE_BYTRUCKID, getTruckInsuranceByTruckIdCall);
}

// Async function to handle API call
const getTruckFitnessByTruckIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/fitness/getTruckById/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckFitnessByTruckIdCall(action) {
  try {
    const data = yield call(getTruckFitnessByTruckIdAsync, action.payload); // Call async function with action payload
    yield put(getTruckFitnessByTruckIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckFitnessByTruckIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckFitnessByTruckIdData() {
  yield takeEvery(GET_TRUCK_FITNESS_BYTRUCKID, getTruckFitnessByTruckIdCall);
}

// Async function to handle API call
const getTruckPermitByTruckIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/truck/permit/getTruckById/${payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckPermitByTruckIdCall(action) {
  try {
    const data = yield call(getTruckPermitByTruckIdAsync, action.payload); // Call async function with action payload
    yield put(getTruckPermitByTruckIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckPermitByTruckIdError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckPermitByTruckIdData() {
  yield takeEvery(GET_TRUCK_PERMIT_BYTRUCKID, getTruckPermitByTruckIdCall);
}


// Async function to handle API call
const getTruckInsuranceAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/insurance/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckInsuranceCall(action) {
  try {
    const data = yield call(getTruckInsuranceAsync, action); // Call async function with action payload
    yield put(getTruckInsuranceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckInsuranceError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckInsuranceData() {
  yield takeEvery(GET_TRUCK_INSURANCE, getTruckInsuranceCall);
}

// Async function to handle API call
const getTruckFitnessAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/fitness/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckFitnessCall(action) {
  try {
    const data = yield call(getTruckFitnessAsync, action); // Call async function with action payload
    yield put(getTruckFitnessSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckFitnessError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckFitnessData() {
  yield takeEvery(GET_TRUCK_FITNESS, getTruckFitnessCall);
}

// Async function to handle API call
const getTruckPermitAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/permit/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckPermitCall(action) {
  try {
    const data = yield call(getTruckPermitAsync, action); // Call async function with action payload
    yield put(getTruckPermitSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckPermitError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckPermitData() {
  yield takeEvery(GET_TRUCK_PERMIT, getTruckPermitCall);
}


// Async function to handle API call
const postTruckPurchaseAsync = async (payload) => {
  try {
    const response = await api.post(`/company/truck/purchase/${payload.payload.id}`, payload.payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* postTruckPurchaseCall(action) {
  try {
    const data = yield call(postTruckPurchaseAsync, action); // Call async function with action payload
    yield put(postTruckPurchaseSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postTruckPurchaseError(error)); // Dispatch error action with error object
  }
}
export function* watchPostTruckPurchaseData() {
  yield takeEvery(POST_TRUCK_PURCHASE, postTruckPurchaseCall);
}


// Async function to handle API call
const patchCompanyTruckDetailsAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.patch(
      `/company/truck/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchCompanyTruckDetailsCall(action) {
  try {
    const data = yield call(patchCompanyTruckDetailsAsync, action); // Call async function with action payload
    yield put(patchCompanyTruckDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyTruckDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyTruckDetailsData() {
  yield takeEvery(PATCH_COMPANY_TRUCK_DETAILS, patchCompanyTruckDetailsCall);
}
// Async function to handle API call
const deleteCompanyTruckAsync = async (payload) => {
  try {
    console.log(payload);
    const response = await api.delete(
      `/company/truck/delete/${payload.payload.id}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* deleteCompanyTruckCall(action) {
  try {
    const data = yield call(deleteCompanyTruckAsync, action); // Call async function with action payload
    yield put(deleteCompanyTruckSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyTruckError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyTruckData() {
  yield takeEvery(DELETE_COMPANY_TRUCK, deleteCompanyTruckCall);
}
//UPDATE
// Async function to handle API call
const patchTruckInsuranceAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/alert/insurance/update/${payload.id}`, payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchTruckInsuranceCall(action) {
  try {
    const data = yield call(patchTruckInsuranceAsync, action.payload); // Call async function with action payload
    yield put(patchTruckInsuranceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchTruckInsuranceError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchTruckInsuranceData() {
  yield takeEvery(PATCH_TRUCK_INSURANCE, patchTruckInsuranceCall);
}

// Async function to handle API call
const patchTruckFitnessAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/alert/fitness/update/${payload.id}`, payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchTruckFitnessCall(action) {
  try {
    const data = yield call(patchTruckFitnessAsync, action.payload); // Call async function with action payload
    yield put(patchTruckFitnessSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchTruckFitnessError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchTruckFitnessData() {
  yield takeEvery(PATCH_TRUCK_FITNESS, patchTruckFitnessCall);
}

// Async function to handle API call
const patchTruckPermitAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/alert/permit/update/${payload.id}`, payload.body);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* patchTruckPermitCall(action) {
  try {
    const data = yield call(patchTruckPermitAsync, action.payload); // Call async function with action payload
    yield put(patchTruckPermitSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchTruckPermitError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchTruckPermitData() {
  yield takeEvery(PATCH_TRUCK_PERMIT, patchTruckPermitCall);
}


export default function* rootSaga() {
  yield all([

    fork(watchGetCompanyTruckDetailsData),
    fork(watchGetCompanyTruckDetailsIdData),
    fork(watchPostCompanyTruckDetailsData),
    fork(watchPostTruckSellData),
    fork(watchGetTruckPurchaseData),
    fork(watchGetTruckInsuranceByTruckIdData),
    fork(watchGetTruckFitnessByTruckIdData),
    fork(watchGetTruckPermitByTruckIdData),
    fork(watchGetTruckInsuranceData),
    fork(watchGetTruckFitnessData),
    fork(watchGetTruckPermitData),
    fork(watchPostTruckPurchaseData),
    fork(watchPatchCompanyTruckDetailsData),
    fork(watchDeleteCompanyTruckData),
    fork(watchPatchTruckInsuranceData),
    fork(watchPatchTruckFitnessData),
    fork(watchPatchTruckPermitData),
  ]);
}
