import { getCityAction, getStateAction } from "../../redux/address/action";
import { getCompanyDetailsAction } from "../../redux/company/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../backdrop";
import Image from "../../assets/images/office-building.png";
import moment from "moment";
import { Dialog } from "../../ui/dialog";
import ImagePopUpDownload from "../imagePopUpDownload";
import { Link } from "react-router-dom";

function Details() {
  const dispacth = useDispatch();
  const [showImageModal, setShowImageModal] = useState(false)
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [data, setData] = useState({});
  const {
    getCompanyDetails,
    getCompanyDetailsLoading,
    getCompanyDetailsError,
  } = useSelector((state) => state.Companysection);
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );
  useEffect(() => {
    dispacth(getCompanyDetailsAction());
  }, [dispacth]);
  useEffect(() => {
    if (getState?.status === 200) {
      if (data?.company?.state) {
        setState(getState?.data?.filter((item) => item.id == data?.company.state)[0].name);
      }
    }
  }, [getState]);
  useEffect(() => {
    if (getCity?.status === 200) {
      if (data?.company?.city) {
        setCity(
          getCity?.data.filter((item) => item.id == data?.company.city)[0].name
        );
      }
    }
  }, [getCity]);
  useEffect(() => {
    if (getCompanyDetails?.status === 200) {
      setData(getCompanyDetails?.data);
      dispacth(getStateAction());
      handleCity(getCompanyDetails?.data?.company?.state);
    }
  }, [getCompanyDetails]);
  const handleCity = (id) => {
    dispacth(getCityAction(id));
  };

  return (
    <div>
      {(getStateLoading || getCityLoading || getCompanyDetailsLoading) && (
        <Backdrop />
      )}
      <div className="flex justify-end mb-10">
        <Link to={"addDetails"} className="primaryButton w-fit bg-yellow-600 hover:bg-yellow-800" >Edit Details</Link>
      </div>
      <div className="bg-white p-4 rounded-md shadow-lg grid md:grid-cols-4 gap-5 w-full">
        <div className="rounded-full  w-fit md:col-span-4 col-span-1">
          <img
            src={data?.company?.companyLogo ? data?.company?.companyLogo : Image}
            alt=""
            className={` ${
              data?.company?.companyLogo ? "rounded-full" : ""
            }  h-[100px] w-[100px]`}
            onClick={()=>setShowImageModal(true)}
          />
        </div>
        {data?.company?.companyLogo &&
        <Dialog open={showImageModal}>
            <ImagePopUpDownload image={data?.company?.companyLogo} setShowModal={setShowImageModal} imagename={`company_${data?.company?._id}`} />
          </Dialog>
        }
        <div>
          <label htmlFor="" className="font-bold">
            Company Id
          </label>
          <p className="font-bold"> {data?.company?._id}</p>
        </div>
        <div>
          <label htmlFor="" className="font-bold">
            Company Name
          </label>
          <p className="font-bold"> {data?.company?.companyName}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Email
          </label>
          <p className=""> {data?.user?.email}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Phone Number
          </label>
          <p className=""> {data?.user?.phoneNumber}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Alternate Phone Number
          </label>
          <p className=""> {data?.user?.alternateMobileNumber}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Account Number
          </label>
          <p className=""> {data?.company?.accountNumber}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Company Owner Name
          </label>
          <p className=""> {data?.company?.companyOwnerBy}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Company Start Date
          </label>
          <p className="">
            {" "}
            {moment(data?.company?.companyStartDate).format("DD/MMM/YYYY")}
          </p>
        </div>
        <div>
          <label htmlFor="" className="">
            Company GST Number
          </label>
          <p className=""> {data?.company?.gstNumber}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Address
          </label>
          <p className=""> {data?.company?.address}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            City
          </label>
          <p className=""> {city}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            State
          </label>
          <p className=""> {state}</p>
        </div>
        <div>
          <label htmlFor="" className="">
            Country
          </label>
          <p className=""> {data?.company?.country}</p>
        </div> 
      </div>
    </div>
  );
}

export default Details;

const data = {
  user: {
    _id: "66824d5a977d7b013f994a9d",
    phoneNumber: 8959747807,
    firstName: "Sagar",
    lastName: "Soni",
    authorized: "true",
    alternateMobileNumber: 8269940177,
    email: "dusksoni@gmail.com",
    userType: "admin",
    userCreatedDate: "2024-07-01T06:31:54.077Z",
    __v: 20,
    blocked: false,
    createdAt: "2024-07-06T22:13:56.168Z",
    disabled: false,
    updatedAt: "2024-07-06T22:13:56.168Z",
  },
  company: {
    _id: "669294ac9b6aa30f4028a3ff",
    userId: "669166078c040452f4b0cda1",
    phoneNumber: 8959747807,
    companyName: "test",
    companyLogo:
      "https://res.cloudinary.com/dsn0wxtly/image/upload/v1720882341/acvr5rjqfg4eljsorq5c.jpg",
    alternateMobileNumber: null,
    accountNumber: 5455165161915,
    companyOwnerBy: "test",
    companyStartDate: "2024-06-30T18:30:00.000Z",
    address: "123 Street Road",
    city: "132581",
    state: "4040",
    country: "India",
    gstNumber: "985916515615",
    disabled: false,
    createdAt: "2024-07-13T14:52:28.073Z",
    updatedAt: "2024-07-13T18:15:54.059Z",
    __v: 0,
  },
};
