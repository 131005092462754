import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import DeleteClient from "./deleteClient";
import { Badge } from "../../../ui/badge";

function ClientListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div key={data._id}>
      <div className="grid grid-cols-4 gap-x-7 my-10 md:py-2 sm:py-5 py-2 md:px-10 px-5 bg-white rounded-lg shadow-2xl">
        <div className=" col-span-4 row-span-1 self-center lg:self-auto mb-5">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={
              reroute
                ? () =>
                    navigate(
                      `details?clientId=${data?._id}`
                    )
                : null
            }
          >
            {data?.name}, {data?.ownerName}
          </button>
          
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-4 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Phone Number</h3>
            <p>{data?.phoneNumber}</p>
          </div>
          <div className="md:text-left">
            <h3 className="textgrey text-xs">GST Number</h3>
            <p>{data?.gstNumber}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-left ">Total Payment</h3>
            <p className="flex md:justify-start">Rs. {data?.totalpayment}</p>
          </div>

          <div>
            <h3 className="textgrey text-xs md:text-left">Pending Amount</h3>
            <p>Rs. {data?.pendingAmount}</p>
          </div>
          <div className="col-span-2">
            <h3 className="textgrey text-xs md:text-left">Description</h3>
            <p>{data?.description}</p>
          </div>
          {!data?.disabled && (
            <>
              <div className="my-auto ">
                <button
                  type="button"
                  className="secondaryButton h-full"
                  onClick={() =>
                    navigate(`/company/client/addClient?id=${data?._id}`)
                  }
                >
                  Edit Client Details
                </button>
              </div>
              <div className="my-auto col-span-1">
                <Dialog open={showDeleteModal}>
                  <DialogTrigger asChild>
                    <button
                      type="button"
                      onClick={() => setShowDeleteModal(true)}
                      className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
                    >
                      Delete
                    </button>
                  </DialogTrigger>
                  <DeleteClient
                    setShowModal={setShowDeleteModal}
                    id={data?._id}
                  />
                </Dialog>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientListCard;
