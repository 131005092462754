import React, { useEffect, useState } from "react";
import Header from "./header";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import { Toaster } from "react-hot-toast";
import { getCookies } from "../auth/auth";
import AlertInsurance from "../components/company/alert/alertInsurance";

function Layout() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setSidebarOpen(false);
  }, [navigate]);

  useEffect(() => {
    handle()
  }, []);
  const handle = async () => {
    const user = await getCookies("userType");
    setUserType(user);
  };


  return (
    <>
      <div className="flex h-screen flex-col max-h-screen max-w-screen">
        <div className="fixed top-0 left-0 right-0 z-50">
          <Header toggleSidebar={toggleSidebar} />
        </div>
        <div className="flex flex-1 mt-16">
          {userType === "company" && <AlertInsurance />}
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <div className="md:p-10 p-4 bg-gray-100 w-full overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
