import {
  DELETE_COMPANY_TRIP,
  DELETE_COMPANY_TRIP_ERROR,
  DELETE_COMPANY_TRIP_SUCCESS,
  GET_COMPANY_TRIP_DETAILS,
  GET_COMPANY_TRIP_DETAILS_ERROR,
  GET_COMPANY_TRIP_DETAILS_FILTER,
  GET_COMPANY_TRIP_DETAILS_FILTER_ERROR,
  GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS,
  GET_COMPANY_TRIP_DETAILS_ID,
  GET_COMPANY_TRIP_DETAILS_ID_ERROR,
  GET_COMPANY_TRIP_DETAILS_ID_SUCCESS,
  GET_COMPANY_TRIP_DETAILS_SUCCESS,
  PATCH_COMPANY_TRIP_DETAILS,
  PATCH_COMPANY_TRIP_DETAILS_ERROR,
  PATCH_COMPANY_TRIP_DETAILS_SUCCESS,
  PATCH_COMPANY_TRIP_PAYMENT,
  PATCH_COMPANY_TRIP_PAYMENT_ERROR,
  PATCH_COMPANY_TRIP_PAYMENT_SUCCESS,
  POST_COMPANY_TRIP_DETAILS,
  POST_COMPANY_TRIP_DETAILS_ERROR,
  POST_COMPANY_TRIP_DETAILS_SUCCESS,
} from "../../actions";

//truck
export const getCompanyTripDetailsAction = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS,
  payload: info,
});
export const getCompanyTripDetailsSuccess = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS_SUCCESS,
  payload: info,
});
export const getCompanyTripDetailsError = (error) => ({
  type: GET_COMPANY_TRIP_DETAILS_ERROR,
  payload: error,
});
export const getCompanyTripDetailsIdAction = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS_ID,
  payload: info,
});
export const getCompanyTripDetailsIdSuccess = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS_ID_SUCCESS,
  payload: info,
});
export const getCompanyTripDetailsIdError = (error) => ({
  type: GET_COMPANY_TRIP_DETAILS_ID_ERROR,
  payload: error,
});
export const getCompanyTripDetailsFilterAction = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS_FILTER,
  payload: info,
});
export const getCompanyTripDetailsFilterSuccess = (info) => ({
  type: GET_COMPANY_TRIP_DETAILS_FILTER_SUCCESS,
  payload: info,
});
export const getCompanyTripDetailsFilterError = (error) => ({
  type: GET_COMPANY_TRIP_DETAILS_FILTER_ERROR,
  payload: error,
});
export const postCompanyTripDetailsAction = (info) => ({
  type: POST_COMPANY_TRIP_DETAILS,
  payload: info,
});
export const postCompanyTripDetailsSuccess = (info) => ({
  type: POST_COMPANY_TRIP_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyTripDetailsError = (error) => ({
  type: POST_COMPANY_TRIP_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyTripDetailsAction = (info) => ({
  type: PATCH_COMPANY_TRIP_DETAILS,
  payload: info,
});
export const patchCompanyTripDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_TRIP_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyTripDetailsError = (error) => ({
  type: PATCH_COMPANY_TRIP_DETAILS_ERROR,
  payload: error,
});
export const patchCompanyTripPaymentAction = (info) => ({
  type: PATCH_COMPANY_TRIP_PAYMENT,
  payload: info,
});
export const patchCompanyTripPaymentSuccess = (info) => ({
  type: PATCH_COMPANY_TRIP_PAYMENT_SUCCESS,
  payload: info,
});
export const patchCompanyTripPaymentError = (error) => ({
  type: PATCH_COMPANY_TRIP_PAYMENT_ERROR,
  payload: error,
});
export const deleteCompanyTripAction = (info) => ({
  type: DELETE_COMPANY_TRIP,
  payload: info,
});
export const deleteCompanyTripSuccess = (info) => ({
  type: DELETE_COMPANY_TRIP_SUCCESS,
  payload: info,
});
export const deleteCompanyTripError = (error) => ({
  type: DELETE_COMPANY_TRIP_ERROR,
  payload: error,
});
