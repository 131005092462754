import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/languageSwitcher";
import { FiMenu } from "react-icons/fi";
import logo from "../assets/images/logo2.png";
import { getSubscriptionDiscountAction } from "../redux/company/subscription/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Header = ({ toggleSidebar }) => {
  const dispatch = useDispatch();
  const {
    getSubscriptionDiscount,
    getSubscriptionDiscountLoading,
    getSubscriptionDiscountError,
  } = useSelector((state) => state.SubscriptionSection);
  const [discount, setDiscount] = useState();
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    dispatch(getSubscriptionDiscountAction());
  }, [dispatch]);

  useEffect(() => {
    if (getSubscriptionDiscount?.status === 200) {
      setDiscount(getSubscriptionDiscount?.data);

      const interval = setInterval(() => {
        const now = moment();
        const end = moment(getSubscriptionDiscount?.data?.validTo);
        const duration = moment.duration(end.diff(now));

        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.asHours() % 24);
        const minutes = Math.floor(duration.minutes());
        const seconds = Math.floor(duration.seconds());

        setTimeLeft(`${days}d : ${hours}h : ${minutes}m : ${seconds}s`);

        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setTimeLeft("Offer ended");
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [getSubscriptionDiscount]);

  return (
    <>
      <header className="bg-gray-700 shadow w-full">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-2">
          <button onClick={toggleSidebar} className="">
            <FiMenu className="text-2xl text-white" />
          </button>
          <div className="flex items-center h-12">
            {/* <img src={logo} alt="Logo" className="h-12 w-auto mr-1" /> */}
            <h1 className="text-3xl font-bold logoFont   text-[#efd098] ">
              TRUCKING
            </h1>
          </div>
          <LanguageSwitcher />
        </div>
      </header>
      {discount && (
        <div className="bg-gradient-to-r from-transparent via-yellow-300 to-yellow-500 text-center w-screen sticky">
          Get {discount.extraDiscountPercent}% OFF ({discount.extraDiscountName}) - {timeLeft} Time Left
        </div>
      )}
    </>
  );
};

export default Header;
