import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  DELETE_COMPANY_EMPLOYEE,
  GET_COMPANY_EMPLOYEE_DETAILS,
  GET_COMPANY_EMPLOYEE_DETAILS_ID,
  GET_COMPANY_EMPLOYEE_DRIVER,
  GET_COMPANY_EMPLOYEE_HELPER,
  PATCH_COMPANY_EMPLOYEE_DETAILS,
  POST_COMPANY_EMPLOYEE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS,
  PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  POST_COMPANY_EMPLOYEE_FAMILY_DETAILS,
  PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS,
  PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  POST_COMPANY_EMPLOYEE_LICENSE_DETAILS,
  POST_EMPLOYEE_FIND,
  PATCH_COMPANY_EMPLOYEE_SALARY,
  PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS,
} from "../../actions.js";
import {
  deleteCompanyEmployeeError,
  deleteCompanyEmployeeSuccess,
  getCompanyEmployeeDetailsError,
  getCompanyEmployeeDetailsIdError,
  getCompanyEmployeeDetailsIdSuccess,
  getCompanyEmployeeDetailsSuccess,
  getCompanyEmployeeDriverError,
  getCompanyEmployeeDriverSuccess,
  getCompanyEmployeeHelperError,
  getCompanyEmployeeHelperSuccess,
  patchCompanyEmployeeDetailsError,
  patchCompanyEmployeeDetailsSuccess,
  postCompanyEmployeeDetailsError,
  postCompanyEmployeeDetailsSuccess,
  patchCompanyEmployeePersonalDetailsError,
  patchCompanyEmployeePersonalDetailsSuccess,
  postCompanyEmployeePersonalDetailsError,
  postCompanyEmployeePersonalDetailsSuccess,
  patchCompanyEmployeeAttendenceDetailsError,
  patchCompanyEmployeeAttendenceDetailsSuccess,
  postCompanyEmployeeAttendenceDetailsError,
  postCompanyEmployeeAttendenceDetailsSuccess,
  patchCompanyEmployeeFamilyDetailsError,
  patchCompanyEmployeeFamilyDetailsSuccess,
  postCompanyEmployeeFamilyDetailsError,
  postCompanyEmployeeFamilyDetailsSuccess,
  patchCompanyEmployeeExperienceDetailsError,
  patchCompanyEmployeeExperienceDetailsSuccess,
  postCompanyEmployeeExperienceDetailsError,
  postCompanyEmployeeExperienceDetailsSuccess,
  patchCompanyEmployeeLicenseDetailsError,
  patchCompanyEmployeeLicenseDetailsSuccess,
  postCompanyEmployeeLicenseDetailsError,
  postCompanyEmployeeLicenseDetailsSuccess,
  postEmployeeFindSuccess,
  postEmployeeFindError,
  patchCompanyEmployeeSalarySuccess,
  patchCompanyEmployeeSalaryError,
  patchCompanyEmployeeTerminateSuccess,
  patchCompanyEmployeeTerminateError,
} from "./action.js";

// Async function to handle API call
const getCompanyEmployeeDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_COMPANY_EMPLOYEE_DETAILS action
function* getCompanyEmployeeDetailsCall(action) {
  try {
    const data = yield call(getCompanyEmployeeDetailsAsync, action); // Call async function with action payload
    yield put(getCompanyEmployeeDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyEmployeeDetailsError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyEmployeeDetailsData() {
  yield takeEvery(GET_COMPANY_EMPLOYEE_DETAILS, getCompanyEmployeeDetailsCall);
}

// Async function to handle API call
const getCompanyEmployeeDriverAsync = async (payload) => {
  try {
    const response = await api.get(`/company/employee/driver`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_COMPANY_EMPLOYEE_DRIVER action
function* getCompanyEmployeeDriverCall(action) {
  try {
    const data = yield call(getCompanyEmployeeDriverAsync, action); // Call async function with action payload
    yield put(getCompanyEmployeeDriverSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyEmployeeDriverError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyEmployeeDriverData() {
  yield takeEvery(GET_COMPANY_EMPLOYEE_DRIVER, getCompanyEmployeeDriverCall);
}

// Async function to handle API call
const getCompanyEmployeeHelperAsync = async (payload) => {
  try {
    const response = await api.get(`/company/employee/helper`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_COMPANY_EMPLOYEE_HELPER action
function* getCompanyEmployeeHelperCall(action) {
  try {
    const data = yield call(getCompanyEmployeeHelperAsync, action); // Call async function with action payload
    yield put(getCompanyEmployeeHelperSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyEmployeeHelperError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyEmployeeHelperData() {
  yield takeEvery(GET_COMPANY_EMPLOYEE_HELPER, getCompanyEmployeeHelperCall);
}

// Async function to handle API call
const getCompanyEmployeeDetailsIdAsync = async (payload) => {
  try {
    const response = await api.get(`/company/employee/get/${payload.payload}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle GET_COMPANY_EMPLOYEE_DETAILS_ID action
function* getCompanyEmployeeDetailsIdCall(action) {
  try {
    const data = yield call(getCompanyEmployeeDetailsIdAsync, action); // Call async function with action payload
    yield put(getCompanyEmployeeDetailsIdSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getCompanyEmployeeDetailsIdError(error)); // Dispatch error action with error object
  }
}

export function* watchGetCompanyEmployeeDetailsIdData() {
  yield takeEvery(GET_COMPANY_EMPLOYEE_DETAILS_ID, getCompanyEmployeeDetailsIdCall);
}

// Async function to handle API call
const postEmployeeFindAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/find`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_EMPLOYEE_DETAILS action
function* postEmployeeFindCall(action) {
  try {
    const data = yield call(postEmployeeFindAsync, action); // Call async function with action payload
    yield put(postEmployeeFindSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postEmployeeFindError(error)); // Dispatch error action with error object
  }
}
export function* watchPostEmployeeFindData() {
  yield takeEvery(POST_EMPLOYEE_FIND, postEmployeeFindCall);
}
// Async function to handle API call
const postCompanyEmployeeDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/add`, payload.payload);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_EMPLOYEE_DETAILS action
function* postCompanyEmployeeDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeeDetailsAsync, action); // Call async function with action payload
    yield put(postCompanyEmployeeDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(postCompanyEmployeeDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPostCompanyEmployeeDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_DETAILS, postCompanyEmployeeDetailsCall);
}

// Async function to handle API call
const patchCompanyEmployeeDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(
      `/company/employee/update/${payload.payload.id}`,
      payload.payload.body
    );
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle PATCH_COMPANY_EMPLOYEE_DETAILS action
function* patchCompanyEmployeeDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeDetailsAsync, action); // Call async function with action payload
    yield put(patchCompanyEmployeeDetailsSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(patchCompanyEmployeeDetailsError(error)); // Dispatch error action with error object
  }
}
export function* watchPatchCompanyEmployeeDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_DETAILS, patchCompanyEmployeeDetailsCall);
}

// Async function to handle API call
const deleteCompanyEmployeeAsync = async (payload) => {
  try {
    const response = await api.delete(
      `/company/trip/delete/${payload.payload.id}`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle DELETE_COMPANY_EMPLOYEE action
function* deleteCompanyEmployeeCall(action) {
  try {
    const data = yield call(deleteCompanyEmployeeAsync, action); // Call async function with action payload
    yield put(deleteCompanyEmployeeSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(deleteCompanyEmployeeError(error)); // Dispatch error action with error object
  }
}
export function* watchDeleteCompanyEmployeeData() {
  yield takeEvery(DELETE_COMPANY_EMPLOYEE, deleteCompanyEmployeeCall);
}

// Add additional sagas for new actions
// Personal details
const patchCompanyEmployeePersonalDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/personal/update/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeePersonalDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeePersonalDetailsAsync, action);
    yield put(patchCompanyEmployeePersonalDetailsSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeePersonalDetailsError(error));
  }
}

const postCompanyEmployeePersonalDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/personal/add`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postCompanyEmployeePersonalDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeePersonalDetailsAsync, action);
    yield put(postCompanyEmployeePersonalDetailsSuccess(data));
  } catch (error) {
    yield put(postCompanyEmployeePersonalDetailsError(error));
  }
}

export function* watchPatchCompanyEmployeePersonalDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_PERSONAL_DETAILS, patchCompanyEmployeePersonalDetailsCall);
}

export function* watchPostCompanyEmployeePersonalDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_PERSONAL_DETAILS, postCompanyEmployeePersonalDetailsCall);
}

// Attendance details
const patchCompanyEmployeeAttendenceDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/attendance/update/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeAttendenceDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeAttendenceDetailsAsync, action);
    yield put(patchCompanyEmployeeAttendenceDetailsSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeAttendenceDetailsError(error));
  }
}

const postCompanyEmployeeAttendenceDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/attendance/add`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postCompanyEmployeeAttendenceDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeeAttendenceDetailsAsync, action);
    yield put(postCompanyEmployeeAttendenceDetailsSuccess(data));
  } catch (error) {
    yield put(postCompanyEmployeeAttendenceDetailsError(error));
  }
}

export function* watchPatchCompanyEmployeeAttendenceDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS, patchCompanyEmployeeAttendenceDetailsCall);
}

export function* watchPostCompanyEmployeeAttendenceDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_ATTENDENCE_DETAILS, postCompanyEmployeeAttendenceDetailsCall);
}

// Family details
const patchCompanyEmployeeFamilyDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/family-details`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeFamilyDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeFamilyDetailsAsync, action);
    yield put(patchCompanyEmployeeFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeFamilyDetailsError(error));
  }
}

const postCompanyEmployeeFamilyDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/family-details`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postCompanyEmployeeFamilyDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeeFamilyDetailsAsync, action);
    yield put(postCompanyEmployeeFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(postCompanyEmployeeFamilyDetailsError(error));
  }
}

export function* watchPatchCompanyEmployeeFamilyDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_FAMILY_DETAILS, patchCompanyEmployeeFamilyDetailsCall);
}

export function* watchPostCompanyEmployeeFamilyDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_FAMILY_DETAILS, postCompanyEmployeeFamilyDetailsCall);
}

// Experience details
const patchCompanyEmployeeExperienceDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/experience/update/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeExperienceDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeExperienceDetailsAsync, action);
    yield put(patchCompanyEmployeeExperienceDetailsSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeExperienceDetailsError(error));
  }
}

const postCompanyEmployeeExperienceDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/experience/add`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postCompanyEmployeeExperienceDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeeExperienceDetailsAsync, action);
    yield put(postCompanyEmployeeExperienceDetailsSuccess(data));
  } catch (error) {
    yield put(postCompanyEmployeeExperienceDetailsError(error));
  }
}

export function* watchPatchCompanyEmployeeExperienceDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_EXPERINCE_DETAILS, patchCompanyEmployeeExperienceDetailsCall);
}

export function* watchPostCompanyEmployeeExperienceDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_EXPERINCE_DETAILS, postCompanyEmployeeExperienceDetailsCall);
}

// License details
const patchCompanyEmployeeLicenseDetailsAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/license/update/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeLicenseDetailsCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeLicenseDetailsAsync, action);
    yield put(patchCompanyEmployeeLicenseDetailsSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeLicenseDetailsError(error));
  }
}
export function* watchPatchCompanyEmployeeLicenseDetailsData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_LICENSE_DETAILS, patchCompanyEmployeeLicenseDetailsCall);
}

const postCompanyEmployeeLicenseDetailsAsync = async (payload) => {
  try {
    const response = await api.post(`/company/employee/license/add`, payload.payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* postCompanyEmployeeLicenseDetailsCall(action) {
  try {
    const data = yield call(postCompanyEmployeeLicenseDetailsAsync, action);
    yield put(postCompanyEmployeeLicenseDetailsSuccess(data));
  } catch (error) {
    yield put(postCompanyEmployeeLicenseDetailsError(error));
  }
}


export function* watchPostCompanyEmployeeLicenseDetailsData() {
  yield takeEvery(POST_COMPANY_EMPLOYEE_LICENSE_DETAILS, postCompanyEmployeeLicenseDetailsCall);
}


// UPDATE SALARY details
const patchCompanyEmployeeSalaryAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/salary/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeSalaryCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeSalaryAsync, action);
    yield put(patchCompanyEmployeeSalarySuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeSalaryError(error));
  }
}
export function* watchPatchCompanyEmployeeSalaryData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_SALARY, patchCompanyEmployeeSalaryCall);
}

// terminate details
const patchCompanyEmployeeTerminateAsync = async (payload) => {
  try {
    const response = await api.patch(`/company/employee/terminate/${payload.payload.id}`, payload.payload.body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

function* patchCompanyEmployeeTerminateCall(action) {
  try {
    const data = yield call(patchCompanyEmployeeTerminateAsync, action);
    yield put(patchCompanyEmployeeTerminateSuccess(data));
  } catch (error) {
    yield put(patchCompanyEmployeeTerminateError(error));
  }
}
export function* watchPatchCompanyEmployeeTerminateData() {
  yield takeEvery(PATCH_COMPANY_EMPLOYEE_TERMINATION_DETAILS, patchCompanyEmployeeTerminateCall);
}

// Root saga
export default function* rootSaga() {
  yield all([
    fork(watchGetCompanyEmployeeDetailsData),
    fork(watchGetCompanyEmployeeDetailsIdData),
    fork(watchGetCompanyEmployeeDriverData),
    fork(watchGetCompanyEmployeeHelperData),
    fork(watchPostEmployeeFindData),
    fork(watchPostCompanyEmployeeDetailsData),
    fork(watchPatchCompanyEmployeeDetailsData),
    fork(watchDeleteCompanyEmployeeData),
    fork(watchPatchCompanyEmployeePersonalDetailsData),
    fork(watchPostCompanyEmployeePersonalDetailsData),
    fork(watchPatchCompanyEmployeeAttendenceDetailsData),
    fork(watchPostCompanyEmployeeAttendenceDetailsData),
    fork(watchPatchCompanyEmployeeFamilyDetailsData),
    fork(watchPostCompanyEmployeeFamilyDetailsData),
    fork(watchPatchCompanyEmployeeExperienceDetailsData),
    fork(watchPostCompanyEmployeeExperienceDetailsData),
    fork(watchPatchCompanyEmployeeLicenseDetailsData),
    fork(watchPostCompanyEmployeeLicenseDetailsData),
    fork(watchPatchCompanyEmployeeSalaryData),
    fork(watchPatchCompanyEmployeeTerminateData),
  ]);
}
