import { getCompanyTollTaxFilterAction } from "../../../redux/company/tollTax/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import { getCompanyTruckDetailsAction } from "../../../redux/company/truck/action";
import { getCompanyTripDetailsFilterAction } from "../../../redux/company/trip/action";
import DatePicker from "../../datepicker/datePicker";
import TollTaxListCard from "./tollTaxListCard";

function TollTaxList({ data, isSell }) {
  const dispatch = useDispatch();
  const {
    getCompanyTripDetailsFilter,
  } = useSelector((state) => state.CompanyTripsection);
  const { getCompanyTruckDetails, getCompanyTruckDetailsLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [truckParams, setTruckParams] = useState(null);
  const [tripParams, setTripParams] = useState(null);
  const [trip, setTrip] = useState([]);
  const [truck, setTruck] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  useEffect(() => {
      if (!(data?.length > 0)) {
          handleClearFilters();
        }
    }, [dispatch]);
    useEffect(() => {
        dispatch(getCompanyTruckDetailsAction())
    }, []);
    useEffect(() => {
        if (getCompanyTruckDetails?.status === 200) {
          setTruck(getCompanyTruckDetails?.trucks)
        }
      }, [getCompanyTruckDetails]);
    useEffect(() => {
      if (getCompanyTripDetailsFilter?.status === 200) {
        setTrip(getCompanyTripDetailsFilter.data);
      }
    }, [getCompanyTripDetailsFilter]);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(getCompanyTollTaxFilterAction(payload));
  };
  const handleClearFilters = () => {
    const TruckId = searchParams.get("truckId");
    const TripId = searchParams.get("tripId");
    setTruckParams(TruckId);
    setTripParams(TripId)
    const payload = {
      truckId: TruckId,
      tripId: TripId,
    };
    dispatch(getCompanyTollTaxFilterAction(payload));
    dispatch(getCompanyTripDetailsFilterAction(payload));
  };

  const filteredData = data?.tollTax?.filter(
    (item) =>
      item.cost.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.billNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.tollName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)}>
          <FaFilter className="text-gray-600" />
        </button>
      </div>
      {showFilters && (
        <Formik
          initialValues={{
            truckId: truckParams || "",
            tripId: tripParams || "",
            startDate: "",
          }}
          onSubmit={handleFilterSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="grid md:grid-cols-2 gap-4 mb-4 bg-gray-700 p-4 rounded-md shadow-md shadow-red-300">
              <div>
                <label htmlFor="startDate">Date From</label>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  startDate={new Date()}
                  endDate={null}
                  selectsStart
                  placeHolder="Select Date Range"
                />
              </div>
              <div>
                <label htmlFor="endDate">Date To</label>
                <DatePicker
                  name="endDate"
                  id="endDate"
                  startDate={new Date()}
                  endDate={null}
                  selectsEnd
                  minDate={values.startDate}
                  placeHolder="Select Date Range"
                />
              </div>
              <div>
                <label htmlFor="truckId">Truck</label>
                <Field
                  name="truckId"
                  as="select"
                  disabled={truckParams}
                  className="shadow-md"
                >
                  <option value="">Filter by Truck</option>
                  {truck?.map((item) => (
                    <option value={item._id}>{item.truckNumber}</option>
                  ))}
                </Field>
              </div>
              <div>
                <label htmlFor="tripId">Trip</label>
                <Field
                  name="tripId"
                  as="select"
                  disabled={tripParams}
                  className="shadow-md"
                >
                  <option value="">Filter by Trip</option>
                  {trip?.map((item) => (
                    <option value={item._id}> {item.tokenNumber} ({item?.startLocation} -{" "}
                        {item?.endLocation})</option>
                  ))}
                </Field>
              </div>

              <div className="md:col-span-2  flex gap-4 justify-between">
                <button type="submit" className="primaryButton">
                  Apply Filters
                </button>
                <button
                  type="button"
                  className="secondaryButton"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className="grid grid-cols-2">
      <div className="md:text-left">
            <h3 className="textgrey">Total Toll Tax</h3>
            <p className="font-bold">{data?.tollTax?.length}</p>
          </div>
      <div className="md:text-right">
            <h3 className="textgrey">Total Amount</h3>
            <p className="font-bold">Rs.{data?.totalAmount}</p>
          </div>
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <TollTaxListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No TollTax found.</p>
      )}
    </div>
  );
}

export default TollTaxList;
