import React, { useEffect, useState } from "react";
import TripListCard from "./tripListCard";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getCompanyTripDetailsFilterAction } from "../../../redux/company/trip/action";
import { FaFilter } from "react-icons/fa";
import {
  getCompanyEmployeeDriverAction,
  getCompanyEmployeeHelperAction,
} from "../../../redux/company/employee/action";
import { Formik, Form, Field } from "formik";
import DatePicker from "../../datepicker/datePicker";
import { getCompanyTruckDetailsAction } from "../../../redux/company/truck/action";

function TripList({ data }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    getCompanyEmployeeDriver,
    getCompanyEmployeeHelper,
    getCompanyEmployeeHelperActionLoading,
    getCompanyEmployeeDriverLoading,
  } = useSelector((state) => state.CompanyEmployeesection);
  const { getCompanyTruckDetails, getCompanyTruckDetailsLoading } = useSelector(
    (state) => state.Companytrucksection
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [truckParams, setTruckParams] = useState(null);
  const [truck, setTruck] = useState([]);
  const [helpers, setHelpers] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const truckId = searchParams.get("truckId");
    const ClientID = searchParams.get("clientId")
    const payload = {
      truckId: truckId,
      clientId: ClientID
    };
    setTruckParams(truckId)
    if (!(data?.length > 0)) {
      dispatch(getCompanyTripDetailsFilterAction(payload));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCompanyEmployeeDriverAction());
    dispatch(getCompanyEmployeeHelperAction());
    dispatch(getCompanyTruckDetailsAction())
  }, []);
  useEffect(() => {
    if (getCompanyTruckDetails?.status === 200) {
      setTruck(getCompanyTruckDetails?.trucks)
    }
  }, [getCompanyTruckDetails]);
  useEffect(() => {
    // Filter drivers and helpers based on the data list
    if (data && getCompanyEmployeeDriver?.status === 200) {
      const driverIds = data.map((item) => item.driverId?._id);
      const helperIds = data.map((item) => item.helperId?._id);

      const filteredDrivers = getCompanyEmployeeDriver?.data?.filter((driver) =>
        driverIds.includes(driver._id)
      );
      const filteredHelpers = getCompanyEmployeeHelper?.data?.filter((helper) =>
        helperIds.includes(helper._id)
      );

      setDrivers(filteredDrivers);
      setHelpers(filteredHelpers);
    }
  }, [data, getCompanyEmployeeDriver, getCompanyEmployeeHelper]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterSubmit = (values) => {
    const payload = {
      ...Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== "")
      ), // Only send filled filters
    };
    dispatch(getCompanyTripDetailsFilterAction(payload));
  };

  const handleClearFilters = () => {
    const id = searchParams.get("truckId");
    const payload = {
      truckId: id,
    };
    dispatch(getCompanyTripDetailsFilterAction(payload));
  };

  const filteredData = data?.filter((item) =>
    item.tokenNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.productType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="mb-4 gap-8 flex justify-between items-center">
        <input
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md mb-2"
        />
        <button onClick={() => setShowFilters(!showFilters)}>
          <FaFilter className="text-gray-600" />
        </button>
      </div>
      {showFilters && (
        <Formik
          initialValues={{
            startLocation: "",
            endLocation: "",
            truckId: truckParams || "",
            status: "",
            driverId: "",
            helperId: "",
            startDate: "",
          }}
          onSubmit={handleFilterSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="grid md:grid-cols-2 gap-4 mb-4 bg-gray-700 p-4 rounded-md shadow-md shadow-red-300">
              <div>
                <label htmlFor="startLocation">Start Location</label>
                <Field
                  name="startLocation"
                  id="startLocation"
                  placeholder="Filter by Start Location"
                  className="shadow-md"
                />
              </div>
              <div>
                <label htmlFor="endLocation">End Location</label>
                <Field
                  name="endLocation"
                  id="endLocation"
                  placeholder="Filter by End Location"
                  className="shadow-md"
                />
              </div>
              {/* {!truckParams && */}
                <div>
                <label htmlFor="endLocation">Truck</label>
                <Field
                name="truckId"
                as="select"
                disabled={truckParams}
                className="shadow-md"
                >
                <option value="">Filter by Truck</option>
                {truck?.map((item)=>
                <option value={item._id}>{item.truckNumber}</option>
                )}
                
                </Field>
                </div>
                {/* } */}
              <div>
                <label htmlFor="driverId">Driver</label>
                <Field
                  name="driverId"
                  id="driverId"
                  as="select"
                  className="shadow-md"
                >
                  <option value="">Filter by Driver</option>
                  {drivers.map((driver) => (
                    <option key={driver._id} value={driver._id}>
                      {driver.employeeDetails.firstName}{" "}
                      {driver.employeeDetails.lastName}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                <label htmlFor="helperId">Helper</label>
                <Field
                  name="helperId"
                  id="helperId"
                  as="select"
                  className="shadow-md"
                >
                  <option value="">Filter by Helper</option>
                  {helpers?.map((helper) => (
                    <option key={helper._id} value={helper._id}>
                      {helper.employeeDetails.firstName}{" "}
                      {helper.employeeDetails.lastName}
                    </option>
                  ))}
                </Field>
              </div>
              <div>
                
              <div>
                <label htmlFor="startDate">Date From</label>
                <DatePicker
                  name="startDate"
                  id="startDate"
                  startDate={new Date()}
                  endDate={null}
                  selectsStart
                  placeHolder="Select Date Range"
                />
              </div>
              </div>
              <div>
                <label htmlFor="endDate">Date To</label>
                <DatePicker
                  name="endDate"
                  id="endDate"

                  startDate={new Date()}
                  endDate={null}
                  selectsEnd
                  minDate={values.startDate}
                  placeHolder="Select Date Range"
                />
              </div>
              <div className="md:col-span-2  flex gap-4 justify-between">
                <button type="submit" className="primaryButton">
                  Apply Filters
                </button>
                <button
                  type="button"
                  className="secondaryButton"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {filteredData.length > 0 ? (
        filteredData.map((item) => (
          <TripListCard key={item._id} data={item} reroute={true} />
        ))
      ) : (
        <p>No Trips found.</p>
      )}
    </div>
  );
}

export default TripList;
