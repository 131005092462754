import { Dialog } from "../../../../ui/dialog";
import moment from "moment";
import React, { useState } from "react";
import ImagePopUpDownload from "../../../../components/imagePopUpDownload";
import Image from "../../../../assets/images/bill.png";
import EmiDelete from "./truckEmiDelete";

function EmiCard({ data, emiDelete }) {
  const [showEmiDeleteModal, setShowEmiDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div
      key={data._id}
      className="my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl"
    >
      <div className="grid grid-cols-12 gap-x-7">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-4">
          <button
            type="button"
            className={`  ${
              data?.emiTransationImage === "" ? "bg-gray-400 " : ""
            } rounded-full`}
            onClick={() => setShowImageModal(true)}
          >
            <img
              src={
                data?.emiTransationImage === ""
                  ? Image
                  : data?.emiTransationImage
              }
              alt=""
              className={`min-h-[100px] max-h-[200px] min-w-[100px] max-w-[200px] ${
                data?.emiTransationImage === "" ? "" : ""
              }`}
            />
          </button>
        </div>
        {data?.emiTransationImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.emiTransationImage}
              setShowModal={setShowImageModal}
              imagename={`Emi_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <p className="text-blue-700 font-semibold text-xl uppercase">
            Emi for {moment(data.emiPayMonth).format("MMMM YYYY")}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
            <div className="md:text-left">
              <h3 className="textgrey text-xs">Total Pay</h3>
              <p>₹{data.totalAmountPay}</p>
            </div>

            <div>
              <h3 className="textgrey text-xs md:text-center">Emi Amount</h3>
              <p className="md:text-center">₹{data?.emiAmount}</p>
            </div>

            <div>
              <h3 className="textgrey text-xs md:text-center">Add-Ons</h3>
              <p className="md:text-center">₹{data.additionCharges}</p>
            </div>
            <div>
              <h3 className="textgrey text-xs md:text-centre">
                Addition Charges Reason
              </h3>
              <p className="md:text-center">
                <div className="">{data.additionalChargesReason}</div>
              </p>
            </div>
            <div>
              <h3 className="textgrey text-xs md:text-left">Payment Type</h3>
              <p className="md:text-left">
                <div className="">{data.paymentType}</div>
              </p>
            </div>
            <div>
              <h3 className="textgrey text-xs md:text-center">
                Emi Amount Left
              </h3>
              <p className="md:text-center">{data?.emiAmountLeft}</p>
            </div>

            <div className="col-span-2">
              <h3 className="textgrey text-xs md:text-center ">Description</h3>
              <p className="md:text-center">{data?.description}</p>
            </div>
            {emiDelete === data.emiPayMonth && (
              <div className="flex">
                <Dialog open={showEmiDeleteModal}>
                  <button
                    onClick={() => setShowEmiDeleteModal(true)}
                    className="primaryButton bg-red-600 hover:bg-red-800"
                  >
                    Delete Emi
                  </button>
                  <EmiDelete id={data?._id} setShowModal={setShowEmiDeleteModal} />
                </Dialog>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmiCard;
