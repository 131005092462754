import React, { useEffect, useState } from 'react'
import TripListCard from './tripListCard'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../ui/tabs'
import { PiTruck } from 'react-icons/pi'
import { FaRoute } from 'react-icons/fa'
import { BsFuelPumpFill } from 'react-icons/bs'
import { TbCheckupList } from 'react-icons/tb'
import { CiReceipt } from 'react-icons/ci'
import TruckTabDetails from '../Truck/truckTabDetails'
import {  getCompanyTruckDetailsIdAction } from '../../../redux/company/truck/action'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyTripDetailsIdAction } from '../../../redux/company/trip/action'
import { useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import FuelList from '../fuel/fuelList'
import Backdrop from '../../backdrop'
import TripTabDetails from './tripTabDetails'
import TollTaxList from '../tollTax/tollTaxList'
import ExtraExpenseTripList from './extraExpense/extraExpenseTripList'

function TripDetails() {
    const dispatch = useDispatch()
    let [searchParams, setSearchParams] = useSearchParams();
    const {
        getCompanyTruckDetailsId,
        getCompanyTruckDetailsIdLoading,
        getCompanyTruckDetailsIdError,
      } = useSelector((state) => state.Companytrucksection);
      const {
        getCompanyTripDetailsId,
        getCompanyTripDetailsIdLoading,
        getCompanyTripDetailsIdError,
      } = useSelector((state) => state.CompanyTripsection);
      const {
        getExtraExpenseTripFilter,
        getExtraExpenseTripFilterLoading,
        getExtraExpenseTripFilterError,
      } = useSelector((state) => state.ExpenseTripSection);
      const { getCompanyFuelFilter, getCompanyFuelFilterLoading, getCompanyFuelFilterError } = useSelector(
        (state) => state.Fuelsection
      );
      const { getCompanyTollTaxFilter, getCompanyTollTaxFilterLoading, getCompanyTollTaxFilterError } = useSelector(
        (state) => state.TollTaxsection
      );

      const [data, setData] = useState([]);
      const [truckData, setTruckData] = useState([]);
      const [fuelData, setFuelData] = useState([]);
      const [tollTaxData, setTollTaxData] = useState([]);
      const [extraExpense, setExtraExpense] = useState([]);


    useEffect(() => {
        const id = searchParams.get("tripId");
        const truckid = searchParams.get("truckId");
          dispatch(getCompanyTripDetailsIdAction(id));
          dispatch(getCompanyTruckDetailsIdAction(truckid));

      }, [dispatch]);
      useEffect(() => {
        if (getCompanyTruckDetailsId?.status === 200) {
            setTruckData(getCompanyTruckDetailsId.truck);
        }
      }, [getCompanyTruckDetailsId]);
      useEffect(() => {
        if (getCompanyTruckDetailsIdError?.status === 404) {
          toast.error(getCompanyTruckDetailsIdError.message, {
            id: "truck-toast",
          });
        } else if (getCompanyTruckDetailsIdError?.status === 400) {
          toast.error(getCompanyTruckDetailsIdError.message, {
            id: "truck-toast",
          });
        } else if (getCompanyTruckDetailsIdError?.status === 500) {
          toast.error(getCompanyTruckDetailsIdError.error, {
            id: "truck-toast",
          });
        }
      }, [getCompanyTruckDetailsIdError]);
      useEffect(() => {
        if (getCompanyTripDetailsId?.status === 200) {
          setData(getCompanyTripDetailsId.data);
        }
      }, [getCompanyTripDetailsId]);
      useEffect(() => {
        if (getCompanyTripDetailsIdError?.status === 404) {
          toast.error(getCompanyTripDetailsIdError.message, {
            id: "trip-toast",
          });
        } else if (getCompanyTripDetailsIdError?.status === 400) {
          toast.error(getCompanyTripDetailsIdError.message, {
            id: "trip-toast",
          });
        } else if (getCompanyTripDetailsIdError?.status === 500) {
          toast.error(getCompanyTripDetailsIdError.error, {
            id: "trip-toast",
          });
        }
      }, [getCompanyTripDetailsIdError]);
      useEffect(() => {
        if (getCompanyFuelFilter?.status === 200) {
          setFuelData(getCompanyFuelFilter.data);
        }
      }, [getCompanyFuelFilter]);
      useEffect(() => {
        if (getCompanyFuelFilterError?.status === 404) {
          toast.error(getCompanyFuelFilterError.message, {
            id: "fuel-toast",
          });
        } else if (getCompanyFuelFilterError?.status === 400) {
          toast.error(getCompanyFuelFilterError.message, {
            id: "fuel-toast",
          });
        } else if (getCompanyFuelFilterError?.status === 500) {
          toast.error(getCompanyFuelFilterError.error, {
            id: "fuel-toast",
          });
        }
      }, [getCompanyFuelFilterError]);
      useEffect(() => {
        if (getCompanyTollTaxFilter?.status === 200) {
          setTollTaxData(getCompanyTollTaxFilter.data);
        }
      }, [getCompanyTollTaxFilter]);
      useEffect(() => {
        if (getCompanyTollTaxFilterError?.status === 404) {
          toast.error(getCompanyTollTaxFilterError.message, {
            id: "toll-toast",
          });
        } else if (getCompanyTollTaxFilterError?.status === 400) {
          toast.error(getCompanyTollTaxFilterError.message, {
            id: "toll-toast",
          });
        } else if (getCompanyTollTaxFilterError?.status === 500) {
          toast.error(getCompanyTollTaxFilterError.error, {
            id: "toll-toast",
          });
        }
      }, [getCompanyTollTaxFilterError]);
      useEffect(() => {
        if (getExtraExpenseTripFilter?.status === 200) {
          setExtraExpense(getExtraExpenseTripFilter.data);
        }
      }, [getExtraExpenseTripFilter]);
      useEffect(() => {
        if (getExtraExpenseTripFilterError?.status === 404) {
          toast.error(getExtraExpenseTripFilterError.message, {
            id: "service-toast",
          });
        } else if (getExtraExpenseTripFilterError?.status === 400) {
          toast.error(getExtraExpenseTripFilterError.message, {
            id: "service-toast",
          });
        } else if (getExtraExpenseTripFilterError?.status === 500) {
          toast.error(getExtraExpenseTripFilterError.error, {
            id: "service-toast",
          });
        }
      }, [getExtraExpenseTripFilterError]);
console.log(getExtraExpenseTripFilter)
  return (
    <div>
    {(getCompanyTruckDetailsIdLoading || getCompanyTripDetailsIdLoading || getExtraExpenseTripFilterLoading || getCompanyFuelFilterLoading||getCompanyTollTaxFilterLoading) && <Backdrop />}
    <TripListCard data={data}/>
    <Tabs defaultValue="Trips" color="primary" className="min-w-[400px]">
      <TabsList>
        <TabsTrigger value="Trips">
          <FaRoute className="md:mr-1 mr-0.5 md:text-xl " /> Trips
        </TabsTrigger>
        <TabsTrigger value="Truck">
          <PiTruck className="md:mr-1 mr-0.5 md:text-xl " /> Truck
        </TabsTrigger>
        <TabsTrigger value="Fuel">
          <BsFuelPumpFill className="md:mr-1 mr-0.5 md:text-xl " /> Fuel
        </TabsTrigger>
        <TabsTrigger value="Toll">
          <CiReceipt className="md:mr-1 mr-0.5 md:text-xl " /> Toll Tax
        </TabsTrigger>
        <TabsTrigger value="Extra Expences">
          <TbCheckupList className="md:mr-1 mr-0.5 md:text-xl " /> Other Expences
        </TabsTrigger>
      </TabsList>
      <TabsContent value="Trips">
        <TripTabDetails data={data}/>
        </TabsContent>
      <TabsContent value="Truck" className="w-full">
        <TruckTabDetails data={truckData} />
      </TabsContent>
      <TabsContent value="Fuel">
        <FuelList data={fuelData}/>
        </TabsContent>
      <TabsContent value="Toll">
        <TollTaxList data={tollTaxData}/>
        </TabsContent>
      <TabsContent value="Extra Expences">
        <ExtraExpenseTripList data={extraExpense}/>
      </TabsContent>
    </Tabs>
  </div>
  )
}

export default TripDetails
