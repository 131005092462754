import { MdDeleteForever } from "react-icons/md";
import { Input } from "../../ui/input";
import React, { useEffect, useState } from "react";
import api from "../../auth/api";
import Backdrop from "../backdrop";

function FileUpload({ setFieldValue, name, values, disabled, ...rest }) {
  const maxSize = 10 * 1024 * 1024;
  const [fileName, setFileName] = useState(null);
  const [publicId, setPublicId] = useState(null); // Store public_id
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setFileName(values[name]?.url || null); // Use secure_url (image URL) if available
    setPublicId(values[name]?.public_id || null); // Use public_id if available
  }, [values[name]]); // Update when values change

  const handleFileChange = (files) => {
    const selectedFile = files?.[0];
    if (selectedFile) {
      validateAndSetFile(selectedFile);
    }
  };

  const validateAndSetFile = async (file) => {
    if (file.size > maxSize) {
      setError(`File size should not exceed ${maxSize / (1024 * 1024)}MB`);
      setFileName(null);
      setFieldValue(name, null);
    } else {
      setError(null);
      const formData = new FormData();
      formData.append("image", file);
      setLoader(true);
      try {
        const res = await api.post("/upload", formData);
        if (res?.data?.status === 200) {
          setLoader(false);
          setFieldValue(name, {
            url: res.data.data.secure_url, // Save secure URL to form
            public_id: res.data.data.public_id, // Save public_id to form
          });
          setFileName(res.data.data.url); // Set image URL
          setPublicId(res.data.data.public_id); // Set public_id
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setLoader(false);
        setError("Error uploading file. Please try again.");
        setFileName(null);
        setFieldValue(name, null);
      }
    }
  };

  const deleteFile = async () => {
    try {
      if (publicId) {
        setLoader(true);

        const res = await api.delete("/remove", {
          data: { public_id: publicId },
        }); // Delete by public_id
        if (res?.data?.status === 200) {
          setLoader(false);
          setFileName(null);
          setPublicId(null);
          setFieldValue(name, null); // Reset form field
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
      console.error("Error deleting file:", err);
      setError("Error deleting file. Please try again.");
    }
  };

  return (
    <div>
      {loader && <Backdrop />}
      {fileName ? (
        <div className="flex gap-10 mt-4  rounded-md items-center relative w-fit">
          <img src={fileName} alt="Uploaded File" className="w-20 h-auto" />
          <button
            type="button"
            onClick={deleteFile}
            className="w-fit absolute -top-2 -right-2 p-1 bg-white rounded-full"
            disabled={disabled}
          >
            <MdDeleteForever className="cursor-pointer text-xl text-red-500" />
          </button>
        </div>
      ) : (
        <>
          <label htmlFor={name} className="flex flex-col ">
            <div className="flex flex-col items-center bg-white font-bold text-black gap-6 rounded-xl border-2 border-dashed border-[#dce0e5] px-6 py-14">
              Upload Image
            </div>
          </label>
          <Input
            id={name}
            type="file"
            className="sr-only w-fit"
            disabled={disabled}
            {...rest}
            onChange={(e) => handleFileChange(e.target.files)}
          />
          {error && (
            <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
          )}
        </>
      )}
    </div>
  );
}

export default FileUpload;
