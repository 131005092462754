import {
  getAdminEmployeeByEmployeeIdAction,
  postAdminEmployeeAction,
} from "../../../redux/admin/employee/action";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FileUpload from "../../fileupload/fileUpload";
import { ErrorMessage, Field, Form, Formik } from "formik";
import DatePicker from "../../datepicker/datePicker";
import { getCityAction, getStateAction } from "../../../redux/address/action";
import { getCompanySearchAction } from "../../../redux/company/action";

const bloodGroup = ["O-", "O+", "A-", "A+", "B-", "B+", "AB-", "AB+"];
const today = new Date();
const maxDate = new Date(today.setFullYear(today.getFullYear() - 16));

function EmployeeAddToCompany() {
  const { employeeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [licenseData, setLicenseData] = useState();
  const [companyError, setCompanyError] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const {
    getAdminEmployeeByEmployeeId,
    getAdminEmployeeByEmployeeIdLoading,
    getAdminEmployeeByEmployeeIdError,
    postAdminEmployee,
    postAdminEmployeeLoading,
    postAdminEmployeeError,
  } = useSelector((state) => state.AdminEmployeeSection);
  const { getCompanySearch, getCompanySearchLoading, getCompanySearchError } =
    useSelector((state) => state.Companysection);
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );

  useEffect(() => {
    dispatch(getStateAction());
    dispatch(getAdminEmployeeByEmployeeIdAction(employeeId));
  }, []);
  useEffect(() => {
    if (getAdminEmployeeByEmployeeId?.status === 200) {
      setData(getAdminEmployeeByEmployeeId?.data);
      const cityList = getAdminEmployeeByEmployeeId?.data?.employee?.state;
      handleCity(cityList);
      setLicenseData(getAdminEmployeeByEmployeeId.data.licence);
    }
  }, [getAdminEmployeeByEmployeeId]);
  useEffect(() => {
    if (
      getAdminEmployeeByEmployeeIdError?.status === 400 ||
      getAdminEmployeeByEmployeeIdError?.status === 404
    ) {
      toast.error(getAdminEmployeeByEmployeeIdError.message);
    } else if (getAdminEmployeeByEmployeeIdError?.status === 500) {
      toast.error(getAdminEmployeeByEmployeeIdError?.error);
    }
  }, [getAdminEmployeeByEmployeeIdError]);

  useEffect(() => {
    if (getState?.status === 200) {
      setState(getState.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getState]);

  useEffect(() => {
    if (getCity?.status === 200) {
      setCity(getCity?.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getCity]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearch.status === 200) {
        setLoading(false);
        setCompanyName(getCompanySearch?.data?.companyName);
      }
    }
  }, [loading, getCompanySearch]);
  useEffect(() => {
    if (loading && !getCompanySearchLoading) {
      if (getCompanySearchError.status === 400) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 404) {
        setLoading(false);
        setCompanyError(getCompanySearchError?.message);
      } else if (getCompanySearchError.status === 500) {
        setLoading(false);
        setCompanyError("Company Not Found");
      }
    }
  }, [loading, getCompanySearchError]);
  const initialValues = {
    companyId: "",
    userId: data?.employee?.userId,
    phoneNumber: data?.employee?.phoneNumber || null,
    address: data?.employee?.address || "",
    bloodGroup: data?.employee?.bloodGroup || "",
    city: data?.employee?.city || "",
    employeeImage: data?.employee?.employeeImage || "",
    country: data?.employee?.country || "India",
    firstName: data?.employee?.firstName || "",
    gender: data?.employee?.gender || "",
    lastName: data?.employee?.lastName || "",
    state: data?.employee?.state || "",
    zipCode: data?.employee?.zipCode || null,
    alternateMobileNumber: data?.employee?.alternateMobileNumber || null,
    dateOfBirth: data?.employee?.dateOfBirth
      ? new Date(data?.employee?.dateOfBirth)
      : "",
    designation: data?.companyEmployees?.designation || "",
    employeeType: data?.companyEmployees?.employeeType || "Full Time",
    joiningDate: data?.companyEmployees?.joiningDate
      ? new Date(data?.companyEmployees?.joiningDate)
      : "",
    salary: data?.companyEmployees?.salary || null,
    licenseNumber: licenseData?.licenseNumber || "",
    expiryDate: licenseData?.expiryDate
      ? new Date(licenseData?.expiryDate)
      : "",
    licenseImageUrl: licenseData?.licenseImageUrl || "",
  };
  const validationSchema = Yup.object({
    companyId: Yup.string().required("This Field is Required"),
    phoneNumber: Yup.number().required("This Field is Required"),
    address: Yup.string().required("This Field is Required"),
    bloodGroup: Yup.string().required("This Field is Required"),
    city: Yup.string().required("This Field is Required"),
    country: Yup.string().required("This Field is Required"),
    firstName: Yup.string().required("This Field is Required"),
    gender: Yup.string().required("This Field is Required"),
    lastName: Yup.string().required("This Field is Required"),
    state: Yup.string().required("This Field is Required"),
    zipCode: Yup.string().required("This Field is Required"),
    alternateMobileNumber: Yup.number().nullable(),
    dateOfBirth: Yup.date(),
    designation: Yup.string().required("This Field is Required"),
    employeeType: Yup.string().required("This Field is Required"),
    joiningDate: Yup.date(),
    salary: Yup.number().required("This Field is Required"),
    employeeImage: Yup.string(),
  });
  console.log(postAdminEmployee);

  useEffect(() => {
    if (loader) {
      if (postAdminEmployee.status === 200) {
        toast.success(postAdminEmployee.message, {
          id: "postDetails",
        });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [postAdminEmployee]);
  useEffect(() => {
    if (loader) {
      if (postAdminEmployeeError.status === 400) {
        toast.error(postAdminEmployeeError.message, {
          id: "postDetails",
        });
        setLoader(false);
      } else if (postAdminEmployeeError.status === 404) {
        toast.error(postAdminEmployeeError.message, {
          id: "postDetails",
        });
        setLoader(false);
      } else if (postAdminEmployeeError.status === 500) {
        toast.error(postAdminEmployeeError.error, {
          id: "postDetails",
        });
        setLoader(false);
      }
    }
  }, [postAdminEmployeeError]);

  const handleCity = (id) => {
    dispatch(getCityAction(id));
  };
  const handleCompany = (value) => {
    if (value?.length === 24) {
      dispatch(getCompanySearchAction(value));
      setLoading(true);
    } else {
      setCompanyError(null);
      setCompanyName(null);
    }
  };

  const handleSubmit = (values) => {
    dispatch(postAdminEmployeeAction(values));
    toast.loading("Employee details Saving....", {
      id: "postDetails",
    });
    setLoader(true);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add Employee Personal Details</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              <div>
                <label htmlFor="companyId">
                  Company Id <span>*</span>
                </label>
                <Field
                  type="text"
                  id="companyId"
                  name="companyId"
                  maxlength={24}
                  className=""
                  onChange={(e) => {
                    setFieldValue("companyId", e.target.value);
                    handleCompany(e.target.value);
                  }}
                  placeholder="Company ID"
                />
                <ErrorMessage
                  name="companyId"
                  component="div"
                  className="error"
                />
                {companyError && <div className="error">{companyError}</div>}
                {companyName && (
                  <div className="flex text-sm gap-1">
                    Company Name is
                    <div className="text-green-600 text-sm uppercase font-bold">
                      {" "}
                      {companyName}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label htmlFor="firstName">
                  First Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="lastName">
                  Last Name <span>*</span>
                </label>
                <Field
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  name="lastName"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="gender">
                  Gender <span>*</span>
                </label>
                <Field
                  as="select"
                  id="gender"
                  name="gender"
                  placeholder="Gender"
                  className="w-full px-4 py-2 border rounded-lg"
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Field>
                <ErrorMessage
                  name="employeeType"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <DatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  placeHolder="Date Of Birth"
                  maxDate={maxDate}
                />
                <ErrorMessage
                  name="dateOfBirth"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="phoneNumber">
                  Phone Number <span>*</span>
                </label>
                <Field
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="+91 98765*****"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="alternateMobileNumber">
                  Alternate Phone Number <span>*</span>
                </label>
                <Field
                  type="number"
                  id="alternateMobileNumber"
                  name="alternateMobileNumber"
                  placeholder="+91 98765*****"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="alternateMobileNumber"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="address">
                  Address <span>*</span>
                </label>
                <Field
                  type="text"
                  id="address"
                  placeholder="123 Street"
                  name="address"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="country">
                  Country <span>*</span>
                </label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  disabled={true}
                  className=""
                  placeholder="Country"
                  readOnly
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="state">
                  State <span>*</span>
                </label>
                <Field
                  as="select"
                  id="state"
                  name="state"
                  className=""
                  onChange={(e) => {
                    setFieldValue("state", e.target.value);
                    handleCity(e.target.value);
                  }}
                  placeholder="State"
                >
                  <option value="">State</option>
                  {state?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage name="state" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="city">
                  City <span>*</span>
                </label>
                <Field
                  as="select"
                  id="city"
                  name="city"
                  className=""
                  placeholder="City"
                >
                  <option value="">City</option>
                  {city?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage name="city" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="zipCode">
                  Zip Code <span>*</span>
                </label>
                <Field
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  placeholder="123456"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage
                  name="zipCode"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="bloodGroup">
                  Blood Group <span>*</span>
                </label>
                <Field
                  as="select"
                  id="bloodGroup"
                  name="bloodGroup"
                  className="w-full px-4 py-2 border rounded-lg"
                >
                  <option value="">Blood Group</option>
                  {bloodGroup.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Field>
                <ErrorMessage
                  name="bloodGroup"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="designation">
                  Designation <span>*</span>
                </label>
                <Field
                  type="text"
                  id="designation"
                  name="designation"
                  placeholder="Designation"
                  list="desig"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <datalist id="desig">
                  <option>Driver</option>
                  <option>Helper</option>
                  <option>Munsi</option>
                </datalist>
                <ErrorMessage
                  name="designation"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="employeeType">
                  Employee Type <span>*</span>
                </label>
                <Field
                  as="select"
                  id="employeeType"
                  name="employeeType"
                  className="w-full px-4 py-2 border rounded-lg"
                >
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                  <option value="Contract">Contract</option>
                </Field>
                <ErrorMessage
                  name="employeeType"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="joiningDate">
                  Joining Date <span>*</span>
                </label>
                <DatePicker
                  id="joiningDate"
                  name="joiningDate"
                  placeHolder="Joining Date"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="joiningDate"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="salary">
                  Salary <span>*</span>
                </label>
                <Field
                  type="number"
                  id="salary"
                  name="salary"
                  placeholder="salary"
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <ErrorMessage name="salary" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="employeeImage">Employee Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  disabled={data}
                  name="employeeImage"
                  values={values}
                />
              </div>
              {values.designation.toLowerCase() === "driver" && (
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:col-span-3 md:col-span-2 col-span-1">
                  <div className="lg:col-span-3 md:col-span-2 col-span-1">
                    <div className="text-xl font-semibold">License Details</div>
                  </div>
                  <div>
                    <label htmlFor="licenseNumber">
                      License Number <span>*</span>
                    </label>
                    <Field
                      type="text"
                      disabled={licenseData}
                      id="licenseNumber"
                      name="licenseNumber"
                      className="w-full px-4 py-2 border rounded-lg"
                    />
                    <ErrorMessage
                      name="licenseNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <label htmlFor="expiryDate">
                      License Expiry Date <span>*</span>
                    </label>
                    <DatePicker
                      id="expiryDate"
                      name="expiryDate"
                      placeHolder="License Expiry Date"
                      minDate={new Date()}
                    />
                    <ErrorMessage
                      name="joiningDate"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <label htmlFor="licenseImageUrl">License Image</label>
                    <FileUpload
                      setFieldValue={setFieldValue}
                      name="licenseImageUrl"
                      values={values}
                    />
                  </div>
                </div>
              )}

              <button type="submit" className="primaryButton mt-auto h-fit">
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EmployeeAddToCompany;
