import { getCompanyTruckDetailsAction } from "../../../redux/company/truck/action";
import {
  getCompanyTripDetailsAction,
  getCompanyTripDetailsIdAction,
} from "../../../redux/company/trip/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import Backdrop from "../../backdrop";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FileUpload from "../../fileupload/fileUpload";
import DatePicker from "../../datepicker/datePicker";
import {
  getCompanyFuelIdAction,
  patchCompanyFuelAction,
  postCompanyFuelAction,
} from "../../../redux/company/fuel/action";

function AddFuel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    getCompanyTripDetails,
    getCompanyTripDetailsLoading,
    getCompanyTripDetailsError,
    getCompanyTripDetailsId,
    getCompanyTripDetailsIdLoading,
    getCompanyTripDetailsIdError,
  } = useSelector((state) => state.CompanyTripsection);
  const { getCompanyTruckDetails, getCompanyTruckDetailsLoading } = useSelector(
    (state) => state.Companytrucksection
  );
  const {
    postCompanyFuel,
    postCompanyFuelLoading,
    postCompanyFuelError,
    patchCompanyFuel,
    patchCompanyFuelLoading,
    patchCompanyFuelError,
    getCompanyFuelId,
    getCompanyFuelIdLoading,
    getCompanyFuelIdError,
  } = useSelector((state) => state.Fuelsection);
  const [tripData, setTripData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [data, setData] = useState(null);
  const [edit, setEdit] = useState(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const tripId = searchParams.get("tripId");
    if (tripId !== null) {
      dispatch(getCompanyTripDetailsIdAction(tripId));
    }
    
  }, [searchParams]);
  useEffect(() => {
    const id = searchParams.get("id");
    if (id !== null) {
      dispatch(getCompanyFuelIdAction(id));
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(getCompanyTripDetailsAction());
    dispatch(getCompanyTruckDetailsAction());
  }, []);

  useEffect(() => {
    if (getCompanyTripDetails?.status === 200) {
      setTripData(getCompanyTripDetails.data);
    }
  }, [getCompanyTripDetails]);
  useEffect(() => {
    if (getCompanyTruckDetails?.status === 200) {
      const truckFilter = getCompanyTruckDetails?.trucks?.filter((item)=> item.isSell === false) 
      setTruckData(truckFilter);
      const truckId = searchParams.get("truckId");
    if (truckId !== null) {
      setData({truckId: {
        _id: truckId
      }});
    }
    }
  }, [getCompanyTruckDetails]);
  useEffect(() => {
    const edit = searchParams.get("tripId") === null;

    if (!edit) {
      if (getCompanyTripDetailsId?.status === 200) {
        if (getCompanyTripDetailsId?.data?._id !== null) {
          setData(getCompanyTripDetailsId?.data);
        }
      }
    }
  }, [getCompanyTripDetailsId, getCompanyTripDetailsIdError]);
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getCompanyFuelId?.status === 200) {
        if (getCompanyFuelId?.data?._id !== null) {
          setEdit(getCompanyFuelId?.data);
        }
      }
      if (getCompanyFuelIdError === 400) {
        toast.error(getCompanyFuelIdError?.message);
      } else if (getCompanyFuelIdError === 404) {
        toast.error(getCompanyFuelIdError.message);
      } else if (getCompanyFuelIdError === 500) {
        toast.error(getCompanyFuelIdError.error);
      }
    }
  }, [getCompanyFuelId, getCompanyFuelIdError]);

  useEffect(() => {
    if (loader) {
      if (postCompanyFuel?.status === 200) {
        toast.success(postCompanyFuel?.message, { id: "postFuel" });
        setLoader(false);
        navigate(-1);
      }
      
    }
  }, [postCompanyFuel]);
  useEffect(() => {
    if (loader) {
      console.log(postCompanyFuelError)
      if (postCompanyFuelError?.status === 400) {
        toast.error(postCompanyFuelError?.message, { id: "postFuel" });
        setLoader(false);
      } else if (postCompanyFuelError?.status === 404) {
        toast.error(postCompanyFuelError.message, { id: "postFuel" });
        setLoader(false);
      } else if (postCompanyFuelError?.status === 500) {
        toast.error(postCompanyFuelError.error, { id: "postFuel" });
        setLoader(false);
      }
      
    }
  }, [ postCompanyFuelError]);
  useEffect(() => {
    if (loader) {
      if (patchCompanyFuel?.status === 200) {
        toast.success(patchCompanyFuel?.message, { id: "postFuel" });
        setLoader(false);
        navigate(-1);
      }
     
    }
  }, [patchCompanyFuel]);
  useEffect(() => {
    if (loader) {
      if (patchCompanyFuelError?.status === 400) {
        toast.error(patchCompanyFuelError?.message, { id: "postFuel" });
        setLoader(false);
      } else if (patchCompanyFuelError?.status === 404) {
        toast.error(patchCompanyFuelError.message, { id: "postFuel" });
        setLoader(false);
      } else if (patchCompanyFuelError?.status === 500) {
        toast.error(patchCompanyFuelError.error, { id: "postFuel" });
        setLoader(false);
      }
    }
  }, [ patchCompanyFuelError]);

  const initialValues = {
    truckId: data?.truckId?._id || edit?.truckId || "",
    tripId: data?._id || edit?.tripId || "",
    pumpName: edit?.pumpName || "",
    cost: edit?.cost || "",
    quantity: edit?.quantity || "",
    fuelFillDate: edit?.fuelFillDate ? new Date(edit?.fuelFillDate) : "",
    description: edit?.description || "",
    billImage: edit?.billImage || "",
    billNumber: edit?.billNumber || "",
    perLiterCost: edit?.perLiterCost || null,
  };
  const validationSchema = Yup.object().shape({
    truckId: Yup.string().required("Truck is required"),
    tripId: Yup.string(),
    pumpName: Yup.string().required("Pump Name is required"),
    cost: Yup.number()
      .required("Total is required")
      .positive("Total must be a positive number"),
    quantity: Yup.number()
      .required("Liter is required")
      .positive("Liter must be a positive number"),
    fuelFillDate: Yup.date().required("Fuel Fill Date is required"),
    description: Yup.string(),
    billImage: Yup.string(),
    billNumber: Yup.string(),
    perLiterCost: Yup.number()
      .positive("Per Liter Rate must be a positive number")
      .nullable(),
  });

  const onsubmit = (values) => {
    if (values.quantity && values.cost) {
      values.perLiterCost = values.cost / values.quantity;
    }
    dispatch(postCompanyFuelAction(values));
    setLoader(true);
    toast.loading("Saving Fuel Details...", { id: "postFuel" });
  };
  const onUpdate = (values) => {
    if (values.quantity && values.cost) {
      values.perLiterCost = values.cost / values.quantity;
    }
    const payload = {
      id: edit._id,
      body: values,
    };
    dispatch(patchCompanyFuelAction(payload));
    toast.loading("Update Fuel Details...", { id: "postFuel" });
    setLoader(true);
  };

  return (
    <div>
      {(getCompanyTruckDetailsLoading ||
        getCompanyTripDetailsLoading ||
        getCompanyTripDetailsIdLoading || getCompanyFuelIdLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">
        {edit?._id ? "Update" : "Add"} Fuel Details
      </h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={edit?._id ? onUpdate : onsubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="tripId">
                  Trip 
                </label>
                <Field as="select" name="tripId" disabled={searchParams.get("tripId")!==null}>
                  <option value="">Select Trip</option>
                  {tripData &&
                    tripData.map((trip) => (
                      <option key={trip._id} value={trip._id}>
                        {trip.tokenNumber} ({trip?.startLocation} -{" "}
                        {trip?.endLocation})
                      </option>
                    ))}
                </Field>
                <ErrorMessage name="tripId" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="truckId">
                  Truck <span>*</span>
                </label>
                <Field as="select" name="truckId" disabled={searchParams.get("truckId")!==null || data?.truckId?._id}>
                  <option value="">Select Truck</option>
                  {truckData &&
                    truckData.map((truck) => (
                      <option key={truck._id} value={truck._id}>
                        {truck.truckNumber}
                      </option>
                    ))}
                </Field>
                <ErrorMessage
                  name="truckId"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="fuelFillDate">
                  Fuel Fill Date <span>*</span>
                </label>
                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="fuelFillDate"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="fuelFillDate"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="pumpName">
                  Pump Name <span>*</span>
                </label>
                <Field type="text" name="pumpName" placeHolder="Pump Name" />
                <ErrorMessage
                  name="pumpName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="quantity">Liter</label>
                <Field type="number" name="quantity" placeHolder="Liter" />
                <ErrorMessage
                  name="quantity"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="perLiterCost">Per Liter Rate</label>
                <Field
                  type="number"
                  name="perLiterCost"
                  placeHolder="Per Liter Rate"
                />
                <ErrorMessage
                  name="perLiterCost"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="cost">
                  Total <span>*</span>
                </label>
                <Field type="number" name="cost" placeHolder="Total Amount" />
                <ErrorMessage name="cost" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="billNumber">Bill Number</label>
                <Field
                  type="text"
                  name="billNumber"
                  placeHolder="Bill Number"
                />
                <ErrorMessage
                  name="billNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="billImage">Bill Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="billImage"
                  values={values}
                />
                <ErrorMessage
                  name="billImage"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  placeHolder="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <button
                type="submit"
                disabled={postCompanyFuelLoading || getCompanyFuelIdLoading}
                className="primaryButton h-fit mt-auto"
              >
                {edit?._id ? "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddFuel;
