import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyTripDetailsFilterAction,
  getCompanyTripDetailsIdAction,
  patchCompanyTripPaymentAction,
} from "../../../redux/company/trip/action";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { useSearchParams } from "react-router-dom";
import { getCompanyDetailsAction } from "../../../redux/company/action";
import FileUpload from "../../fileupload/fileUpload";
import { getCompanyTruckDetailsIdAction } from "../../../redux/company/truck/action";

function Payment({ data, setShowModal, showModal }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    patchCompanyTripPayment,
    patchCompanyTripPaymentLoading,
    patchCompanyTripPaymentError,
  } = useSelector((state) => state.CompanyTripsection);
  const {
    getCompanyDetails,
    getCompanyDetailsLoading,
    getCompanyDetailsError,
  } = useSelector((state) => state.Companysection);

  const [loading, setLoading] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);
  const [accountNumber, setAccountNumber] = useState(null);
  const [gst, setGst] = useState(0); // GST state

  useEffect(() => {
    if (showModal === true) {
      dispatch(getCompanyDetailsAction());
    }
  }, [showModal]);

  useEffect(() => {
    if (getCompanyDetails?.status === 200) {
      setAccountNumber(getCompanyDetails?.data?.company.accountNumber);
    }
  }, [getCompanyDetails]);

  useEffect(() => {
    const paymentAmount = data?.endingLoad * data?.ratePerWeight;
    setTotalPayment(paymentAmount);
  }, [data]);

  const handleGstChange = (value, setFieldValue) => {
    const gstValue = value;
    if (!isNaN(gstValue)) value = 0;
    setGst(gstValue);
    const gstAmount = (data?.endingLoad * data?.ratePerWeight * gstValue) / 100;
    setTotalPayment(data?.endingLoad * data?.ratePerWeight - gstAmount);
    setFieldValue("gstOnPayment", value);
    setFieldValue(
      "totalPayment",
      data?.endingLoad * data?.ratePerWeight - gstAmount
    );
    // }
  };

  const initialValues = {
    paymentAccount: data?.accountNumber || accountNumber || "",
    paymentType: data?.paymentType || "Net Banking",
    paymentDate: data?.paymentDate || new Date(),
    transitionNumber: data?.transitionNumber || "",
    checkImage: data?.checkImage || "",
    checkNumber: data?.checkNumber || "",
    totalPayment: data?.totalPayment || totalPayment,
    gstOnPayment: data?.gstOnPayment || 0, // Default GST value
    anyDeduction: data?.anyDeduction || null,
  };

  const validationSchema = Yup.object({
    paymentType: Yup.string().required("Payment Type is required"),
    paymentDate: Yup.date().required("Payment Date is required"),
    totalPayment: Yup.number().required("Total Payment is required"),
    paymentAccount: Yup.number().when("paymentType", {
      is: (val) => val !== "Cash",
      then: () => Yup.number().required("Account Number is required"),
    }),
    checkNumber: Yup.string().when("paymentType", {
      is: "Check",
      then: () => Yup.string().required("Check Number is required"),
    }),
    checkImage: Yup.string().when("paymentType", {
      is: "Check",
      then: () => Yup.string().required("Check Image is required"),
    }),
  });

  useEffect(() => {
    if (loading) {
      if (patchCompanyTripPayment?.status === 200) {
        toast.success(patchCompanyTripPayment.message, {
          id: "updateDetails-toast",
        });
        const id = searchParams.get("truckId");
        const tripid = searchParams.get("tripId");
        const payload = {
          truckId: id,
        };
        if (tripid !== null) {
          dispatch(getCompanyTripDetailsIdAction(tripid));
          dispatch(getCompanyTruckDetailsIdAction(id));
        } else {
          dispatch(getCompanyTripDetailsFilterAction(payload));
        }
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [patchCompanyTripPayment]);
  useEffect(() => {
    if (loading) {
      if (patchCompanyTripPaymentError?.status === 400) {
        toast.success(patchCompanyTripPaymentError.message, {
          id: "updateDetails-toast",
        });

        setLoading(false);
      } else if (patchCompanyTripPaymentError?.status === 404) {
        toast.success(patchCompanyTripPaymentError.message, {
          id: "updateDetails-toast",
        });

        setLoading(false);
      } else if (patchCompanyTripPaymentError?.status === 500) {
        toast.success(patchCompanyTripPaymentError.error, {
          id: "updateDetails-toast",
        });

        setLoading(false);
      }
    }
  }, [patchCompanyTripPaymentError]);
  useEffect(() => {
    if (loading) {
      if (patchCompanyTripPayment?.status === 200) {
        toast.success(patchCompanyTripPayment.message, {
          id: "updateDetails-toast",
        });
        const id = searchParams.get("truckId");
        const tripid = searchParams.get("tripId");
        const payload = {
          truckId: id,
        };
        if (tripid !== null) {
          dispatch(getCompanyTripDetailsIdAction(tripid));
          dispatch(getCompanyTruckDetailsIdAction(id));
        } else {
          dispatch(getCompanyTripDetailsFilterAction(payload));
        }
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [patchCompanyTripPayment, patchCompanyTripPaymentError, loading]);

  const onSubmit = async (values) => {
    let payload = {
      id: data._id,
      body: {
        paymentType: values.paymentType,
        paymentDate: values.paymentDate,
        transitionNumber: values.transitionNumber,
        finalPayment: values.totalPayment,
        gstOnPayment: values.gstOnPayment,
        anyDeduction: values.anyDeduction,
      },
    };

    if (values.paymentType !== "Cash") {
      payload.body.paymentAccount = values.paymentAccount;
    }
    if (values.paymentType === "Check") {
      payload.body.checkNumber = values.checkNumber;
      payload.body.checkImage = values.checkImage;
    }

    dispatch(patchCompanyTripPaymentAction(payload));
    toast.loading("Updating Trip Details...", { id: "updateDetails-toast" });
    setLoading(true);
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Payment Details</DialogTitle>
      </DialogHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <label htmlFor="totalPayment">
                Total Payment <span>*</span>
              </label>
              <Field
                type="number"
                disabled={true}
                value={data?.endingLoad * data?.ratePerWeight}
                className=""
              />
            </div>

            {data?.isAdvancePayment && (
              <div className="mb-4">
                <label htmlFor="totalPayment">
                  Advance Payment <span>*</span>
                </label>
                <Field
                  type="number"
                  disabled={true}
                  value={data?.advancePaymentCost}
                  className=""
                />
              </div>
            )}

            {/* <div className="mb-4">
              <label htmlFor="totalPayment">
                Receivable Payment <span>*</span>
              </label>
              <Field
                type="number"
                disabled={true}
                value={
                  data?.isAdvancePayment
                    ? data?.endingLoad * data?.ratePerWeight -
                      data.advancePaymentCost
                    : data?.endingLoad * data?.ratePerWeight
                }
                className=""
              />
            </div> */}

            <div className="mb-4">
              <label htmlFor="gstOnPayment">
                GST Percentage <span>*</span>
              </label>
              <input
                type="number"
                id="gstOnPayment"
                name="gstOnPayment"
                value={gst}
                placeholder="Enter GST in %"
                onChange={(e) => {
                  handleGstChange(e.target.value, setFieldValue);
                  setFieldValue("gstOnPayment", e.target.value);
                }}
              />
            </div>

            <div>
              <label htmlFor="totalPayment">Any Deduction</label>
              <Field
                type="number"
                name="anyDeduction"
                value={values.anyDeduction}
                onChange={(e) => {
                  setFieldValue("anyDeduction", e.target.value);
                  setFieldValue("totalPayment", totalPayment - e.target.value);
                }}
                className=""
              />
            </div>

            <div className="mb-4">
              <label htmlFor="paymentType">
                Payment Type <span>*</span>
              </label>
              <Field
                as="select"
                id="paymentType"
                name="paymentType"
                className=""
              >
                <option value="Net Banking">Net Banking</option>
                <option value="Cash">Cash</option>
                <option value="Check">Check</option>
                <option value="UPI">UPI</option>
              </Field>
              <ErrorMessage
                name="paymentType"
                component="div"
                className="error"
              />
            </div>

            {values.paymentType !== "Cash" &&
              values.paymentType !== "Check" && (
                <div className="mb-4">
                  <label htmlFor="transitionNumber">Transition Number</label>
                  <Field
                    type="text"
                    id="transitionNumber"
                    name="transitionNumber"
                    className=""
                    placeholder="Transition Number"
                  />
                  <ErrorMessage
                    name="transitionNumber"
                    component="div"
                    className="error"
                  />
                </div>
              )}

            {values.paymentType !== "Cash" && (
              <div className="mb-4">
                <label htmlFor="paymentAccount">
                  Account Number <span>*</span>
                </label>
                <Field
                  type="number"
                  id="paymentAccount"
                  name="paymentAccount"
                  className=""
                  placeholder="Account No"
                />
                <ErrorMessage
                  name="paymentAccount"
                  component="div"
                  className="error"
                />
              </div>
            )}

            {values.paymentType === "Check" && (
              <>
                <div className="mb-4">
                  <label htmlFor="checkNumber">
                    Check Number <span>*</span>
                  </label>
                  <Field
                    type="text"
                    id="checkNumber"
                    name="checkNumber"
                    className=""
                    placeholder="Check Number"
                  />
                  <ErrorMessage
                    name="checkNumber"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="mb-4 col-span-2">
                  <label htmlFor="checkImage">
                    Check Image <span>*</span>
                  </label>
                  <FileUpload
                    setFieldValue={setFieldValue}
                    name="checkImage"
                    values={values}
                  />
                  <ErrorMessage
                    name="checkImage"
                    component="div"
                    className="error"
                  />
                </div>
              </>
            )}
            <div className="my-auto">
              Receive Payment Amount:{" "}
              <span className="font-bold">
                Rs.{values.totalPayment - data.advancePaymentCost}
              </span>
            </div>
            <DialogFooter className="col-span-2">
              <button
                type="button"
                className="mr-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                // disabled={isSubmitting}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                {loading ? "Saving..." : "Save Total Payment"} Rs.
                {values.totalPayment}
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default Payment;
