import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmiAction, getEmiAction } from "../../../../redux/company/truck/emi/action";

function EmiDelete({ setShowModal, id }) {
  const dispatch = useDispatch();
  const { deleteEmi, deleteEmiLoading, deleteEmiError } = useSelector(
    (state) => state.EmiSection
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["EMI"], 'You must type "EMI" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteEmi?.status === 200) {
        toast.success(deleteEmi.message, {
          id: "deletesalary-toast",
        });
        const truckId = searchParams.get("truckId");
        dispatch(getEmiAction(truckId));
        setLoading(false);
        setShowModal(false);
      }
    }
  }, [deleteEmi, loading]);
  useEffect(() => {
    if (loading) {
      if (deleteEmiError?.status === 404) {
        toast.error(deleteEmiError.message, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      } else if (deleteEmiError?.status === 400) {
        toast.error(deleteEmiError.message, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      } else if (deleteEmiError?.status === 500) {
        toast.error(deleteEmiError.error, {
          id: "deletesalary-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteEmiError, loading]);
  const onSubmit = (values) => {
    if (values.deleteValue === "EMI") {
      dispatch(deleteEmiAction(id));
      toast.loading("Deleting Emi...", { id: "deletesalary-toast" });
      setLoading(true);
    }
  };
  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Emi</DialogTitle>
      </DialogHeader>
      <div className="text-xl mt-5 font-semibold">
        Are you sure you want to{" "}
        <span className="text-red-500 font-bold">Delete</span> this EMI?
      </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"EMI"</span> in
                Capital Letter the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="EMI"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
              />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                type="button"
                disabled={deleteEmiLoading}
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
                disabled={deleteEmiLoading}
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default EmiDelete;
