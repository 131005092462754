import React, { useState } from "react";
import Image from "../../../assets/images/bill.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../../ui/badge";
import EndTrip from "./endTrip";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import Payment from "./payment";
import DeleteTrip from "./deleteTrip";
import { BsFuelPumpFill } from "react-icons/bs";
import { CiReceipt } from "react-icons/ci";
import ImagePopUpDownload from "../../imagePopUpDownload";

function TripListCard({ data, reroute = false }) {
  const navigate = useNavigate();
  const [showEndModal, setShowEndModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div key={data._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full bg-gray-400"
            onClick={() =>
              reroute
                ? navigate(
                    `/company/trip/details?tripId=${data._id}&truckId=${data?.truckId?._id}`
                  )
                : setShowImageModal(true)
            }
          >
            <img
              src={
                data?.tokenImage?.length > 0 ? data?.tokenImage[0].url : Image
              }
              alt=""
              className={` ${
                data?.tokenImage?.length > 0 ? "" : ""
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.tokenImage?.length > 0 && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              isArray={true}
              image={data?.tokenImage}
              setShowModal={setShowImageModal}
              imagename={`token_${data?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={
              reroute
                ? () =>
                    navigate(
                      `/company/trip/details?tripId=${data._id}&truckId=${data?.truckId?._id}`
                    )
                : null
            }
          >
            {data?.tokenNumber}
          </button>
          <p className="textgrey text-base uppercase">
            {data.productType}, {moment(data.startDate).format("DD/MMM/YYYY")}-
            {data.endDate ? moment(data.endDate).format("DD/MMM/YYYY") : ""}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Driver Name</h3>
            <p>{data?.driverName}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center ">Client</h3>
            <p className="flex md:justify-center">
              {data?.isClientId
                ? data.clientId.name + `(${data?.clientId.ownerName})`
                : data?.clientNames}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center ">Helper</h3>
            <p className="flex md:justify-center">
              {data?.helperId ? data.helperName : "-"}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">From-to</h3>
            <p className="md:text-center uppercase flex flex-col">
              {data?.startLocation} - {data?.endLocation} (
              {data?.distanceTravelled}km)
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs">Start - End Load</h3>
            <p>
              {data?.startingLoad
                ? data?.startingLoad.toString() + data?.weigthType
                : ""}{" "}
              - {data?.endingLoad ? data?.endingLoad + data?.weigthType : ""}
            </p>
          </div>
          <div className="md:text-center">
            <h3 className="textgrey text-xs">Rate per {data?.weigthType}</h3>
            <p>{data?.ratePerWeight}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center ">Trip Status</h3>
            <p className="flex md:justify-center">
              {data?.status === "Planned" && (
                <Badge variant="destructive">{data?.status}</Badge>
              )}
              {data?.status === "Trip Started" && (
                <Badge
                  variant="secondary"
                  className="bg-yellow-500 hover:bg-yellow-800 text-white"
                >
                  {data?.status}
                </Badge>
              )}
              {data?.status === "Trip Ended" && (
                <Badge
                  variant=""
                  className="bg-blue-500 hover:bg-blue-800 text-white"
                >
                  {data?.status}
                </Badge>
              )}
              {data?.status === "Completed" && (
                <Badge
                  variant=""
                  className="bg-green-700 hover:bg-green-900 text-white"
                >
                  {data?.status}
                </Badge>
              )}
            </p>
          </div>
          <div></div>

          {data?.status === "Planned" && (
            <div className="my-auto  ">
              <button
                type="button"
                className="primaryButton bg-green-700 hover:bg-green-900 text-white h-full"
                onClick={() =>
                  navigate(
                    `/company/trip/addtrip?id=${data?._id}&status=${data?.status}`
                  )
                }
              >
                Start The Trip
              </button>
            </div>
          )}
          {data?.status === "Trip Started" && (
            <div className="my-auto ">
              <Dialog open={showEndModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    className="primaryButton bg-green-700 hover:bg-green-900 h-full"
                    onClick={() => setShowEndModal(true)}
                  >
                    Trip Complete
                  </button>
                </DialogTrigger>
                <EndTrip data={data} setShowModal={setShowEndModal} />
              </Dialog>
            </div>
          )}
          {data?.status === "Trip Ended" && (
            <div className="my-auto ">
              <Dialog open={showPaymentModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    className="primaryButton bg-green-700 hover:bg-green-900 h-full"
                    onClick={() => setShowPaymentModal(true)}
                  >
                    Payment Done
                  </button>
                </DialogTrigger>
                <Payment
                  data={data}
                  setShowModal={setShowPaymentModal}
                  showModal={showPaymentModal}
                />
              </Dialog>
            </div>
          )}
          {(data?.status === "Planned" || data?.status === "Trip Started") && (
            <div className="my-auto ">
              <button
                type="button"
                className="primaryButton bg-yellow-600 hover:bg-yellow-800  h-full"
                onClick={() =>
                  navigate(
                    `/company/trip/addtrip?truckId=${data?.truckId?._id}&id=${data?._id}&edit=true`
                  )
                }
              >
                Edit Trip Details
              </button>
            </div>
          )}
          {data?.status === "Trip Ended" && (
            <div className="my-auto ">
              <Dialog open={showEndModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    className="primaryButton bg-yellow-600 hover:bg-yellow-800  h-full"
                    onClick={() => setShowEndModal(true)}
                  >
                    Edit Trip End Details
                  </button>
                </DialogTrigger>
                <EndTrip data={data} setShowModal={setShowEndModal} />
              </Dialog>
            </div>
          )}
          {data?.status === "Completed" && (
            <div className="my-auto ">
              <Dialog open={showPaymentModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    className="primaryButton bg-yellow-600 hover:bg-yellow-800 h-full"
                    onClick={() => setShowPaymentModal(true)}
                  >
                    Edit Trip Payment Details
                  </button>
                </DialogTrigger>
                <Payment
                  data={data}
                  setShowModal={setShowPaymentModal}
                  showModal={showPaymentModal}
                />
              </Dialog>
            </div>
          )}
          {data?.status === "Planned" && (
            <div className="my-auto col-span-1">
              <Dialog open={showDeleteModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    onClick={() => setShowDeleteModal(true)}
                    className="primaryButton bg-red-600 hover:bg-red-800 md:px-4 px-2"
                  >
                    Delete
                  </button>
                </DialogTrigger>
                <DeleteTrip setShowModal={setShowDeleteModal} id={data?._id} />
              </Dialog>
            </div>
          )}
          {data?.status !== "Completed" && data?.status !== "Planned" && (
            <>
              <button
                onClick={() =>
                  navigate(`/company/fuel/addFuel?tripId=${data._id}`)
                }
                className="primaryButton flex justify-center items-center gap-2"
              >
                Add Fuel <BsFuelPumpFill className="text-2xl" />
              </button>
              <button
                onClick={() =>
                  navigate(`/company/tollTax/addTollTax?tripId=${data._id}`)
                }
                className="primaryButton flex justify-center items-center gap-2"
              >
                Add Toll Tax <CiReceipt className="text-2xl" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TripListCard;
