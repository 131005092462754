import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../../assets/images/login.jpeg";
import { Badge } from "../../../ui/badge";
import TruckListCard from "./truckListCard";

function TruckList({ data }) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter(
    (item) =>
      item.truckNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.typeOfTruck.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="mb-4">
        <input
          placeholder="Search by Truck Number or Type"
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm shadow-md "
        />
      </div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item, index) => <TruckListCard data={item} reroute={true} />)
      ) : (
        <p>No trucks found.</p>
      )}
    </div>
  );
}

export default TruckList;
