import { getCompanyEmployeeDetailsAction } from "../../../redux/company/employee/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../backdrop";
import { useNavigate } from "react-router-dom";
import EmployeeListCard from "./employeeListCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

function EmployeeList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getCompanyEmployeeDetails,
    getCompanyEmployeeDetailsLoading,
    getCompanyEmployeeDetailsError,
  } = useSelector((state) => state.CompanyEmployeesection);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getCompanyEmployeeDetailsAction());
  }, []);

  useEffect(() => {
    if (getCompanyEmployeeDetails.status === 200) {
      const sortData = getCompanyEmployeeDetails?.data?.sort(
        (a, b) => b.isWorking - a.isWorking
      );
      setData(sortData);
    }
  }, [getCompanyEmployeeDetails]);
  return (
    <div>
      {getCompanyEmployeeDetailsLoading && <Backdrop />}
      <div className="flex justify-end gap-10">
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/employee/addEmployee/find")}
        >
          Add Exsisting Employee
        </button>
        <button
          type="button"
          className="primaryButton w-fit flex gap-2 mb-5 "
          onClick={() => navigate("/company/employee/addEmployee/user")}
        >
          Add New Employee
        </button>
      </div>
      <Tabs defaultValue="All" color="primary" className="min-w-[400px]">
        <TabsList>
          <TabsTrigger value="All">All Employees</TabsTrigger>
          <TabsTrigger value="working">Working</TabsTrigger>
          <TabsTrigger value="terminated">Terminated</TabsTrigger>
        </TabsList>
        <TabsContent value="All">
          {data?.length > 0 &&
            data.map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
        <TabsContent value="working">
          {data?.length > 0 &&
            data.filter((item)=> item.isWorking === true).map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
        <TabsContent value="terminated">
          {data?.length > 0 &&
            data.filter((item)=> item.isWorking === false).map((item) => <EmployeeListCard data={item} reroute={true} />)}
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default EmployeeList;
