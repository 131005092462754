import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteCookies, getCookies, useCustomCookies } from "../auth/auth";
import { Admin, Company, Employee } from "../data/data";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    handler();
  }, []);

  const handler = async () => {
    const type = await getCookies("userType");
    if (type === "company") {
      setUserType(Company);
    } else if (type === "admin") {
      setUserType(Admin);
    } else if (type === "employee") {
      setUserType(Employee);
    }
  };

  const handleLogout = async () => {
    const token = await deleteCookies("token");
    const userType = await deleteCookies("userType");

    if (token.status === true && userType.status === true) {
      navigate("/login");
    }
  };

  return (
    <>
      <div
        className={`transition-width duration-300 bg-gray-800 h-full fixed md:relative top-0 md:h-full z-50 md:z-0 ${
          isOpen ? "w-64" : "w-20 md:w-20"
        } ${isOpen ? "block" : "hidden md:block"}`}
      >
        <div className="flex justify-end pr-10 mt-5 md:hidden">
          <button onClick={toggleSidebar} className=" ">
            {isOpen ? (
              <FaTimes className="text-white" />
            ) : (
              <FaBars className="text-white" />
            )}
          </button>
        </div>
        <div className="flex flex-col p-6 space-y-9 fixed">
          {userType?.map((item) => (
            <NavLink key={item.href} to={item.href}>
              {({ isActive, isPending, isTransitioning }) => (
                <div
                  className={` ${
                    isActive
                      ? "text-gray-800 bg-white py-1 px-2  rounded-md"
                      : "text-white"
                  } flex gap-4 w-full`}
                >
                  <div
                    className={` ${
                      isActive ? "text-gray-800" : ""
                    } my-auto text-2xl`}
                  >
                    {item.icon}
                  </div>
                  {isOpen && (
                    <span className="my-auto transition-opacity duration-300 opacity-100">
                      {item.name}
                    </span>
                  )}
                </div>
              )}
            </NavLink>
          ))}
          <button
            className="primaryButton flex gap-4 px-1"
            type="button"
            onClick={() => handleLogout()}
            
          >
            <LuLogOut className="text-2xl" title="Logout" />
            {isOpen && "LOGOUT"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
