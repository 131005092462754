import {
  getEmiAction,
  postEmiAction,
} from "../../../../redux/company/truck/emi/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "../../../../components/datepicker/datePicker";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import FileUpload from "../../../../components/fileupload/fileUpload";
import Backdrop from "../../../../components/backdrop";

function PayEmi() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("truckId");

  const {
    postEmi,
    postEmiLoading,
    postEmiError,
    getEmi,
    getEmiLoading,
    getEmiError,
  } = useSelector((state) => state.EmiSection);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const [excludeDate, setExcludeDate] = useState([]);
  useEffect(() => {
    dispatch(getEmiAction(id));
  }, []);

  useEffect(() => {
    if (loading) {
      if (postEmi?.status === 200) {
        toast.success(postEmi.message, { id: "post_emiAmount" });
        setLoading(false);
        navigate(-1);
      }
    }
  }, [postEmi]);
  useEffect(() => {
    if (loading) {
      if (postEmiError?.status === 400) {
        toast.error(postEmiError.message, { id: "post_emiAmount" });
        setLoading(false);
      } else if (postEmiError?.status === 404) {
        toast.error(postEmiError.message, { id: "post_emiAmount" });
        setLoading(false);
      } else if (postEmiError?.status === 500) {
        toast.error(postEmiError.error, { id: "post_emiAmount" });
        setLoading(false);
      }
    }
  }, [postEmiError]);
  useEffect(() => {
    if (getEmi?.status == 200) {
      if (getEmi?.data?.emiData?.length > 0) {
        let filterData = getEmi?.data?.emiData;

        const excludeDates = filterData?.map(
          (record) => new Date(record.emiPayMonth)
        );
        setExcludeDate(excludeDates);
      }
    }
  }, [getEmi]);
  const validationSchema = Yup.object({
    paymentType: Yup.string().required("Payment type is required"),
    emiPayMonth: Yup.date().required("Emi month is required").nullable(),
    additionCharges: Yup.number().nullable(),
  });
  const onSubmit = (values) => {
    const payload = {
      truckId: id,
      emiAmount: values.emiAmount,
      paymentType: values.paymentType,
      emiPayMonth: values.emiPayMonth,
      additionCharges: values.additionCharges,
      desctiption: values.desctiption,
      additionalChargesReason: values.additionalChargesReason,
      emiTransationNumber: values.emiTransationNumber,
      emiTransationImage: values.emiTransationImage,
    };
    dispatch(postEmiAction(payload));
    setLoading(true);
    toast.loading("Saving data....", { id: "post_emiAmount" });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Pay Emi</h2>
      {getEmiLoading && <Backdrop />}
      <Formik
        initialValues={{
          emiAmount: "",
          paymentType: "Cash",
          emiPayMonth: "",
          additionCharges: 0,
          additionalChargesReason: "",
          emiTransationNumber: null,
          emiTransationImage: "",
          desctiption: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="">
            {console.log(errors)}
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div className="mb-4">
                <label htmlFor="emiAmount">
                  Emi <span>*</span>
                </label>
                <Field
                  type="number"
                  id="emiAmount"
                  name="emiAmount"
                  className=""
                  placeholder="Emi"
                />
                <ErrorMessage
                  name="emiAmount"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="paymentType">
                  Payment Type <span>*</span>
                </label>
                <Field
                  as="select"
                  id="paymentType"
                  name="paymentType"
                  className=""
                >
                  <option value="Cash">Cash</option>
                  <option value="Net Banking">Net Banking</option>
                  <option value="Check">Check</option>
                  <option value="UPI">UPI</option>
                </Field>
                <ErrorMessage
                  name="paymentType"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="emiPayMonth">
                  Emi Month <span>*</span>
                </label>
                <DatePicker
                  id="emiPayMonth"
                  name="emiPayMonth"
                  placeHolder="Emi Month"
                  showMonthYearPicker
                  dateFormat="MMM / YYYY"
                  excludeDates={excludeDate}
                />
                <ErrorMessage
                  name="emiPayMonth"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="additionCharges">Extra Pay</label>
                <Field
                  type="number"
                  id="additionCharges"
                  name="additionCharges"
                  className=""
                  placeholder="Extra pay"
                />
                <ErrorMessage
                  name="additionCharges"
                  component="div"
                  className="error"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="additionalChargesReason">
                  Extra Pay Reason
                </label>
                <Field
                  type="text"
                  id="additionalChargesReason"
                  name="additionalChargesReason"
                  className=""
                  placeholder="Extra pay Reason"
                />
                <ErrorMessage
                  name="additionalChargesReason"
                  component="div"
                  className="error"
                />
              </div>
              {values.paymentType !== "Cash" && (
                <div className="mb-4">
                  <label htmlFor="emiTransationNumber">Extra Pay Reason</label>
                  <Field
                    type="text"
                    id="emiTransationNumber"
                    name="emiTransationNumber"
                    className=""
                    placeholder="Transition Number"
                  />
                  <ErrorMessage
                    name="emiTransationNumber"
                    component="div"
                    className="error"
                  />
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="emiTransationImage">Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="emiTransationImage"
                  values={values}
                />
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  placeHolder="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <button
                className="primaryButton bg-green-600 hover:bg-green-800 h-fit my-auto  whitespace-nowrap"
                type="submit"
                disabled={postEmiLoading}
              >
                Pay {values.emiAmount + values.additionCharges}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default PayEmi;
