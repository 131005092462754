import {
  DELETE_COMPANY_TRUCK,
  DELETE_COMPANY_TRUCK_ERROR,
  DELETE_COMPANY_TRUCK_SUCCESS,
  GET_COMPANY_TRUCK_DETAILS,
  GET_COMPANY_TRUCK_DETAILS_ERROR,
  GET_COMPANY_TRUCK_DETAILS_ID,
  GET_COMPANY_TRUCK_DETAILS_ID_ERROR,
  GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS,
  GET_COMPANY_TRUCK_DETAILS_SUCCESS,
  PATCH_COMPANY_TRUCK_DETAILS,
  PATCH_COMPANY_TRUCK_DETAILS_ERROR,
  PATCH_COMPANY_TRUCK_DETAILS_SUCCESS,
  POST_COMPANY_TRUCK_DETAILS,
  POST_COMPANY_TRUCK_DETAILS_ERROR,
  POST_COMPANY_TRUCK_DETAILS_SUCCESS,
  POST_TRUCK_SELL,
  POST_TRUCK_SELL_SUCCESS,
  POST_TRUCK_SELL_ERROR,
  POST_TRUCK_PURCHASE,
  POST_TRUCK_PURCHASE_SUCCESS,
  POST_TRUCK_PURCHASE_ERROR,
  GET_TRUCK_PURCHASE,
  GET_TRUCK_PURCHASE_SUCCESS,
  GET_TRUCK_PURCHASE_ERROR,
  GET_TRUCK_INSURANCE,
  GET_TRUCK_INSURANCE_SUCCESS,
  GET_TRUCK_INSURANCE_ERROR,
  GET_TRUCK_FITNESS,
  GET_TRUCK_FITNESS_SUCCESS,
  GET_TRUCK_FITNESS_ERROR,
  GET_TRUCK_INSURANCE_BYTRUCKID,
  GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS,
  GET_TRUCK_INSURANCE_BYTRUCKID_ERROR,
  GET_TRUCK_FITNESS_BYTRUCKID,
  GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS,
  GET_TRUCK_FITNESS_BYTRUCKID_ERROR,
  GET_TRUCK_PERMIT_BYTRUCKID,
  GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS,
  GET_TRUCK_PERMIT_BYTRUCKID_ERROR,
  GET_TRUCK_PERMIT_ERROR,
  GET_TRUCK_PERMIT_SUCCESS,
  GET_TRUCK_PERMIT,
  PATCH_TRUCK_FITNESS,
  PATCH_TRUCK_FITNESS_ERROR,
  PATCH_TRUCK_FITNESS_SUCCESS,
  PATCH_TRUCK_INSURANCE,
  PATCH_TRUCK_INSURANCE_ERROR,
  PATCH_TRUCK_INSURANCE_SUCCESS,
  PATCH_TRUCK_PERMIT,
  PATCH_TRUCK_PERMIT_ERROR,
  PATCH_TRUCK_PERMIT_SUCCESS,
} from "../../actions";

//truck
export const getCompanyTruckDetailsAction = (info) => ({
  type: GET_COMPANY_TRUCK_DETAILS,
  payload: info,
});
export const getCompanyTruckDetailsSuccess = (info) => ({
  type: GET_COMPANY_TRUCK_DETAILS_SUCCESS,
  payload: info,
});
export const getCompanyTruckDetailsError = (error) => ({
  type: GET_COMPANY_TRUCK_DETAILS_ERROR,
  payload: error,
});
export const getCompanyTruckDetailsIdAction = (info) => ({
  type: GET_COMPANY_TRUCK_DETAILS_ID,
  payload: info,
});
export const getCompanyTruckDetailsIdSuccess = (info) => ({
  type: GET_COMPANY_TRUCK_DETAILS_ID_SUCCESS,
  payload: info,
});
export const getCompanyTruckDetailsIdError = (error) => ({
  type: GET_COMPANY_TRUCK_DETAILS_ID_ERROR,
  payload: error,
});
export const postCompanyTruckDetailsAction = (info) => ({
  type: POST_COMPANY_TRUCK_DETAILS,
  payload: info,
});
export const postCompanyTruckDetailsSuccess = (info) => ({
  type: POST_COMPANY_TRUCK_DETAILS_SUCCESS,
  payload: info,
});
export const postCompanyTruckDetailsError = (error) => ({
  type: POST_COMPANY_TRUCK_DETAILS_ERROR,
  payload: error,
});
export const postTruckSellAction = (info) => ({
  type: POST_TRUCK_SELL,
  payload: info,
});
export const postTruckSellSuccess = (info) => ({
  type: POST_TRUCK_SELL_SUCCESS,
  payload: info,
});
export const postTruckSellError = (error) => ({
  type: POST_TRUCK_SELL_ERROR,
  payload: error,
});
export const getTruckPurchaseAction = (info) => ({
  type: GET_TRUCK_PURCHASE,
  payload: info,
});
export const getTruckPurchaseSuccess = (info) => ({
  type: GET_TRUCK_PURCHASE_SUCCESS,
  payload: info,
});
export const getTruckPurchaseError = (error) => ({
  type: GET_TRUCK_PURCHASE_ERROR,
  payload: error,
});
export const postTruckPurchaseAction = (info) => ({
  type: POST_TRUCK_PURCHASE,
  payload: info,
});
export const postTruckPurchaseSuccess = (info) => ({
  type: POST_TRUCK_PURCHASE_SUCCESS,
  payload: info,
});
export const postTruckPurchaseError = (error) => ({
  type: POST_TRUCK_PURCHASE_ERROR,
  payload: error,
});
export const patchCompanyTruckDetailsAction = (info) => ({
  type: PATCH_COMPANY_TRUCK_DETAILS,
  payload: info,
});
export const patchCompanyTruckDetailsSuccess = (info) => ({
  type: PATCH_COMPANY_TRUCK_DETAILS_SUCCESS,
  payload: info,
});
export const patchCompanyTruckDetailsError = (error) => ({
  type: PATCH_COMPANY_TRUCK_DETAILS_ERROR,
  payload: error,
});
export const deleteCompanyTruckAction = (info) => ({
  type: DELETE_COMPANY_TRUCK,
  payload: info,
});
export const deleteCompanyTruckSuccess = (info) => ({
  type: DELETE_COMPANY_TRUCK_SUCCESS,
  payload: info,
});
export const deleteCompanyTruckError = (error) => ({
  type: DELETE_COMPANY_TRUCK_ERROR,
  payload: error,
});

export const getTruckInsuranceByTruckIdAction = (info) => ({
  type: GET_TRUCK_INSURANCE_BYTRUCKID,
  payload: info,
});
export const getTruckInsuranceByTruckIdSuccess = (info) => ({
  type: GET_TRUCK_INSURANCE_BYTRUCKID_SUCCESS,
  payload: info,
});
export const getTruckInsuranceByTruckIdError = (error) => ({
  type: GET_TRUCK_INSURANCE_BYTRUCKID_ERROR,
  payload: error,
});
export const getTruckFitnessByTruckIdAction = (info) => ({
  type: GET_TRUCK_FITNESS_BYTRUCKID,
  payload: info,
});
export const getTruckFitnessByTruckIdSuccess = (info) => ({
  type: GET_TRUCK_FITNESS_BYTRUCKID_SUCCESS,
  payload: info,
});
export const getTruckFitnessByTruckIdError = (error) => ({
  type: GET_TRUCK_FITNESS_BYTRUCKID_ERROR,
  payload: error,
});
export const getTruckPermitByTruckIdAction = (info) => ({
  type: GET_TRUCK_PERMIT_BYTRUCKID,
  payload: info,
});
export const getTruckPermitByTruckIdSuccess = (info) => ({
  type: GET_TRUCK_PERMIT_BYTRUCKID_SUCCESS,
  payload: info,
});
export const getTruckPermitByTruckIdError = (error) => ({
  type: GET_TRUCK_PERMIT_BYTRUCKID_ERROR,
  payload: error,
});

export const getTruckInsuranceAction = (info) => ({
  type: GET_TRUCK_INSURANCE,
  payload: info,
});
export const getTruckInsuranceSuccess = (info) => ({
  type: GET_TRUCK_INSURANCE_SUCCESS,
  payload: info,
});
export const getTruckInsuranceError = (error) => ({
  type: GET_TRUCK_INSURANCE_ERROR,
  payload: error,
});
export const getTruckFitnessAction = (info) => ({
  type: GET_TRUCK_FITNESS,
  payload: info,
});
export const getTruckFitnessSuccess = (info) => ({
  type: GET_TRUCK_FITNESS_SUCCESS,
  payload: info,
});
export const getTruckFitnessError = (error) => ({
  type: GET_TRUCK_FITNESS_ERROR,
  payload: error,
});
export const getTruckPermitAction = (info) => ({
  type: GET_TRUCK_PERMIT,
  payload: info,
});
export const getTruckPermitSuccess = (info) => ({
  type: GET_TRUCK_PERMIT_SUCCESS,
  payload: info,
});
export const getTruckPermitError = (error) => ({
  type: GET_TRUCK_PERMIT_ERROR,
  payload: error,
});

export const patchTruckInsuranceAction = (info) => ({
  type: PATCH_TRUCK_INSURANCE,
  payload: info,
});
export const patchTruckInsuranceSuccess = (info) => ({
  type: PATCH_TRUCK_INSURANCE_SUCCESS,
  payload: info,
});
export const patchTruckInsuranceError = (error) => ({
  type: PATCH_TRUCK_INSURANCE_ERROR,
  payload: error,
});
export const patchTruckFitnessAction = (info) => ({
  type: PATCH_TRUCK_FITNESS,
  payload: info,
});
export const patchTruckFitnessSuccess = (info) => ({
  type: PATCH_TRUCK_FITNESS_SUCCESS,
  payload: info,
});
export const patchTruckFitnessError = (error) => ({
  type: PATCH_TRUCK_FITNESS_ERROR,
  payload: error,
});
export const patchTruckPermitAction = (info) => ({
  type: PATCH_TRUCK_PERMIT,
  payload: info,
});
export const patchTruckPermitSuccess = (info) => ({
  type: PATCH_TRUCK_PERMIT_SUCCESS,
  payload: info,
});
export const patchTruckPermitError = (error) => ({
  type: PATCH_TRUCK_PERMIT_ERROR,
  payload: error,
});
