import React from "react";
import { DialogContent, DialogFooter } from "../ui/dialog";
import { saveAs } from "file-saver";

function ImagePopUpDownload({
  image,
  setShowModal,
  imagename,
  isArray = false,
}) {
  // Function to handle image download
  const handleDownload = () => {
    saveAs(image, `${imagename}_image.jpg`);
  };

  return (
    <DialogContent className="bg-gray-100">
      <div className="h-full w-full flex justify-center items-center">
        {isArray ? (
          image.map((item) => (
            <div>
              {" "}
              <img
                src={item.url}
                alt="Preview"
                className="h-full w-full object-contain"
              />
            </div>
          ))
        ) : (
          <img
            src={image.url}
            alt="Preview"
            className="h-full w-full object-contain"
          />
        )}
      </div>
      <DialogFooter>
        <button
          className="secondaryButton"
          onClick={() => setShowModal(false)}
          type="button"
        >
          Close
        </button>
        <button
          className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
          onClick={handleDownload}
          type="button"
        >
          Download
        </button>
      </DialogFooter>
    </DialogContent>
  );
}

export default ImagePopUpDownload;
