import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { postForgetPasswordAction } from "../redux/auth/action";
import toast from "react-hot-toast";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


function ForgetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {

    postForgetPassword,
    postForgetPasswordLoading,
    postForgetPasswordError,
  } = useSelector((state) => state.Authsection);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader) {
      if (postForgetPassword.status === 200) {
        toast.success(postForgetPassword.message, { id: "forget-toast" });
        setLoader(false);

      } else if (postForgetPassword.status === 400) {
        toast.error(postForgetPassword.message, { id: "forget-toast" });
      } else if (postForgetPassword.status === 500) {
        toast.error(postForgetPassword.error, { id: "forget-toast" });
      }
    }
  }, [postForgetPassword]);

  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
  });

  const onSubmit = (value) => {
    dispatch(postForgetPasswordAction(value));
    setLoader(true);
    toast.loading("Loading...", { id: "forget-toast" });

  };
  return (
    <div className="w-full p-8 md:col-span-2 bg-gray-100 col-span-5 grid grid-rows-3 ">
      <div className="flex gap-4">
      <FaChevronLeft onClick={()=>navigate("/login")} className="ghostButton text-2xl mt-1.5 cursor-pointer"/>
        <h2 className="text-2xl font-bold uppercase ">{t("forgetPassword")}</h2>
      </div>
      {/* <h2 className="text-lg text-gray-400  ">{t("slogan")}</h2> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => (
          <Form className="space-y-8 row-span-2 flex flex-col justify-between  ">
            <div>
              <label htmlFor="email">
                {t("email")} <span>*</span>
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                autoFocus
                placeholder={t("email")}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="error"
              />
            </div>
            <button
              type="submit"
              disabled={postForgetPasswordLoading}
              className="primaryButton"
            >
              {t("submit")}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgetPassword;
