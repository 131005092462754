import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Backdrop from "../../backdrop";
import { getCityAction, getStateAction } from "../../../redux/address/action";
import { getClientIdAction, patchClientAction, postClientAction } from "../../../redux/company/client/action";

function AddClient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState()
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const { postClient, postClientLoading, postClientError, patchClient, patchClientLoading, patchClientError, getClientId, getClientIdLoading, getClientIdError } = useSelector(
    (state) => state.Clientsection
  );
  const { getState, getStateLoading, getCity, getCityLoading } = useSelector(
    (state) => state.AddressSection
  );
  useEffect(() => {
    const id = searchParams.get("id");
    if (id !== null) {
      dispatch(getClientIdAction(id));
    }
  }, [searchParams]);
  useEffect(() => {
    dispatch(getStateAction());
  }, [dispatch]);

  
  
  useEffect(() => {
    const edit = searchParams.get("id") === null;
    console.log(edit)
    
    if (!edit) {
      if (getClientId?.status === 200) {
          setData(getClientId?.data);
          dispatch(getStateAction());
          handleCity(getClientId?.data?.state)
      }
      if (getClientIdError === 400) {
        toast.error(getClientIdError?.message);
      } else if (getClientIdError === 404) {
        toast.error(getClientIdError.message);
      } else if (getClientIdError === 500) {
        toast.error(getClientIdError.error);
      }
    }
  }, [getClientId, getClientIdError]);
  useEffect(() => {
    if (getState?.status === 200) {
      setState(getState.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getState]);
  useEffect(() => {
    if (getCity?.status === 200) {
      setCity(getCity?.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [getCity]);
  useEffect(() => {
    if (loader) {
      if (postClient?.status === 200) {
        setLoader(false);
        toast.success(postClient.message, {
          id: "addClient",
        });
        navigate(-1);
      } else if (postClientError?.status === 404) {
        setLoader(false);
        toast.error(postClientError.message, {
          id: "addClient",
        });
      } else if (postClientError?.status === 400) {
        setLoader(false);
        toast.error(postClientError.message, {
          id: "addClient",
        });
      } else if (postClientError?.error === 500) {
        setLoader(false);
        toast.error(postClientError.message, {
          id: "addClient",
        });
      }
    }
  }, [postClient, postClientError]);
  useEffect(() => {
    if (loader) {
      if (patchClient?.status === 200) {
        setLoader(false);
        toast.success(patchClient.message, {
          id: "updateClient",
        });
        navigate(-1);
      } else if (patchClientError?.status === 404) {
        setLoader(false);
        toast.error(patchClientError.message, {
          id: "updateClient",
        });
      } else if (patchClientError?.status === 400) {
        setLoader(false);
        toast.error(patchClientError.message, {
          id: "updateClient",
        });
      } else if (patchClientError?.error === 500) {
        setLoader(false);
        toast.error(patchClientError.message, {
          id: "updateClient",
        });
      }
    }
  }, [patchClient, patchClientError]);

  const initialValues = {
    name: data?.name || "",
    ownerName: data?.ownerName || "",
    phoneNumber: data?.phoneNumber || "",
    gstNumber: data?.gstNumber || "",
    address: data?.address || "",
    state: data?.state || "",
    city: data?.city || "",
    country: data?.country || "India",
    zipCode: data?.zipCode || "",
    description: data?.description || ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Client name is required"),
    ownerName: Yup.string().required("Owner name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    gstNumber: Yup.string(),
    address: Yup.string().required("Address is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zipCode: Yup.string()
      .matches(/^[0-9]{6}$/, "Zip code must be 6 digits")
      .required("Zip code is required"),
  });

  const handleCity = (id) => {
    dispatch(getCityAction(id));
  };

  const onSubmit = (values) => {
    dispatch(postClientAction(values));
    setLoader(true);
    toast.loading("Saving Client Details...", { id: "addClient" });
  };
  const onUpdate = (values) => {
    const payload = {
      id: searchParams.get("id"),
      body: values
    }
    dispatch(patchClientAction(payload));
    setLoader(true);
    toast.loading("Updating Client Details...", { id: "updateClient" });
  };

  return (
    <div>
      {(getStateLoading || getCityLoading || getClientIdLoading) && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Add Client Details</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 ">
              <div>
                <label htmlFor="name">
                  Client Company Name <span>*</span>
                </label>
                <Field
                  type="text"
                  name="name"
                  placeholder="Client Company Name"
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="ownerName">
                  Owner Name <span>*</span>
                </label>
                <Field type="text" name="ownerName" placeholder="Owner Name" />
                <ErrorMessage
                  name="ownerName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="phoneNumber">
                  Phone Number <span>*</span>
                </label>
                <Field
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="gstNumber">
                  GST Number <span>*</span>
                </label>
                <Field type="text" name="gstNumber" placeholder="GST Number" />
                <ErrorMessage
                  name="gstNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="address">
                  Address <span>*</span>
                </label>
                <Field
                  type="text"
                  id="address"
                  name="address"
                  className=""
                  placeholder="Address"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="country">
                  Country <span>*</span>
                </label>
                <Field
                  type="text"
                  id="country"
                  name="country"
                  className=""
                  placeholder="Country"
                  readOnly
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="state">
                  State <span>*</span>
                </label>
                <Field
                  as="select"
                  id="state"
                  name="state"
                  className=""
                  onChange={(e) => {
                    setFieldValue("state", e.target.value);
                    handleCity(e.target.value);
                  }}
                  placeholder="State"
                >
                  <option value="">State</option>
                  {state?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage name="state" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="city">
                  City <span>*</span>
                </label>
                <Field
                  as="select"
                  id="city"
                  name="city"
                  className=""
                  placeholder="City"
                >
                  <option value="">City</option>
                  {city?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Field>
                <ErrorMessage name="city" component="div" className="error" />
              </div>
              <div>
                <label htmlFor="zipCode">
                  Zip Code <span>*</span>
                </label>
                <Field type="text" name="zipCode" placeholder="Zip Code" />
                <ErrorMessage
                  name="zipCode"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  placeHolder="description"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>
              <button
                type="submit"
                disabled={postClientLoading || patchClientLoading}
                className="primaryButton h-fit mt-auto"
              >
              {data?._id ? "Update" : "Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddClient;
