import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Image from "../../../assets/images/semi.png";
import { Badge } from "../../../ui/badge";
import TruckSell from "./truckSell";
import { Dialog, DialogTrigger } from "../../../ui/dialog";
import SubscriptionList from "./subscription/subscriptionList";
import ImagePopUpDownload from "../../imagePopUpDownload";

function TruckListCard({ data, reroute = false }) {
  const navigate = useNavigate();

  const [showModal, setshowModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <div key={data._id}>
      <div className="grid grid-cols-12 gap-x-7 my-10 md:p-2 sm:p-5 p-2 bg-white rounded-lg shadow-2xl">
        <div className="lg:col-span-2 col-span-6 row-span-1 justify-self-center my-auto lg:row-span-3">
          <button
            type="button"
            className="rounded-full bg-gray-400"
            onClick={() =>
              reroute
                ? navigate(`details?truckId=${data._id}`)
                : setShowImageModal(true)
            }
          >
            <img
              src={
                data.truckId?.truckImage ? data.truckId?.truckImage.url : Image
              }
              alt=""
              className={` ${
                data.truckId?.truckImage ? "rounded-full" : ""
              }  h-[100px] w-[100px]`}
            />
          </button>
        </div>
        {data?.truckId?.truckImage && (
          <Dialog open={showImageModal}>
            <ImagePopUpDownload
              image={data?.truckId?.truckImage}
              setShowModal={setShowImageModal}
              imagename={`truck_${data?.truckId?._id}`}
            />
          </Dialog>
        )}
        <div className="mb-5 text-center md:text-left lg:col-span-10 col-span-6 row-span-1 self-center lg:self-auto">
          <button
            type="button"
            className="font-bold text-blue-600 text-xl"
            onClick={
              reroute ? () => navigate(`details?truckId=${data._id}`) : null
            }
          >
            {data.truckNumber}
          </button>
          <p className="textgrey text-base">
            {data.truckId?.truckCompanyName}, {data.truckId?.truckModelName},{" "}
            {data.truckId?.typeOfTruck}{" "}
          </p>
        </div>
        <div className="grid md:grid-cols-4 grid-cols-2 lg:gap-8 gap-3 justify-between lg:col-span-10 col-span-12 self-center">
          <div className="md:text-left">
            <h3 className="textgrey text-xs">Running In</h3>
            <p>{data?.usedfor}</p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center ">Subscription</h3>
            <p className="flex md:justify-center">
              {data?.isSubscribe ? (
                <Badge className="bg-green-700 hover:bg-green-900">
                  Subscribed
                </Badge>
              ) : (
                <Badge variant="destructive">UnSubscribed</Badge>
              )}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-center">
              Subscription End
            </h3>

            <p className="md:text-center">
              {data?.isSubscribe
                ? moment(data?.subscribeTill).format("DD/MM/YYYY") +
                  moment(data?.subscribeTill).fromNow()
                : "Please Subscribe"}
            </p>
          </div>
          <div>
            <h3 className="textgrey text-xs md:text-right">Subscription End</h3>
            <p className="md:text-right">
              {moment(data?.subscribeTill).diff(moment(new Date()), "days")}{" "}
              Days Left
            </p>
          </div>
          {data?.isSell ? (
            <>
              <div>
                <h3 className="textgrey text-xs  ">Truck Selled</h3>
                <p className="flex ">
                  <Badge variant="destructive">Sell</Badge>
                </p>
              </div>
              <div className="md:text-center">
                <h3 className="textgrey text-xs  ">Truck Sell Date</h3>

                <p className="">
                  {moment(data?.sellDate).format("DD/MMM/YYYY")}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="my-auto ">
                <button
                  type="button"
                  className="secondaryButton bg-yellow-600 hover:bg-yellow-800 text-white h-full"
                  onClick={() =>
                    navigate(`/company/truck/addtruck?id=${data?._id}`)
                  }
                >
                  Edit Truck Details
                </button>
              </div>
              <div className="my-auto col-span-1">
                <button
                  className="primaryButton bg-green-600 hover:bg-green-800 md:px-4 px-2"
                  onClick={() => setShowSubscribeModal(true)}
                >
                  {data?.isSubscribe ? "Extend Subscription" : "Subscribe"}
                </button>
                <Dialog open={showSubscribeModal}>
                  <SubscriptionList
                    setShowModal={setShowSubscribeModal}
                    truckId={data?._id}
                  />
                </Dialog>
              </div>
              {data?.truckId?.isEmi && (
                <Link
                  to={`emi?truckId=${data?.truckId?._id}`}
                  className="primaryButton bg-cyan-500 hover:bg-cyan-800 w-full text-center"
                >
                  Emi
                </Link>
              )}
              {data?.isSubscribe ? (
                <>
                  <button
                    onClick={() =>
                      navigate(`/company/trip/addtrip?truckId=${data._id}`)
                    }
                    className="primaryButton "
                  >
                    Add Trip
                  </button>
                </>
              ) : null}
              <Dialog open={showModal}>
                <DialogTrigger asChild>
                  <button
                    type="button"
                    className="secondaryButton bg-red-600 hover:bg-red-800 text-white h-full"
                    onClick={() => setshowModal(true)}
                  >
                    Sell Truck
                  </button>
                </DialogTrigger>
                <TruckSell id={data?._id} setshowModal={setshowModal} />
              </Dialog>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckListCard;
