import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "../../datepicker/datePicker";
import FileUpload from "../../fileupload/fileUpload";
import {
  getAdminExpenseByIdAction,
  patchAdminExpenseAction,
  postAdminExpenseAction,
} from "../../../redux/admin/expenses/action";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import Backdrop from "../../backdrop";

const names = ["domain", "email", "messages", "aws", "images", "code", "other"];

function AddExpenses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    if (!edit) {
      dispatch(getAdminExpenseByIdAction(searchParams.get("id")));
    }
  }, []);

  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);

  const {
    getAdminExpenseById,
    getAdminExpenseByIdLoading,
    getAdminExpenseByIdError,
    patchAdminExpense,
    patchAdminExpenseLoading,
    patchAdminExpenseError,
    postAdminExpense,
    postAdminExpenseLoading,
    postAdminExpenseError,
  } = useSelector((state) => state.AdminExpenseSection);
  const initialValues = {
    expenseDate: data?.expenseDate ? new Date(data?.expenseDate) : "",
    billNumber: data?.billNumber || null,
    expenseName: data?.expenseName || "",
    billImage: data?.billImage || "",
    cost: data?.cost || null,
    description: data?.description || "",
  };
  const validationSchema = Yup.object({
    expenseDate: Yup.date().required("Service date is required"),
    billNumber: Yup.string().required("Service bill number is required"),
    expenseName: Yup.string().required("Service type is required"),
    cost: Yup.number().required("Service cost is required"),
    description: Yup.string(),
  });
  useEffect(() => {
    const edit = searchParams.get("id") === null;

    if (!edit) {
      if (getAdminExpenseById?.status === 200) {
        setData(getAdminExpenseById?.data);
      }
    }
  }, [getAdminExpenseById]);

  useEffect(() => {
    const edit = searchParams.get("id") === null;
    if (!edit) {
      if (getAdminExpenseByIdError === 400) {
        toast.error(getAdminExpenseByIdError?.message);
      } else if (getAdminExpenseByIdError === 404) {
        toast.error(getAdminExpenseByIdError.message);
      } else if (getAdminExpenseByIdError === 500) {
        toast.error(getAdminExpenseByIdError.error);
      }
    }
  }, [getAdminExpenseByIdError]);

  useEffect(() => {
    if (loader) {
      if (postAdminExpenseError?.status === 404) {
        toast.error(postAdminExpenseError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postAdminExpenseError?.status === 400) {
        toast.error(postAdminExpenseError.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
      } else if (postAdminExpenseError?.status === 500) {
        toast.error(postAdminExpenseError.error, {
          id: "addDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [postAdminExpenseError]);
  useEffect(() => {
    if (loader) {
      if (postAdminExpense?.status === 200) {
        toast.success(postAdminExpense.message, {
          id: "addDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [postAdminExpense]);
  useEffect(() => {
    if (loader) {
      if (patchAdminExpense?.status === 200) {
        toast.success(patchAdminExpense.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
        navigate(-1);
      }
    }
  }, [patchAdminExpense]);
  useEffect(() => {
    if (loader) {
      if (patchAdminExpenseError?.status === 404) {
        toast.error(patchAdminExpenseError.message, {
          id: "updateDetails-toast",
        });
      } else if (patchAdminExpenseError?.status === 400) {
        toast.error(patchAdminExpenseError.message, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      } else if (patchAdminExpenseError?.status === 500) {
        toast.error(patchAdminExpenseError.error, {
          id: "updateDetails-toast",
        });
        setLoader(false);
      }
    }
  }, [patchAdminExpenseError]);
  const onSubmit = (values) => {
    dispatch(postAdminExpenseAction(values));
    setLoader(true);
    toast.loading("Adding Expense...", { id: "addDetails-toast" });
  };

  const onUpdate = (values) => {
    const payload = { id: data._id, body: values };
    dispatch(patchAdminExpenseAction(payload));
    setLoader(true);
    toast.loading("Updating Expense...", { id: "updateDetails-toast" });
  };
  return (
    <div>
      {getAdminExpenseByIdLoading && <Backdrop />}
      <h2 className="text-2xl font-bold mb-4">Add Expense</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={data?._id ? onUpdate : onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
              <div>
                <label htmlFor="expenseName">
                  Expense Name<span>*</span>
                </label>
                <Field
                  as="select"
                  id="expenseName"
                  placeHolder="Name"
                  name="expenseName"
                  className=""
                >
                  <option value=""></option>
                  {names?.map((item) => (
                    <option value={item} className="capitalize">{item}</option>
                  ))}
                </Field>

                <ErrorMessage
                  name="expenseName"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="expenseDate">
                  Expense Date<span>*</span>
                </label>

                <DatePicker
                  placeHolder="DD / MMM / YYYY"
                  name="expenseDate"
                  maxDate={new Date()}
                />
                <ErrorMessage
                  name="expenseDate"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="billNumber">
                  Bill No<span>*</span>
                </label>
                <Field
                  type="text"
                  placeHolder="Service Bill No"
                  id="billNumber"
                  name="billNumber"
                  className=""
                />
                <ErrorMessage
                  name="billNumber"
                  component="div"
                  className="error"
                />
              </div>

              <div>
                <label htmlFor="billImage">Bill Image</label>
                <FileUpload
                  setFieldValue={setFieldValue}
                  name="billImage"
                  values={values}
                />
                <ErrorMessage
                  name="billImage"
                  component="div"
                  className="error"
                />
              </div>
              <div>
                <label htmlFor="cost">
                  Total Cost<span>*</span>
                </label>
                <Field type="number" id="cost" name="cost" className="" />
                <ErrorMessage name="cost" component="div" className="error" />
              </div>

              <div>
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  id="description"
                  name="description"
                  className=""
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>

              <div className="flex">
                <button
                  type="submit"
                  className="primaryButton mt-auto"
                  // disabled={patchAdminExpenseLoading || postAdminExpenseLoading}
                >
                  {data?._id ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddExpenses;
