import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../ui/dialog";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { deleteCompanyTollTaxAction, getCompanyTollTaxFilterAction } from "../../../redux/company/tollTax/action";

function DeleteTollTax({ id, setShowModal }) {
  const dispatch = useDispatch()
  const {
    deleteCompanyTollTax,
    deleteCompanyTollTaxLoading,
    deleteCompanyTollTaxError,
  } = useSelector((state) => state.TollTaxsection);
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    deleteValue: Yup.string()
      .required("This field is required")
      .oneOf(["TOLL"], 'You must type "TOLL" to confirm'),
  });
  useEffect(() => {
    if (loading) {
      if (deleteCompanyTollTax?.status === 200) {
        toast.success(deleteCompanyTollTax.message, {
          id: "deleteTollTax-toast",
        });
        const id = searchParams.get("id");
        const payload = {
          truckId: id,
        };
        dispatch(getCompanyTollTaxFilterAction(payload));
        setLoading(false);
        setShowModal(false);
      } else if (deleteCompanyTollTaxError?.status === 404) {
        toast.error(deleteCompanyTollTaxError.message, {
          id: "deleteTollTax-toast",
        });
        setLoading(false);
      } else if (deleteCompanyTollTaxError?.status === 400) {
        toast.error(deleteCompanyTollTaxError.message, {
          id: "deleteTollTax-toast",
        });
        setShowModal(false);
        setLoading(false);
      } else if (deleteCompanyTollTaxError?.status === 500) {
        toast.error(deleteCompanyTollTaxError.error, {
          id: "deleteTollTax-toast",
        });
        setLoading(false);
      }
    }
  }, [deleteCompanyTollTax, deleteCompanyTollTaxError, loading]);

  const onSubmit = (values) => {
    if (values.deleteValue === "TOLL") {
      dispatch(deleteCompanyTollTaxAction(id))
      toast.loading("Deleting TollTax...", { id: "deleteTollTax-toast" });
      setLoading(true);
    }
  };

  return (
    <DialogContent className="bg-gray-100">
      <DialogHeader>
        <DialogTitle>Delete Toll</DialogTitle>
      </DialogHeader>
        <div className="text-xl mt-5 font-semibold">
          Are you sure you want to{" "}
          <span className="text-red-500 font-bold">Delete</span> this Toll Tax Data?
        </div>
      <Formik
        initialValues={{ deleteValue: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="">
            <div className="mb-4">
              <label htmlFor="deleteValue" className="text-gray-700">
                To confirm, type <span className="font-bold">"TOLL"</span> in Capital Letter
                the box below*
              </label>
              <Field
                type="text"
                id="deleteValue"
                name="deleteValue"
                placeholder="TOLL"
              />
              <ErrorMessage
                name="deleteValue"
                component="div"
                className="text-red-600"
                />
            </div>
            <DialogFooter>
              <button
                className="secondaryButton"
                onClick={() => setShowModal(false)}
                type="button"
              >
                Close
              </button>
              <button
                className="primaryButton bg-red-600 hover:bg-red-800 w-fit"
                type="submit"
              >
                Delete
              </button>
            </DialogFooter>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
}

export default DeleteTollTax;
