import api from "../../../auth/api.jsx";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALERT_FITNESS,
  GET_ALERT_INSURANCE,
  GET_ALERT_PERMIT,
} from "../../actions.js";
import {
  getTruckAlertFitnessError,
  getTruckAlertFitnessSuccess,
  getTruckAlertInsuranceError,
  getTruckAlertInsuranceSuccess,
  getTruckAlertPermitError,
  getTruckAlertPermitSuccess,
} from "./action.js";

// Async function to handle API call
const getTruckAlertInsuranceAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/insurance/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckAlertInsuranceCall(action) {
  try {
    const data = yield call(getTruckAlertInsuranceAsync, action); // Call async function with action payload
    yield put(getTruckAlertInsuranceSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckAlertInsuranceError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckAlertInsuranceData() {
  yield takeEvery(GET_ALERT_INSURANCE, getTruckAlertInsuranceCall);
}

// Async function to handle API call
const getTruckAlertFitnessAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/fitness/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckAlertFitnessCall(action) {
  try {
    const data = yield call(getTruckAlertFitnessAsync, action); // Call async function with action payload
    yield put(getTruckAlertFitnessSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckAlertFitnessError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckAlertFitnessData() {
  yield takeEvery(GET_ALERT_FITNESS, getTruckAlertFitnessCall);
}

// Async function to handle API call
const getTruckAlertPermitAsync = async (payload) => {
  try {
    const response = await api.get(`/company/alert/permit/get`);
    return response.data; // Return the data from the API response
  } catch (error) {
    throw error.response.data;; // Throw the error to be caught by the Saga error handler
  }
};

// Saga function to handle POST_COMPANY_DETAILS action
function* getTruckAlertPermitCall(action) {
  try {
    const data = yield call(getTruckAlertPermitAsync, action); // Call async function with action payload
    yield put(getTruckAlertPermitSuccess(data)); // Dispatch success action with data
  } catch (error) {
    yield put(getTruckAlertPermitError(error)); // Dispatch error action with error object
  }
}
export function* watchGetTruckAlertPermitData() {
  yield takeEvery(GET_ALERT_PERMIT, getTruckAlertPermitCall);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetTruckAlertInsuranceData),
    fork(watchGetTruckAlertFitnessData),
    fork(watchGetTruckAlertPermitData),
   
  ]);
}
